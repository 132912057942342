import React, { useContext, useState, useEffect, useRef } from "react";
import { Card, Button, Modal, Input, Tooltip, Form, message } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { SherpaContext } from "../utils/sherpaContext";
import { getCourses, joinCourse } from "../api/course";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import bg1 from "../img/shortbg1.jpeg";
import bg2 from "../img/shortbg2.jpeg";
import bg3 from "../img/shortbg3.jpeg";
import bg4 from "../img/shortbg4.jpeg";
import bg5 from "../img/shortbg5.jpeg";
import bg6 from "../img/shortbg6.jpeg";

const backgrounds = [bg1, bg2, bg3, bg4, bg5, bg6];

const Classteacherdashboard = () => {
  const { userToken } = useContext(SherpaContext);
  const [classes, setClasses] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [classCode, setClassCode] = useState(["", "", "", "", "", "", "", ""]);
  const inputRefs = useRef([]);

  const fetchClasses = async () => {
    try {
      const response = await getCourses();
      const coursesWithBackgrounds = response.map((course) => ({
        ...course,
        backgroundIndex: Math.floor(Math.random() * backgrounds.length), // Assign a random background index
      }));
      setClasses(coursesWithBackgrounds);
      // console.log(response);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  useEffect(() => {
    fetchClasses();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleClassCodeChange = (value, index) => {
    const newClassCode = [...classCode];
    newClassCode[index] = value;
    setClassCode(newClassCode);

    if (value !== "" && index < 7) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handlePaste = (e) => {
    const pasteData = e.clipboardData.getData("text").slice(0, 8).split("");
    setClassCode(pasteData);
    pasteData.forEach((digit, i) => {
      if (inputRefs.current[i]) {
        inputRefs.current[i].value = digit;
      }
    });
    if (pasteData.length < 8) {
      inputRefs.current[pasteData.length].focus();
    }
    e.preventDefault();
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !classCode[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleFormSubmit = async () => {
    const code = classCode.join("");
    try {
      await joinCourse(code);
      toast.success("Joined class successfully!");
      fetchClasses();
      setClassCode(["", "", "", "", "", "", "", ""]); // Clear the class code
      setIsModalVisible(false); // Close the modal
    } catch (error) {
      toast.error("Failed to join class. Please try again.");
      console.error(error);
    }
  };

  const handleCopyCode = (code, event) => {
    navigator.clipboard.writeText(code);
    event.stopPropagation();
  };

  return (
    <div>
      <style>
        {`
          .custom-ant-card .ant-card-body {
            padding: 0px;
            border-radius: 0 0 8px 8px;
            width: 100%;
          }
        `}
      </style>
      <div
        className="bg-white w-full flex justify-between items-center h-16"
        style={{
          borderBottom: "1.5px solid #E2E8F0",
          fontFamily: "Inter !important",
        }}
      >
        <div className="text-md m-4" style={{ fontWeight: 600 }}>
          Student Dashboard
        </div>
      </div>
      <div className="w-full py-8 bg-[#f9f9f9] flex justify-center">
        <div className="w-[800px] flex flex-col">
          <div className="text-2xl font-semibold my-4">
            <span className="text-4xl mr-2" id="getting_started_title">
              🚀
            </span>
            {userToken?.details?.name?.split(" ")?.[0]
              ? "Hi " + userToken?.details?.name?.split(" ")?.[0] + "."
              : ""}
          </div>
          {classes.length === 0 ? (
            <div className="flex flex-col items-center">
              <p className="leading-relaxed" style={{ lineHeight: "0.1" }}>
                Welcome to Sherpa! You have not joined any classes.
              </p>
              <Button
                onClick={showModal}
                className="mt-4 text-white font-semibold cursor-pointer flex gap-1 items-center justify-center"
                size="large"
                type="primary"
                style={{ padding: "8px 20px" }}
              >
                Join Class
              </Button>
            </div>
          ) : (
            <p className="leading-relaxed" style={{ lineHeight: "0.1" }}>
              Welcome to Sherpa! Your classes are listed below:
            </p>
          )}
        </div>
      </div>
      <div className="w-full py-8 bg-[#f9f9f9] flex justify-center">
        <div className="w-[800px] flex flex-wrap justify-between">
          <div className="flex justify-between items-center my-4 w-full">
            {classes.length === 0 ? (
              <div className="text-2xl font-semibold"></div>
            ) : (
              <div className="text-2xl font-semibold">Your Classes</div>
            )}
            {classes.length > 0 && (
              <Button
                onClick={showModal}
                className="text-white font-semibold cursor-pointer flex gap-1 items-center justify-center"
                size="large"
                type="primary"
                style={{ padding: "8px 20px" }}
              >
                Join Class
              </Button>
            )}
          </div>

          <div className='hello' style={{
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)", /* Three equal columns */
  gap: "20px", /* Adjust gap between cards */
  alignItems: 'center',
  width: "100%"
}}>
          {classes.map((classItem, index) => (
            <Link key={classItem.id}
            style={{textDecoration:"none",}}
             to={{
              pathname: `/studentcourse/${classItem._id}`,
              state: { backgroundIndex: classItem.backgroundIndex } // Pass backgroundIndex as state
            }}>
              <Card 
                key={index}
                className="custom-ant-card" // Add custom class name
                style={{
                  width: "240px", 
                  marginBottom: '16px', 
                  display: 'flex', 
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  border: '1px solid #E2E8F0',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  height:'250px',
                  textDecoration:"none",
                
                }}

              >
                  <div style={{ width: '100%', height: '120px', backgroundImage: `url(${backgrounds[classItem.backgroundIndex]})`, backgroundSize: 'cover' }}></div>
                <div style={{ padding: '16px', width: '100%', backgroundColor: '#fff' }}>
                  <p style={{ fontWeight: 'bold', fontSize: '18px' ,position:'relative',top:'-20px'}}>{classItem.title ? classItem.title.charAt(0).toUpperCase() + classItem.title.slice(1) : ''}</p>
                  {/* <p>{classItem.description}</p> */}
                  <p style={{ position: "relative", top: "-40px" }}>
                    Grade : {classItem.grade}
                  </p>
                  <p
                    style={{ position: "relative", top: "-40px" }}
                  >{`Start Date: ${new Date(classItem.created_at).toLocaleDateString(undefined, { year: "numeric", month: "long", day: "numeric" })}`}</p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  ></div>
                </div>
              </Card>
            </Link>
          ))}
          </div>
        </div>
      </div>
      <Modal title="" open={isModalVisible} footer={null} onCancel={handleCancel} >
        <div className="flex justify-center items-center mb-4">
          <span className="text-xl font-semibold">Enter Class Code</span>
        </div>
        <div className="flex justify-center gap-2 mb-4" onPaste={handlePaste}>
          {classCode.map((digit, index) => (
            <Input
              key={index}
              maxLength={1}
              style={{
                width: 45,
                height: 40,
                textAlign: "center",
                fontSize: "24px",
              }}
              value={digit}
              ref={(el) => (inputRefs.current[index] = el)}
              onChange={(e) => handleClassCodeChange(e.target.value, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
            />
          ))}
        </div>
        <div className="flex justify-center">
          <Button
            type="primary"
            onClick={handleFormSubmit}
            className="text-white font-semibold"
            size="large"
          >
            Join Class
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Classteacherdashboard;
