import React, {useContext, useState, useEffect} from 'react';
import { Card, Button, Upload, Form, Input, Modal, message } from 'antd';
import { IoIosCheckmarkCircle } from "react-icons/io";
import { SherpaContext } from '../utils/sherpaContext';
import { uploadToS3 } from '../api/s3';
import { toast } from 'react-toastify';
import { AiFillFilePdf } from 'react-icons/ai';
import ReactLoading from 'react-loading';
import { createAssignment } from '../api/assignment';
import { PiMicrophoneStageFill } from "react-icons/pi"
import { getConversation } from '../api/conversation';
import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { getCourses, createCourse } from '../api/course';
import { useHistory } from 'react-router-dom';

import bg1 from '../img/shortbg1.jpeg';
import bg2 from '../img/shortbg2.jpeg';
import bg3 from '../img/shortbg3.jpeg';
import bg4 from '../img/shortbg4.jpeg';
import bg5 from '../img/shortbg5.jpeg';
import bg6 from '../img/shortbg6.jpeg';

const backgrounds = [bg1, bg2, bg3, bg4, bg5, bg6];


GlobalWorkerOptions.workerSrc = pdfjsWorker;

const WORD_COUNT = 10000

const GettingStarted = (props) => {
    const { userToken, updateGettingStartedStatus } = useContext(SherpaContext)
    const [inputType, setInputType] = useState('PDF')
    const [isCreatingAssignment, setIsCreatingAssignment] = useState(false)
    const [fileData, setFileData] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [conversationComplete, setConversationComplete] = useState()
    const [isEvaluated, setIsEvaluated] = useState()
    const [readingText, setReadingText] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [classes, setClasses] = useState([]);
    const history = useHistory(); // For navigation

    useEffect(()=>{
        const fetchData = async () => {
            setIsLoading(true)
            if (userToken.details.getting_started?.main_conversation) {
                const conversation = await getConversation(userToken.details.getting_started.main_conversation)
                setConversationComplete(conversation?.conversation.is_complete)
                setIsEvaluated(conversation?.evaluations?.length > 0)
            } else {
                setConversationComplete(false)
                setIsEvaluated(false)
            }
            setIsLoading(false)
        }
        fetchData();
    }, []);

    const fetchClasses = async () => {
        try {
          getCourses()
          .then(response => {
            const coursesWithBackgrounds = response.map(course => ({
              ...course,
              backgroundIndex: Math.floor(Math.random() * backgrounds.length) // Assign a random background index
            }));
            setClasses(coursesWithBackgrounds);
          })
          .catch(error => {
            console.error('Error fetching courses:', error);
          });
        } catch (error) {
          console.error('Error fetching courses:', error);
        }
      };
    
      useEffect(() => {
        fetchClasses();
      }, []);
    

    const showModal = () => {
        setIsModalVisible(true);
      };
    
      const [form] = Form.useForm();
    
      const handleFormSubmit = () => {
        form.validateFields()
          .then((values) => {
            handleOk(values, form); // Pass form values to handleOk

             // Redirect to the dashboard after handling the form submission
        history.push('/teacher/Classteacherdashboard');
    })
          
          .catch((errorInfo) => {
            message.error('Please fill in all the required fields');
          });
      };
    
      const handleOk = async (course, form) => {
        try {
          await createCourse(course);
          toast.success('Course created successfully!');
          fetchClasses();
          form.resetFields(); // Reset form fields after successful submission
        } catch (error) {
          toast.error('Failed to create course. Please try again.');
          console.error(error);
        }
        
        setIsModalVisible(false);
      };
    
      const handleCancel = () => {
        setIsModalVisible(false);
      };

      
    

    const onSubmit = async (text) => {
        setIsCreatingAssignment(true)
        const response = await createAssignment({
            "time_limit": false,
            "min_words": 10,
            "external_source_title": inputType === "PDF" ? fileName : "Sample Reading",
            "file_name": inputType === "PDF" ? fileName : null,
            "subject": userToken.details.subjects?.[0] ?? "",
            "grade": userToken.details.grade ?? 9,
            
            "recording_format": "students_can_choose",
            "assignment_type": "Reading Responses",
            "title": inputType === "PDF" ? fileName : "Sample Reading",
            "external_format": "reading",
            "conversation_flow": [{
                    "type": "start"
                }, {
                    "type": "base",
                    "level": 1,
                    "concept": null
                }, 
                {
                    "type": "followup"
                }, 
                {
                    "type": "base",
                    "level": 2,
                    "concept": null
                }, {
                    "type": "followup"
                }, {
                    "type": "base",
                    "level": 3,
                    "concept": null
                }, 
                {
                    "type": "closure"
                }
            ],
            "text": text
        })
        
        await updateGettingStartedStatus("create_reading_assignment", response["assignment_id"])
        props.history.push('/student/assignment/' + response["assignment_id"])
        setIsCreatingAssignment(false)
    }


  return !isLoading ? (
    <div>
        <div className={`bg-white w-full flex justify-between items-center h-16`} style={{borderBottom: '1.5px solid #E2E8F0', fontFamily: 'Inter !important'}} >
            <div className="text-md m-4" style={{
              fontWeight: 600
            }}>Getting Started</div>
        </div>
        <div className="w-full py-8 bg-[#f9f9f9] flex justify-center">
            <div className="w-[800px] flex flex-col">
                <div className="text-2xl font-semibold my-4">
                    <span className="text-4xl mr-2" id="getting_started_title">🚀</span> {userToken?.details?.name?.split(" ")?.[0] ? "Hi " + userToken?.details?.name?.split(" ")?.[0] + "." : "" } Welcome to Sherpa!
                </div>

                <div className="text-md opacity-70 flex flex-col w-full">
                    <p className="leading-relaxed" style={{lineHeight: '1.7'}}>
                    Sherpa brings your readings to life with voice-enabled assignments. We take your students on a journey through their reading, immersing them in a <b>10-minute oral conversation</b> with personalized questions to gauge their true understanding.</p>
                    {/* <div className="flex items-center">
                        <Button type="dashed" target="_blank" href="https://youtu.be/7g-qAObe8Mw">Watch Intro Video</Button>
                    </div> */}
                </div>
            </div>
        </div>
        <div className="w-full flex py-16 justify-center flex-col items-center gap-8">
             {/* <div className="rounded-lg bg-[#EEECFF] w-[800px]">
                <div className="px-8 py-6 flex items-start justify-between ">
                    <div className="text-purple-800 flex flex-col gap-2">
                    <span className="text-lg" style={{fontWeight: '600'}}>Let's Get Started!</span>
                    <span className="text-sm">Below are 3 key steps that will allow you to truly harness the power of Sherpa.</span>
                    </div>
                </div>
            </div> */}
            <div className="rounded-lg bg-white w-[800px] flex flex-col gap-8">
                <Card
                    style={{
                        border: '1.5px solid #E2E8F0'
                    }}
                >
                    <div className="flex w-full items-center justify-between">
                        <div className="w-full flex flex-col gap-4">
                            <div className="w-full text-center py-4 flex flex-col gap-2">
                                <span className="text-lg font-regular">Step 1</span>
                                <div className="flex gap-2 w-full items-center justify-center">
                                    <span className="text-2xl font-semibold">Upload Your First Reading</span>
                                    {userToken.details?.getting_started?.create_reading_assignment ? 
                                        <IoIosCheckmarkCircle color="#1AB057" size={40} className="pl-4" />
                                    : null }
                                </div>
                            </div>

                            {userToken.details?.getting_started?.create_reading_assignment ? 
                            <>
                            {conversationComplete ? 
                            <>
                                <div className='flex items-center text-center justify-center'>
                                    <p className="opacity-70 text-lg">Congrats! You just created your first assignment, & had a conversation about it!</p>
                                </div>
                                <Button onClick={()=>{
                                    props.history.push('/teacher/create')
                                }} className="text-white font-semibold cursor-pointer flex gap-1 items-center justify-center" size='large' type='primary'>Create Another Assignment</Button>
                            </>
                            :
                                <Button onClick={()=>{
                                    props.history.push('/student/assignment/' + userToken.details?.getting_started?.create_reading_assignment)
                                }} className="text-white font-semibold cursor-pointer flex gap-1 items-center justify-center" size='large' type='primary'>
                                    Resume Conversation
                                </Button>
                            }
                            </>
                            :
                            <>
                                <div className="w-full flex justify-end">
                                    <div className="hover:underline cursor-pointer opacity-70" onClick={()=>{
                                        setInputType(inputType === 'PDF' ? 'text' : 'PDF')
                                    }}>Or, you can click here {inputType === 'PDF' ? <>to paste the reading as text instead.</> : inputType === 'text' ?  <>upload a PDF instead.</> : null}</div>
                                </div>
                                
                                {inputType === 'text' ? 
                                    <Input.TextArea rows={8} maxLength={25000} placeholder={"The novel was inspired by a youthful romance Fitzgerald had with socialite Ginevra King, and the riotous parties he attended on Long Island's North Shore in 1922. Following a move to the French Riviera, Fitzgerald completed a rough draft of the novel in 1924. He submitted it to editor Maxwell Perkins, who persuaded Fitzgerald to revise the work over the following winter. After making revisions, Fitzgerald was satisfied with the text, but remained ambivalent about the book's title and considered several alternatives. Painter Francis Cugat's dust jacket art greatly impressed Fitzgerald, and he incorporated its imagery into the novel. After its publication by Scribner's in April 1925, The Great Gatsby received generally favorable reviews, though some literary critics believed it did not equal Fitzgerald's previous efforts. Compared to his earlier novels, This Side of Paradise (1920) and The Beautiful and Damned (1922), Gatsby was a commercial disappointment. It sold fewer than 20,000 copies by October, and Fitzgerald's hopes of a monetary windfall from the novel were unrealized. When the author died in 1940, he believed himself to be a failure and his work forgotten."} onChange={(e) => setReadingText(e.target.value)} />
                                :
                                <Upload.Dragger 
                                    disabled={userToken.details?.getting_started?.create_reading_assignment}
                                    accept='application/pdf'
                                    maxCount={1} 
                                    className="h-64 mb-8"
                                    customRequest={async (data)=>{
                                        await uploadToS3(data.file.name, data.file)
                                        setFileName(data.file.name)
                                        const fileReader = new FileReader();
                                        fileReader.readAsArrayBuffer(data.file);
                                        fileReader.onload = async () => {
                                        const arrayBuffer = fileReader.result;
                                        const buffer = new Uint8Array(arrayBuffer);
                                        
                                        const loadingTask = getDocument(buffer);
                                        try {
                                            const doc = await loadingTask.promise;
                                            const numPages = doc.numPages;
                                            
                                            const loadPage = async function (pageNum) {
                                                const page = await doc.getPage(pageNum);
                                                const content = await page.getTextContent();
                                                const strings = content.items.map(function (item) {
                                                    return item.str;
                                                });
                                                page.cleanup();
                                                return strings.join(" ")
                                            };
                                            
                                            const textData = []
                                            for (let i = 1; i <= numPages; i++) {
                                                textData.push(await loadPage(i));
                                            }
                                            
                                            setFileData(textData)
                                            toast.success("Processed Reading!")
                                            data.onSuccess(data.file, data.file.name)
                                            
                                        } catch (err) {
                                            toast.error("Error: " + err)
                                        }
                                        }
                                        
                                    }} 
                                    beforeUpload={(file)=>{
                                        const isCorrectFormat = file.type === 'application/pdf';
                                        if (!isCorrectFormat) {
                                            toast.error('You can only upload JPG/PNG file!');
                                        }
                                        return isCorrectFormat;
                                    }}
                                >
                                    <p className="ant-upload-drag-icon">
                                    <AiFillFilePdf size={50} color="#9C1FFF" />
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload your PDF</p>
                                    <p className="ant-upload-hint">
                                    Only PDF files are supported
                                    </p>
                            </Upload.Dragger>
                            }
                            <Button loading={isCreatingAssignment} disabled={inputType === 'PDF' ? !fileData : inputType === 'text' ? !readingText : null} className="text-white font-semibold cursor-pointer flex gap-1 items-center justify-center" size='large' type='primary'
                                onClick={async ()=>{
                                    if (inputType === 'PDF') {
                                        const text = fileData.join("\n").split(" ").join(" ");
                                        const wordCount = text.split(" ").length - 1

                                        if (wordCount < 50) {
                                            toast.error("The uploaded reading does not contain enough words.")
                                        } else if (wordCount > WORD_COUNT) {
                                            Modal.confirm({
                                                title: `The reading exceeds the ${WORD_COUNT} word limit. Only the first ${WORD_COUNT} words will be used.`,
                                                onOk: async () => {
                                                    onSubmit(fileData.join("\n").split(" ").slice(0, WORD_COUNT).join(" "));
                                                },
                                            });
                                        } else {
                                            await onSubmit(fileData.join("\n").split(" ").slice(0, WORD_COUNT).join(" "));
                                        }
                                    } else {
                                        const wordCount = readingText.split(" ").length - 1
                                        if (wordCount < 50) {
                                            toast.error("The uploaded reading does not contain enough words.")
                                        } else {
                                            await onSubmit(readingText);
                                        }
                                    }
                                   
                                }}
                            >
                                <span>Begin Your First Conversation!</span> <PiMicrophoneStageFill color="white" />
                            </Button>
                            </>
                            }
                        </div>
                        {/* {checkIfStepIsComplete("try_conversation") ? <IoIosCheckmarkCircle color="#1AB057" size={40} className="px-8" /> : null} */}
                    </div>
                   
                    {/* <div className="w-full flex items-center justify-center">
                        <img src={StartConversation} className="w-full"/>
                    </div> */}
                </Card>
                <Card
                    style={{
                        border: '1.5px solid #E2E8F0'
                    }}
                >
                    <div className="flex w-full items-center justify-between py-4">
                        <div className="w-full text-center flex flex-col gap-2">
                                <span className="text-lg font-regular">Step 2</span>
                                <div className="flex items-center justify-center w-full gap-2">
                                    <span className="text-2xl font-semibold">Evaluate Your Conversation</span>
                                    {isEvaluated ? 
                                        <IoIosCheckmarkCircle color="#1AB057" size={40} className="pl-4" />
                                    : null}
                                </div>
                                {!isEvaluated ? 
                                    <p className="opacity-70 text-lg">Our AI surfaces <i>warnings</i> to identify student learning gaps.</p>
                                : null}
                            </div>
                        {/* {checkIfStepIsComplete("see_evaluation") ? <IoIosCheckmarkCircle color="#1AB057" size={40} className="px-8" /> : null} */}
                    </div>
                    <div className="w-full flex flex-col items-center mt-2">
                        {!conversationComplete ? <p className="opacity-70">You must complete the conversation before evaluating it.</p> : null }

                        { isEvaluated ? 
                            <div className='flex items-center text-center justify-center my-2'>
                                <p className="opacity-70 text-lg">Congrats! You finished evaluating your first conversation</p>
                            </div>
                            : null 
                        }
                        <Button className="w-full text-white font-semibold cursor-pointer" size='large' type='primary' disabled={!conversationComplete}
                            onClick={async ()=>{
                                props.history.push('/teacher/conversation/' + userToken.details?.getting_started?.main_conversation)

                                await updateGettingStartedStatus("see_evaluation")
                            }}
                        >
                            See Your AI Evaluation
                        </Button>
                    </div>
                </Card>
                <Card
                    style={{
                        border: '1.5px solid #E2E8F0'
                    }}
                >
                    <div className="flex w-full items-center justify-between py-4">
                        <div className="w-full text-center flex flex-col gap-2">
                                <span className="text-lg font-regular">Step 3</span>
                                <div className="flex items-center justify-center w-full gap-2">
                                    <span className="text-2xl font-semibold">Create Class and Invite Students</span>
                                    {userToken.details.getting_started?.invited_student ? 
                                        <IoIosCheckmarkCircle color="#1AB057" size={40} className="pl-4" />
                                    : null}
                                </div>
                                
                                <p className="opacity-70 text-lg">Create a class and invite students using code or link and start <br/> uploading assignments for the students</p>
                            </div>
                        {/* {checkIfStepIsComplete("see_evaluation") ? <IoIosCheckmarkCircle color="#1AB057" size={40} className="px-8" /> : null} */}
                    </div>
                    <div className="w-full flex items-center mt-4 gap-4">
                        
                        <Button className="w-full text-white font-semibold cursor-pointer" size='large' type='primary' disabled={!conversationComplete}
                           onClick={showModal}
                         >
                             Create Class
                         </Button>
                         
                    </div>
                </Card>
                <div className="rounded-lg flex items-center justify-center gap-8 my-8 flex-col">
                    <span className="opacity-70">Curious to try Sherpa in your classroom today?</span>
                    <Button type="dashed" onClick={()=>{
                        window.open("https://calendly.com/shaury-1/sherpa-with-shaurya", "_blank");                
                    }}>Speak with Sherpa's founders</Button>
                </div>

                <Modal title="Create New Class" open={isModalVisible} onOk={handleFormSubmit} onCancel={handleCancel}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="title"
            rules={[{ required: true, message: 'Class title is required' }]}
          >
            <Input placeholder="Enter class title" />
          </Form.Item>
          <Form.Item
            name="description"
            rules={[{ required: true, message: 'Class description is required' }]}
          >
            <Input placeholder="Enter class description" />
          </Form.Item>
          <Form.Item
            name="subject"
            rules={[{ required: true, message: 'Subject is required' }]}
          >
            <Input placeholder="Subject" />
          </Form.Item>
          <Form.Item
            name="grade"
            rules={[{ required: true, message: 'Grade level is required' }]}
          >
            <Input placeholder="Grade Level" />
          </Form.Item>
        </Form>
      </Modal>
                {/*
                <Card
                    style={{
                        border: '1.5px solid #E2E8F0'
                    }}
                >
                    <div className="flex w-full items-center justify-between py-4">
                        <div className="w-full flex flex-col gap-4">
                            <span className="text-2xl font-semibold">Step 3</span><span className="text-lg font-semibold">Great, this makes sense. Now, I want to create my own conversation that I want to use in my classroom. How do I do that?</span>
                        <span>Great work so far! Viva voce, to speak “with the living voice”, has long been the gold standard of demonstrating your knowledge. We hope in that speaking about your essay, you feel this too.</span>
                        <div>Now, let's <b>create our first conversation</b>. There are two main types of conversation:</div>
                        <div>1. You can gauge a student's understanding of their own work, whether it be an essay, research paper, or lab report. <br/>
                        2. Alternatively, you can gauge a student's understanding of a reading, article or external source.</div>                                
                    </div>{checkIfStepIsComplete("create_conversation") ? <IoIosCheckmarkCircle color="#1AB057" size={40} className="px-8" /> : null}</div>
                    <div className="w-full flex items-center mt-8">
                                    <Button onClick={async ()=>{
                                            await updateGettingStartedStatus("create_conversation")

                                            props.history.push('/teacher/create')
                                        }} size={'large'} type="primary" className="text-white font-semibold cursor-pointer">
                                        Create Your First Conversation
                                    </Button>
                                </div>
                </Card> */}
            </div>
        </div>
    </div>
  ) : <div className="w-full flex items-center justify-center h-screen">
    <ReactLoading
            type={"spin"}
            color={"purple"}
            height={30}
            width={30}
        />
  </div>;
}

export default GettingStarted;
