import React, { useEffect, useState, useContext } from 'react';
import {  createUserWithEmailAndPassword, sendEmailVerification, signInWithPopup, GoogleAuthProvider  } from 'firebase/auth';
import { auth } from '../utils/firebase';
import { Button, Form, Input, Card, Alert } from 'antd' 
import { toast } from 'react-toastify';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { getUser } from '../api/user';
import { FcGoogle } from "react-icons/fc"
import { SherpaContext } from '../utils/sherpaContext';

const provider = new GoogleAuthProvider();

const Signup = (props) => {

    const [signupForm] = Form.useForm();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { setUserToken, userToken } = useContext(SherpaContext)
    
    const queryParams = new URLSearchParams(window.location.search);
    const assignmentid = queryParams.get('assignmentid');

    useEffect(() => {
        if (userToken) {
            if (userToken.details.role === "student"){
                if (assignmentid) {
                    props.history.push('/student/assignment/' + assignmentid)    
                } else {
                    props.history.push('/student')
                }
            } else if (userToken.details.role === "teacher") {
                if (assignmentid) {
                    props.history.push('/student/assignment/' + assignmentid)    
                } else {
                    props.history.push('/teacher/gettingstarted')
                }
            } else {
                props.history.push('/onboarding' + (assignmentid ? `?assignmentid=${assignmentid}` : ''))
            }
        }
    }, [userToken])

    const onSubmit = async (values) => {
      setIsSubmitting(true)  
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, values.email, values.password);
        // await updateProfile(userCredential.user, {
        //   displayName: values.name,
        // });
        await sendEmailVerification(userCredential.user)
        toast.info("Please check your inbox to verify your email!")
        await auth.signOut();
        // props.history.push('/login')
        
      } catch (error) {
        const errorMessage = error.message;
        toast.error("Error: " + errorMessage);
      }
      setIsSubmitting(false)
    }

    const setToken = async (user) => {
        const user_details = await getUser();
        setUserToken({
            user,
            details: user_details
        });
    }

 
  return (
    <div className="flex w-full h-screen justify-center items-center">
            <Card className="w-[500px] border-0">
                {!assignmentid ? 
                    <>
                    <div 
                        onClick={()=>{
                            window.location.href = "https://sherpalabs.co"
                        }}
                        className="text-purple-500 flex items-center self-start gap-2 cursor-pointer"
                        style={{
                            fontWeight: '500'
                        }}
                    >
                        <AiOutlineArrowLeft />
                        <span>Back Home</span>
                    </div>
                    
                    <div className="flex w-full flex-col gap-4">
                        <div className="text-lg text-left mb-8 mt-4 font-bold">Make a New Account</div>
                    </div>
                </>
                : 
                <div className="flex w-full flex-col mb-4">
                    <div className="text-lg text-left mt-4 font-bold">Sign Up to Speak On It!</div>
                    <p className="text-gray-500">Sherpa is a platform to practice <b>explaining your thinking</b>. You are about to engage in in a engaging, enjoyable, and educational 10-min <b>voice + video enabled</b> conversation about your work, or a reading!</p>
                </div>
                }
                
                <Form
                    form={signupForm}
                    layout="vertical"
                    className="w-full"
                    onFinish={onSubmit}
                >
                    <Button
                            className="w-full mb-8 flex justify-center items-center gap-2 font-bold py-6"
                            onClick={async () => {
                                setUserToken(undefined); // trigger loading

                                try {
                                    const result = await signInWithPopup(auth, provider);
                                    props.history.push('/login')
                                    setToken(result.user);
                                } catch (error) {
                                    const errorMessage = error.message;
                                    toast.error(errorMessage);
                                }
                            }}

                        ><FcGoogle size={25}/> Sign Up with Google</Button>
                    <Form.Item
                        label="Email Address"
                        name="email"
                        rules={[
                        {
                            required: true,
                            message: "Your email can't be empty!",
                        },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[
                        {
                            required: true,
                            message: "Your password can't be empty!",
                        },
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="Confirm Password"
                        name="confirmPassword"
                        rules={[
                        {
                            required: true,
                            message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        }),
                        ]}
                    >
                        <Input.Password />
                    </Form.Item>

                    
                    <Button 
                        loading={isSubmitting}
                        htmlType='submit'
                        type="primary"
                        className="w-full text-center"
                        style={{ background: '#9C1FFF'}}
                    >
                        Sign Up
                    </Button>
                    <div className="mt-8 flex gap-2">
                        <span className="opacity-60">Already have an account?</span>
                        <a className="font-bold text-purple-500 hover:text-purple-300" onClick={()=>{
                            props.history.push('/login' + (assignmentid ? `?assignmentid=${assignmentid}` : ''))
                        }}>Login</a>
                    </div>
                </Form>
            </Card>
        </div>
  )
}
 
export default Signup