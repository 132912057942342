import React, { useState, useRef, useEffect, useContext } from "react";
import { FaAudible, FaMicrophone, FaStop } from 'react-icons/fa'
import { uploadToS3 } from '../api/s3'
import { SherpaContext } from '../utils/sherpaContext';
import { compileCurrentTranscript, processQuestion } from '../api/videos'
import { updateConversation, improveVideo } from "../api/conversation";
import { getAIQuestion } from "../api/chat";
import { v4 as uuidv4 } from 'uuid';
import ScrollFade from "@benestudioco/react-scrollfade";
import { split } from "sentence-splitter";
import { Ripples, RaceBy, Pulsar } from '@uiball/loaders'
import TextyAnim from "rc-texty"
import { toast, Slide } from "react-toastify";
import { Button, Form, Input, Tooltip, Select, Slider } from "antd";
import Sherpa from '../img/sherpa_old.png'
import BugReportModal from "../components/BugReportModal"
import textToSpeech from '../utils/elevenLabs'
import { IoMdVolumeHigh, IoMdVolumeOff } from 'react-icons/io'
import ProgressBar from "@ramonak/react-progress-bar";
import { MdQuestionMark } from 'react-icons/md'
import { evaluateConversationAsync } from "../api/evaluate";
import { lti_submitAssignment } from "../api/lti";
import { getConversationCount } from "../api/survey";
import { submitSurvey } from "../api/survey";

const videoMimeType = 'video/webm; codecs="opus,vp8"';
const audioMimeType = 'audio/webm';

const BLACKLIST_EMAILS = ["uw.edu", "com302twitter@gmail.com"]
const DefaultForm = () => {
	return (
		<>
			<Form.Item
				name="details"
				label="We’d love to hear about your conversation. How did you feel? Do you have any thoughts about the experience?"
			>
				<Input.TextArea rows={4} />
			</Form.Item>
			<Form.Item
				name="classroom_breakin"
				label="Is there anything you forgot to mention in your conversation with Sherpa you would like to include here"
			>
				<Input.TextArea rows={4} />
			</Form.Item>
			<Button onClick={() => {
				window.open("https://calendly.com/shaury-1/sherpa-with-shaurya", "_blank");
			}}
				className="w-full mb-4"
				type="dashed"
			>
				Speak with Sherpa Founders
			</Button>
		</>
	)
}
const TeacherForm = () => {
	return (
		<>
			<Form.Item
				name="student_learning"
				label="Using Sherpa, what have you learned about your students?"
				rules={[
					{
						required: true,
						message: "Please enter at least 10 words.",
						min: 10,
					},
				]}
			>
				<Input.TextArea rows={4} />
			</Form.Item>
			<Form.Item
				name="question_rating"
				label="What do you think about the questions that the Sherpa AI asked? Please explain."
				rules={[
					{
						required: true,
						message: "Please enter at least 10 words.",
						min: 10,
					},
				]}
			>
				<Input.TextArea rows={4} />
			</Form.Item>

			<Form.Item
				name="use_cases"
				label="How are you using Sherpa in your classroom?"
				rules={[
					{
						required: true,
						message: "Please enter at least 10 words.",
						min: 10,
					},
				]}
			>
				<Input.TextArea rows={4} />
			</Form.Item>

			<Form.Item
				label={<span style={{ fontSize: '1.2em' }}>On a scale of 1-10, how likely is it that you would recommend Sherpa to another teacher?</span>}
				name="recommend"
				rules={[
					{
						required: true,
						message: "You cannot leave this field empty!",
					},
				]}
			>
				<Slider
					min={1}
					max={10}
					marks={{
						1: 'Not likely',
						10: 'Very likely'
					}}
				/>
			</Form.Item>
			<Button onClick={() => {
				window.open("https://calendly.com/shaury-1/sherpa-with-shaurya", "_blank");
			}}
				className="w-full mb-4"
				type="dashed"
			>
				Speak with Sherpa Founders
			</Button>
		</>
	)
}



const StudentForm = () => {
	const [convoCount, setConvoCount] = useState(null)

	useEffect(() => {
		const getCount = async () => {
			const count = await getConversationCount()
			setConvoCount(count.count)
		}
		getCount()
	}, [])

	return (
		<>
			{convoCount === 1 ?
				<Form.Item
					label={<span style={{ fontSize: '1.2em' }}>How do you feel when you engage in a conversation with the Sherpa AI? Please explain.</span>}
					name="feel"
					rules={[
						{
							required: true,
							message: "Please enter at least 10 words.",
							min: 10,
						},
					]}
				>
					<Input.TextArea rows={4} placeholder="I feel nervous/excited/awkward/anxious because ..." />
				</Form.Item>
				: convoCount > 1 ?
					<Form.Item
						label={<span style={{ fontSize: '1.2em' }}>How has your perception and feeling towards the Sherpa AI changed the more you've used the tool? Please explain.</span>}
						name="feel_over_time"
						rules={[
							{
								required: true,
								message: "Please enter at least 10 words.",
								min: 10,
							},
						]}
					>
						<Input.TextArea rows={4} />
					</Form.Item>
					: null}
			<Form.Item
				label={<span style={{ fontSize: '1.2em' }}>After talking to the Sherpa AI, what do you think you learned in the conversation? Please explain.</span>}
				name="learning"
				rules={[
					{
						required: true,
						message: "Please enter at least 10 words.",
						min: 10,
					},
				]}
			>
				<Input.TextArea rows={4} />
			</Form.Item>
			<Form.Item
				label={<span style={{ fontSize: '1.2em' }}>Which feeling is the most representative of how you feel towards the Sherpa AI?</span>}
				name="feeling_option"
				rules={[
					{
						required: true,
						message: "Please select something!",
					},
				]}
			>
				<Select placeholder="Select your feeling">
					<Select.Option value="like">I really like using Sherpa!</Select.Option>
					<Select.Option value="excited">I feel excited to talk to Sherpa.</Select.Option>
					<Select.Option value="positive">I feel positive talking to Sherpa.</Select.Option>
					<Select.Option value="neutral">I feel neutral / indifferent talking to Sherpa — I don’t really have an opinion.</Select.Option>
					<Select.Option value="nervous">I feel nervous / stressed out when I talk to Sherpa.</Select.Option>
					<Select.Option value="uncomfortable">I feel uncomfortable talking to Sherpa.</Select.Option>
					<Select.Option value="dislike">I really do not like using Sherpa.</Select.Option>
				</Select>
			</Form.Item>
			<Form.Item
				label={<span style={{ fontSize: '1.2em' }}>How likely is it that you would recommend another teacher to use Sherpa in their classroom?</span>}
				name="recommend"
				rules={[
					{
						required: true,
						message: "You cannot leave this field empty!",
					},
				]}
			>
				<Slider
					min={1}
					max={10}
					marks={{
						1: 'Not likely',
						10: 'Very likely'
					}}
				/>
			</Form.Item>
		</>
	)
}
const Chat = ({
	conversation,
	setConversation,
	assignment,
	history
}) => {

	// Variables
	const withVideo = conversation.with_video ?? false
	const [mode, setMode] = useState("smooth")

	// Modals
	const [feedbackModal, setFeedbackModel] = useState(false)
	const [defaultModal, setDefaultModal] = useState(false)

	const MessagesEndRef = useRef(null);
	const scrollToBottom = () => {
		MessagesEndRef.current.scrollIntoView({ behavior: "smooth" });
		if (MessagesEndRef.current) {
			const scrollOptions = {
				behavior: "smooth",
				block: "end",
				inline: "nearest"
			};
			MessagesEndRef.current.scrollIntoView(scrollOptions);
		}
	};

	// Video & Audio stuff 
	const mediaRecorder = useRef(null);
	const mediaAudioRecorder = useRef(null);
	const liveVideoFeed = useRef(null);

	const [voiceMode, setVoiceMode] = useState(false)
	const [permission, setPermission] = useState(false);
	const [recordingStatus, setRecordingStatus] = useState("inactive");
	const [videoChunks, setVideoChunks] = useState([]);
	const [audioChunks, setAudioChunks] = useState([])

	// AI State 
	const [AIState, setAIState] = useState("thinking") // thinking, waiting, listening, talking
	const [currentQuestionType, setCurrentQuestionType] = useState(assignment.conversation_flow[conversation.up_to].type)

	// Animation stuff at the start
	const [initialLoad, setInitialLoad] = useState(false)
	const [secondLoad, setSecondLoad] = useState(false)

	// Streaming stuff 
	const [messagesInQueue, setMessagesInQueue] = useState([])
	const [currentIndex, setCurrentIndex] = useState(0);
	const [message, setMessage] = useState("")

	// More info about the conversation, statistics and all that, history, etc.
	const [transcript, setTranscript] = useState([])
	const [numVideosInConversation, setNumVideosInConversation] = useState(null)

	const sherpaContext = useContext(SherpaContext)

	const {
		userToken,
		ltiToken,
		updateIntroStatus
	} = sherpaContext;

	const [stream, setStream] = useState(null)
	const [audioStream, setAudioStream] = useState(null)

	const [stopTalkingCount, setStopTalkingCount] = useState(0);


	const turnOffCamera = () => {
		if (liveVideoFeed.current && liveVideoFeed.current.srcObject) {
			const tracks = liveVideoFeed.current.srcObject.getTracks();
			tracks.forEach((track) => {
				track.stop();
			});
			liveVideoFeed.current.srcObject = null;
		}
	}

	useEffect(() => {
		const keyDownHandler = (event) => {
			if (event.key === "Enter") {
				if (AIState === "waiting") {
					startRecording();
				} else if (AIState === "listening") {
					stopRecording()
				}
			}
		};

		document.addEventListener("keydown", keyDownHandler);

		return () => {
			document.removeEventListener("keydown", keyDownHandler);
		};
	}, [AIState]);

	useEffect(() => {
		const init = async () => {
			await new Promise(resolve => setTimeout(resolve, 3000));
			setInitialLoad(true)
			await new Promise(resolve => setTimeout(resolve, 4000));
			setSecondLoad(true)
		}

		init()

		// return async () => {
		// 	if (window.confirm("Are you sure you want to leave this conversation??")) {
		// 		turnOffCamera();	
		// 	}
		// }
	}, [])

	useEffect(() => {
		const init = async () => {
			const compiled_videos = await compileCurrentTranscript(conversation)
			setNumVideosInConversation(compiled_videos.num_videos)
			setTranscript(compiled_videos.transcript)

			if (withVideo) {
				startCamera()
			} else {
				startAudioRecorder()
			}
		}

		init();
	}, [])
	const promptAI = async (type, last_question_to_retry = null) => {
		setMessage("")
		getAIQuestion(conversation, assignment, conversation.up_to, transcript, (message) => {
			setMessage(prev => prev + message)
		}, userToken.details.name, setAIState, type, last_question_to_retry)
	}

	useEffect(() => {
		if (transcript && initialLoad) {
			promptAI("chat");
		}
	}, [transcript, secondLoad])

	useEffect(() => {
		let timeoutId;
		if (AIState === "talking") {
			timeoutId = setTimeout(() => {
				setAIState("waiting");
			}, 13000);
		}
		return () => {
			clearTimeout(timeoutId);
		};
	}, [AIState]);

	const sherpaVoice = useRef()
	useEffect(() => {
		const speak = async () => {
			if (AIState === "waiting" && message && voiceMode) {
				const audio = await textToSpeech("21m00Tcm4TlvDq8ikWAM", message, {
					stability: 0.6,
					similarity_boost: 0.6,
				});

				sherpaVoice.current = audio;
			} else {
				if (sherpaVoice.current) {
					sherpaVoice.current.pause();
					sherpaVoice.current.currentTime = 0;
				}
			}
		}

		speak();

	}, [AIState, voiceMode])

	const submitAssignment = async () => {
		const response = await lti_submitAssignment(ltiToken?.ltik);

		return response
	}

	// useEffect(()=>{
	// 	submitAssignment();
	// }, [ltiToken])

	useEffect(() => {
		if (conversation && conversation["up_to"]) {
			const question_type = assignment['conversation_flow'][conversation['up_to']]['type']
			setCurrentQuestionType(question_type)
			console.log(currentQuestionType)
			console.log(question_type)

			// Complete, if required!
			if (question_type === "closure" && !conversation.is_complete) {
				console.log("we are here chat.jsx")
				updateConversation(conversation.id, {
					"is_complete": true,
					"is_evaluating": true
				})

				// evaluate! 
				Promise.resolve(evaluateConversationAsync(conversation.id, ltiToken ? ltiToken?.ltik : null))
			}
		}
	}, [conversation, assignment])

	useEffect(() => {
		const updateQueue = async () => {
			if (message) {
				if (message.slice(currentIndex).match(/[.!?]$/)) {
					// const sentences = split(message).filter(item => item.type === "Sentence").map(item => item.raw)

					setMessagesInQueue([...messagesInQueue, message.slice(currentIndex).trim()])
					setCurrentIndex(message.length);
				}
			}
		}

		updateQueue();

	}, [message])
	// useEffect(()=>{
	// 	const report = async (truth, array) => {
	// 		await reportBug("SEVERE!!!!", "MESSAGE FADE IN ISSUE", {
	// 			truth,
	// 			array
	// 		})
	// 	}
	// 	if (AIState === "waiting") {
	// 		if (messagesInQueue.join(" ") === message) {
	// 			console.log("LINED UP!")
	// 		} else {
	// 			report(message, messagesInQueue)
	// 		}
	// 	}
	// }, [AIState])

	// Display messages at a constant rate
	// useEffect(() => {
	// 	if (messagesInQueue.length > messagesInQueue.length) {
	// 	const timeout = setTimeout(() => {
	// 		setmessagesInQueue(messagesInQueue.slice(0, messagesInQueue.length + 1));
	// 	}, messagesInQueue.length === 1 ? 0 : MS_PER_MESSAGE);
	// 	return () => clearTimeout(timeout);
	// 	}
	// }, [messagesInQueue, messagesInQueue]);


	const startCamera = async () => {

		//get video and audio permissions and then stream the result media stream to the videoSrc variable
		if ("MediaRecorder" in window) {
			try {
				const videoConstraints = {
					audio: false,
					video: true,
				};
				const audioConstraints = { audio: true };

				// create audio and video streams separately
				const audioStream_ = await navigator.mediaDevices.getUserMedia(
					audioConstraints
				);
				const videoStream = await navigator.mediaDevices.getUserMedia(
					videoConstraints
				);

				setPermission(true);

				//combine both audio and video streams

				const combinedStream = new MediaStream([
					...videoStream.getVideoTracks(),
					...audioStream_.getAudioTracks(),
				]);

				setStream(combinedStream);
				setAudioStream(audioStream_)

				//set videostream to live feed player
				liveVideoFeed.current.srcObject = videoStream;
			} catch (err) {
				alert(err.message);
			}
		} else {
			alert("The MediaRecorder API is not supported in your browser.");
		}
	};

	const startAudioRecorder = async () => {
		if ("MediaRecorder" in window) {
			try {
				const mediaStream = await navigator.mediaDevices.getUserMedia({
					audio: true,
					video: false,
				});
				setPermission(true);
				setAudioStream(mediaStream);
			} catch (err) {
				alert(err.message);
			}
		} else {
			alert("The MediaRecorder API is not supported in your browser.");
		}
	};

	const startRecording = async () => {
		setRecordingStatus("recording");
		setAIState("listening")

		// video

		if (withVideo) {
			const media = new MediaRecorder(stream, { type: videoMimeType });
			mediaRecorder.current = media;
			mediaRecorder.current.start();

			let localVideoChunks = [];

			mediaRecorder.current.ondataavailable = (event) => {
				if (typeof event.data === "undefined") return;
				if (event.data.size === 0) return;
				localVideoChunks.push(event.data);
			};

			setVideoChunks(localVideoChunks);
		}

		// audio
		const media2 = new MediaRecorder(audioStream, { type: audioMimeType });

		mediaAudioRecorder.current = media2;

		mediaAudioRecorder.current.start();

		let localAudioChunks = [];

		mediaAudioRecorder.current.ondataavailable = (event) => {
			if (typeof event.data === "undefined") return;
			if (event.data.size === 0) return;
			localAudioChunks.push(event.data);
		};

		setAudioChunks(localAudioChunks);
	};


	const stopRecording = () => {
		// Start loading
		setAIState("thinking")

		// Reset Search
		setCurrentIndex(0)
		setMessagesInQueue([])

		// Recording stuff
		setRecordingStatus("inactive");

		// video
		if (withVideo) {
			mediaRecorder.current.stop();
		}
		mediaAudioRecorder.current.stop();
		setStopTalkingCount(prevCount => prevCount + 1);


		mediaAudioRecorder.current.onstop = async () => {
			// current question info
			const current_question = assignment['conversation_flow'][conversation['up_to']]

			// Upload recordings to S3 
			const mediaUUID = uuidv4()

			const audioBlob = new Blob(audioChunks, { type: audioMimeType });
			const audioUrl = await uploadToS3(mediaUUID + "_audio.webm", audioBlob);
			console.log("media audio url: ", audioUrl)

			// Transcribe video and get transcription 
			const languageMap = {
				English: "en",
				Spanish: "es",
				German: "de",
				French: "fr",
				Pinyin: "zh",
				Russian: "ru",
				Arabic: "ar",
				Portuguese: "pt",
				Bengali: "bn",
				Hindi: "hi",
				Japanese: "ja",
				Korean: "ko",
				Italian: "it"
			  };
			  
			  // Use the map to get the Deepgram language key
			const deepgramLanguageKey = languageMap[assignment.language];
			  
			const data = await processQuestion({
				"conversation_id": conversation.id,
				"question": message,
				"questionNum": numVideosInConversation + 1,
				"mediaUrl": audioUrl,
				"mediaUUID": mediaUUID,
				"questionType": current_question,
				"minWords": assignment.min_words,
				"language": deepgramLanguageKey,
			})

			const rawTranscribedText = data.transcription
			console.log("transcription: ", rawTranscribedText)

			// If we pass the guard rails ... 
			if (!data.is_warning) {

				// Update number
				setNumVideosInConversation(numVideosInConversation + 1)

				// if finish.. then end it! otherwise, increment 
				if (current_question['type'] === 'closure') {
					console.log("we here slime")
					updateConversation(conversation.id, {
						"is_complete": true,
						"is_evaluating": true,

					})
					console.log("we here now slime")

					setConversation({
						...conversation,
						"is_complete": true,
						"is_evaluating": true,
					})
				} else {
					updateConversation(conversation.id, {
						"up_to": conversation['up_to'] + 1
					})
					setConversation({
						...conversation,
						"up_to": conversation['up_to'] + 1
					})
				}

				// Update transcript
				setTranscript([...transcript,
				{
					"role": "AI",
					"content": message
				},
				{
					"role": "Student",
					"content": rawTranscribedText
				}
				])
			} else {
				// if, you did not pass the warning... 
				promptAI("warning", message);
			}

			// Improve Transcript (this does not have to be async)
			improveVideo(data.video_id)

			// Upload Video
			if (withVideo) {
				const videoBlob = new Blob(videoChunks, { type: videoMimeType });
				uploadToS3(mediaUUID + "_video.webm", videoBlob); // no await
			}

			// Reset! 
			setVideoChunks([]);
			setAudioChunks([])
		};
	};



	useEffect(() => {
		let timeoutId, timeoutId2;
		const warnTiming = async () => {
			if (AIState === "waiting" && currentQuestionType !== "closure" && assignment.time_limit) {
				timeoutId = setTimeout(() => {
					toast.warn("Keep thinking, but in a few seconds, we'll begin the conversation.", {
						position: "top-center",
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						transition: Slide
					});
				}, (assignment.time_limit_seconds * 1000) + 5000);
				timeoutId2 = setTimeout(() => {
					startRecording();
					toast.error("Let's Begin! 😊", {
						position: "top-center",
						hideProgressBar: true,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						transition: Slide
					});
				}, (assignment.time_limit_seconds * 1000) + 10000);
			}
		}

		warnTiming();
		return () => {
			clearTimeout(timeoutId)
			clearTimeout(timeoutId2)
		};

	}, [AIState])


	useEffect(() => {
		if (MessagesEndRef.current) {
			scrollToBottom();
		}
	}, [MessagesEndRef])
	return conversation && numVideosInConversation != null ? (
		<>
			{conversation.is_complete ?
				<div className="text-center flex flex-col gap-4">
					<div className="text-md text-3xl font-bold">You've already spoken about this assignment!</div>
				</div>
				:
				<>
					{secondLoad ?
						<div className="flex flex-col gap-2 absolute -top-14 w-[828px] text-gray-400" style={{
							animation: 'fadeIn 2s'
						}}>
							<div className="w-full text-right">
								{(assignment.conversation_flow.length - conversation.up_to - 1) === 1 ?
									`${assignment.conversation_flow.length - conversation.up_to - 1} question left` :
									`${assignment.conversation_flow.length - conversation.up_to - 1} questions left`
								}
							</div>
							<ProgressBar height={10} isLabelVisible={false} completed={(conversation.up_to) / (assignment.conversation_flow.length - 1) * 100} className="w-full" bgColor='#A78BFA' baseBgColor={'#f7f7f7'} />
						</div>
						: null}

					<BugReportModal userToken={userToken} type="Thank you! We would love to hear your thoughts." isOpen={feedbackModal} source="Survey" setIsOpen={setFeedbackModel} onComplete={async (values) => {

						if (ltiToken) {
							window.location.reload();
						} else {
							if (userToken.details.role === "teacher") {
								await updateIntroStatus("completed_survey")
								await submitSurvey({
									...values,
									"conversation_id": conversation.id
								})

								history.push('/teacher/gettingstarted')
							} else if (userToken.details.role === "student") {
								await updateIntroStatus("completed_survey")
								await submitSurvey({
									...values,
									"conversation_id": conversation.id
								})

								history.push('/student')
							}
						}

					}} customForm={
						userToken.details.role === "student" ?
							<StudentForm />
							: <TeacherForm />
					} />

					<BugReportModal
						userToken={userToken}
						type="Thank you! We would love to hear your thoughts."
						isOpen={defaultModal}
						source="Feedback"
						setIsOpen={setDefaultModal}
						onComplete={async (values) => {
							if (ltiToken) {
								window.location.reload();
							} else {
								if (userToken.details.role === "teacher") {

									history.push('/teacher/gettingstarted')
								} else if (userToken.details.role === "student") {

									history.push('/student')
								}
							}
						}}
						customForm={
							<DefaultForm />
						} />

					<div className="relative flex flex-row h-full w-full gap-3 justify-between p-2">
						{!secondLoad ?
							<div className={`-top-1 -left-1 absolute h-full w-full bg-white flex justify-center items-center flex-col gap-8 ${initialLoad ? 'fadeOut' : ''}`} style={{ zIndex: 9999999, borderRadius: '30px' }}>
								{numVideosInConversation === 0 ?
									<>
										{assignment.assignment_type === "Reading Responses" ?
											<TextyAnim duration={1000} interval={10} type="mask-top" className="text-2xl font-bold text-center" >Reading the text...</TextyAnim>
											:
											<TextyAnim duration={1000} interval={10} type="mask-top" className="text-2xl font-bold text-center" >Reading your essay...</TextyAnim>
										}
									</>

									:
									<TextyAnim duration={1000} interval={10} type="mask-top" className="text-2xl font-bold text-center" >Resuming your conversation...</TextyAnim>
								}

								<RaceBy
									size={80}
									lineWeight={5}
									speed={1.4}
									color="#a68bfa"
								/>
							</div>
							: null}
						{withVideo ?
							<>
								{/* Video */}
								<div className="relative w-2/3">

									{/* Video */}
									<video ref={liveVideoFeed} autoPlay className="live-player w-full h-full" style={{ transform: 'scaleX(-1)', borderRadius: '30px' }}></video>

								{/* Mute Button */}
								<div className="absolute top-5 right-5">
									{voiceMode ? 
										<Tooltip title="Click to mute Sherpa">
											<IoMdVolumeHigh style={{
												filter: 'drop-shadow(0px 0px 2px rgb(0 0 0 / 0.3))'
											}} size={20} color="white" className="cursor-pointer" onClick={()=>{
												setVoiceMode(false)
											}}/>
										</Tooltip> : 
										<Tooltip title="Click to get Sherpa to talk!">
											<IoMdVolumeOff style={{
												filter: 'drop-shadow(0px 0px 2px rgb(0 0 0 / 0.3))'
											}}  size={20} color="white" className="cursor-pointer" onClick={()=>{
												setVoiceMode(true)
											}}/>
										</Tooltip>
									}
								</div>
								{/* Recording Pulse */}
								{AIState === "listening" ? 
									<div className="absolute top-0 left-0 text-red-500 flex gap-2 items-center font-bold p-4 fade" style={{
										zIndex: 999999,
										// borderRadius: '30px',
										// background: 'rgba(0,0,0,0.3)',
										animation: 'fadeIn 2s'
									}}>
										<Ripples 
										size={45}
										speed={4} 
										color="#8049ff" 
										/>
									</div>
								: null
								}
							
							{/* Buttons */}
							{AIState === "waiting" || AIState === "listening"? 
								<>
								{recordingStatus === "inactive" ? 
										<div className="absolute right-2 p-2 cursor-pointer rounded-full text-white text-center flex justify-center items-center fade" style={{
											zIndex: 999,
											bottom: 70,
											background: 'rgba(0,0,0,0.3)',
											animation: 'fadeIn 2s',
										}}
											onClick={()=>{
												setAIState("thinking")
												setCurrentIndex(0)
												setMessagesInQueue([])
												
												promptAI("rephrase", message);
											}}
										>	
										<Tooltip title="I don't understand the question!">
											<MdQuestionMark />
										</Tooltip>	
										</div>
								: null
								}
								<div className="absolute left-0 p-4 text-white text-center h-8 flex justify-center items-center gap-4 fade" style={{
									zIndex: 999,
									bottom: '2px',
									background: 'rgba(0,0,0,0.3)',
									width: '93.80%',
									borderRadius: '30px',
									animation: 'fadeIn 2s'
								}}>	
								{currentQuestionType === "closure" ? 
									<>
										<Button
											onClick={()=>{
												if (userToken.details.intro_status.completed_survey || userToken['user']['email'].includes("uw.edu")){
													setDefaultModal(true)
												} else {
													setFeedbackModel(true)
												}
											}}
											type="primary"
											style={{ background: '#A78BFA' }}
										>Finish Conversation</Button>
									</>
									:
									<>
										{recordingStatus === "inactive" ? (
											<>
												<div className="rounded-full shadow-md flex items-center justify-center p-3 cursor-pointer bg-white" onClick={()=>{
													startRecording()
												}}>
													<FaMicrophone color="#9C1FFF" size={16} />
												</div>
												<span className="text-white">Start Talking!</span>
											</>
										) : null}
										{recordingStatus === "recording" ? (
											<>
											<div className="bg-[#9C1FFF] rounded-full shadow-md flex items-center justify-center p-3 cursor-pointer" onClick={()=>{
												stopRecording()
											}}>
												<FaStop color="#ffffff" size={16}/>
											</div>
											<span className="text-white font-bold">Stop Talking</span>
										</>
										) : null}
									</>
								}
								</div>
								</>
							: null }
						</div>
						
						{/* Chat sidebar */}
						<div className={`flex flex-col w-1/3 items-start justify-start h-full overflow-auto rounded-lg text-gray-800`}>
							<ScrollFade />
							{AIState === "thinking" ? 
								<div className="w-full h-full flex flex-col justify-center items-center">
									<Pulsar 
										size={40}
										speed={1.75} 
										color="#a68bfa" 
									/>
									{(assignment.assignment_type === "IB Oral" && (stopTalkingCount === 1)) ?
													<div className="text-white mt-4 font-semibold text-center bg-purple-400 rounded-lg p-2 shadow-lg">Analyzing the first half of your Internal Oral. Please allow up to 90 seconds.</div>
													: assignment.assignment_type === "IB Oral" && stopTalkingCount === 2 ?
														<div className="text-white mt-4 font-semibold text-center bg-purple-400 rounded-lg p-2 shadow-lg">Analyzing the second half of your Internal Oral. Please allow approximately 90 seconds.</div>
													: null
												}
								</div>
							: 
								<>
									{mode === "reliable" ? 
									<>
									{split(message).filter(item => item.type === "Sentence").map(item => item.raw).map((message, index) => {
										return (
											<div key={index} className="rounded-lg bg-violet-400 text-white p-2 fade text-left mb-2" style={{
												animation: 'fadeIn 1s'
											}}>
												<TextyAnim duration={200} interval={0}>{message}</TextyAnim>
											</div>
										)
									})}
									</>
									: mode === "smooth" ? 
									<>
										{messagesInQueue.map((message, index) => {
											return (
												<div key={index} className="rounded-lg bg-violet-400 text-white p-2 fade text-left mb-2" style={{
													animation: 'fadeIn 2s'
												}}>
													<TextyAnim duration={1000} interval={10}>{message}</TextyAnim>
												</div>
											)
										})}
									</>
									: null }
									{/* {AIState === "waiting" ? 
										<UserFeedbackComponent currentMessage={message} traceId={conversation.id} />
									: null } */}
											<div ref={MessagesEndRef} />
										</>
									}
								</div>
							</>
							: <div className="flex flex-col gap-8 px-2 py-0 w-full">

								{/* Liz */}
								<div className="flex gap-4 items-center justify-start">
									<img src={Sherpa} className="w-20 rounded-xl" />
									<div className="flex flex-col">
										<div className="flex items-center gap-1">
											<div className="text-lg text-gray-700">Sherpa</div>

											{voiceMode ?
												<Tooltip title="Click to mute Sherpa">
													<IoMdVolumeHigh size={20} color="gray" className="cursor-pointer" onClick={() => {
														setVoiceMode(false)
													}} />
												</Tooltip> :
												<Tooltip title="Click to get Sherpa to talk!">
													<IoMdVolumeOff size={20} color="gray" className="cursor-pointer" onClick={() => {
														setVoiceMode(true)
													}} />
												</Tooltip>
											}
										</div>
										<div className="text-md text-gray-500">Your AI guide</div>
									</div>
								</div>


								{/* Chat sidebar */}

								<div className={`flex flex-col w-full items-start justify-start h-full overflow-auto rounded-lg text-gray-800`}>
									<ScrollFade />
									{AIState === "thinking" ?
										<div className="w-full h-full flex justify-center items-center">
											<div className="flex flex-col items-center">
												<Pulsar
													size={40}
													speed={1.75}
													color="#a68bfa"
												/>

												{(assignment.assignment_type === "IB Oral" && (stopTalkingCount === 1)) ?
													<div className="text-white mt-4 font-semibold text-center bg-purple-400 rounded-lg p-2 shadow-lg">Analyzing the first half of your Internal Oral. Please allow up to 90 seconds.</div>
													: assignment.assignment_type === "IB Oral" && stopTalkingCount === 2 ?
														<div className="text-white mt-4 font-semibold text-center bg-purple-400 rounded-lg p-2 shadow-lg">Analyzing the second half of your Internal Oral. Please allow approximately 90 seconds.</div>
														: null
												}
											</div>
										</div>
										:
										<>
											{mode === "reliable" ?
												<>
													{split(message).filter(item => item.type === "Sentence").map(item => item.raw).map((message, index) => {
														return (
															<div key={index} className="rounded-lg bg-violet-400 text-white p-2 fade text-left mb-2" style={{
																animation: 'fadeIn 1s'
															}}>
																<TextyAnim duration={200} interval={0}>{message}</TextyAnim>
															</div>
														)
													})}
												</>
												: mode === "smooth" ?
													<>
														{messagesInQueue.map((message, index) => {
															return (
																<div key={index} className="rounded-lg bg-violet-400 text-white p-2 fade text-left mb-2" style={{
																	animation: 'fadeIn 2s'
																}}>
																	<TextyAnim duration={1000} interval={10}>{message}</TextyAnim>
																</div>
															)
														})}
													</>
													: null}
											{/* {AIState === "waiting" ? 
									<UserFeedbackComponent currentMessage={message} traceId={conversation.id} />
								: null } */}
											<div ref={MessagesEndRef} />
										</>
									}
								</div>
								{/* Buttons */}
								{AIState === "waiting" || AIState === "listening" ?
									<div className="text-gray-400 w-full flex items-center gap-4 fade" style={{
										animation: 'fadeIn 2s'
									}}>
										{currentQuestionType === "closure" ?
											<>
												<span className="text-gray-400 flex items-center gap-2 fade-in justify-between w-full">
													<TextyAnim duration={1000} interval={10} type="mask-top">
														Thank you so much engaging in this conversation!
													</TextyAnim>
													<Button
														onClick={() => {
															if (userToken.details.intro_status.completed_survey || BLACKLIST_EMAILS.some(type => userToken['user']['email'].includes(type))) {
																setDefaultModal(true)
															} else {
																setFeedbackModel(true)
															}
														}}
														type="primary"
														style={{ background: '#A78BFA' }}
													>Finish Conversation</Button>
												</span>
											</>
											:
											<>
												{AIState === "waiting" ? (
													<>
														<span className="text-gray-400 flex items-center gap-2 fade-in justify-between w-full">
															<TextyAnim duration={1000} interval={10} type="mask-top" style={{
																fontSize: '14px'
															}}>
																Take a few seconds to understand the question. Press
																enter when you're ready!
															</TextyAnim>

															<div className="flex items-center gap-2">
																<Tooltip title="Rephrase the question">
																	<Button className="text-gray-400" style={{
																		fontFamily: 'Nunito'
																	}} onClick={() => {
																		setAIState("thinking")
																		setCurrentIndex(0)
																		setMessagesInQueue([])

																		promptAI("rephrase", message);
																	}}>
																		I don't understand the question
																	</Button>
																</Tooltip>
																<Button style={{
																	fontFamily: 'Nunito',
																	background: '#A68BFA',
																	color: "white",
																	border: 0
																}}
																	onClick={() => {
																		startRecording();
																	}}>
																	Start Talking!
																</Button>
															</div>
														</span>
													</>
												) :

													AIState === "listening" ?
														(
															<>
																<span className="text-gray-400 flex w-full flex-col gap-2 fade-in" style={{
																	color: '#A68BFA'
																}}>
																	<div className="flex flex-row items-center justify-between">
																		<div className="flex items-center w-full gap-2">
																			<div class="dot dot--basic"></div>{" "}
																			<TextyAnim duration={1000} interval={10} type="mask-top">
																				Sherpa is now listening... start speaking! Press 'enter'
																				to finish.
																			</TextyAnim>
																		</div>

																		<Tooltip title="Stop Talking!">
																			<Button
																				shape="circle"
																				className="self-start"
																				onClick={() => {
																					stopRecording()
																				}}
																				style={{
																					background: "#A68BFA"
																				}}
																				icon={<FaStop color="#ffffff" size={10} />}
																			/>
																		</Tooltip>
																	</div>

																</span>
															</>
														)

														: null}
											</>
										}
									</div>
									: null}


							</div>}

					</div>
				</>
			}
		</>
	) : null
};

export default Chat;




