import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, Input, Button, message, Spin } from 'antd';
import { SherpaContext } from '../utils/sherpaContext';
import { getUser, updateUser } from '../api/user'; // API calls for user data

const ProfileModal = ({ isOpen, setIsOpen }) => {
  const { userToken } = useContext(SherpaContext);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (isOpen) {
      getUser(userToken)
        .then(data => {
          setUser(data);
          form.setFieldsValue(data); // Set form fields with user data
          setLoading(false);
        })
        .catch(error => {
          message.error('Failed to load profile');
          setLoading(false);
        });
    }
  }, [isOpen, form, userToken]);

  const onFinish = (values) => {
    updateUser(userToken, values)
      .then(data => {
        setUser(data);
        setEditing(false);
        message.success('Profile updated successfully');
      })
      .catch(error => {
        message.error('Failed to update profile');
      });
  };

  return (
    <Modal
      title="My Profile"
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={null}
    >
      {loading ? (
        <Spin tip="Loading..." />
      ) : (
        <div>
          {editing ? (
            <Form
              layout="vertical"
              form={form}
              initialValues={user}
              onFinish={onFinish}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input your name!' }]}
              >
                {/* <Input />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please input your email!' }]}
              > */}
                {/* <Input />
              </Form.Item>
              <Form.Item
                label="Grade"
                name="grade"
                rules={[{ required: true, message: 'Please input your grade!' }]}
              > */}
                <Input />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
                <Button
                  style={{ marginLeft: '10px' }}
                  onClick={() => setEditing(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <div>
              <p><strong>Name:</strong> {user.name}</p>
              <p><strong>Email:</strong> {user.email}</p>
              {/* <p><strong>Grade:</strong> {user.grade}</p> */}
              <p><strong>Role:</strong> {user.role}</p>
              <p><strong>Status:</strong> {user.status}</p>
              <Button type="primary" onClick={() => setEditing(true)}>
                Edit Profile
              </Button>
            </div>
          )}
        </div>
      )}
    </Modal>
  );
};

export default ProfileModal;
