import React, { useContext, useState, useEffect } from 'react';
import { Tag, Table, Modal, Menu, List, Typography, Avatar, Button, Divider, Card, Image, Tooltip, Input } from 'antd';
import { UserOutlined, CopyOutlined, SendOutlined, SearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { getCourseById, getCourseAssignments, getCourseParticipants, getCourseAnnouncements, createCourseAnnouncement } from '../api/course';
import { toast } from 'react-toastify';
import { convertTimeToTimeAgo, convertUtcToNow } from '../utils/general'
import ReactLoading from "react-loading";
import { CgTranscript } from 'react-icons/cg'
import { BsFillPlayFill } from 'react-icons/bs';

import { SherpaContext } from '../utils/sherpaContext';

import bg1 from '../img/shortbg1.jpeg';
import bg2 from '../img/shortbg2.jpeg';
import bg3 from '../img/shortbg3.jpeg';
import bg4 from '../img/shortbg4.jpeg';
import bg5 from '../img/shortbg5.jpeg';
import bg6 from '../img/shortbg6.jpeg';

const backgrounds = [bg1, bg2, bg3, bg4, bg5, bg6];
const { TextArea } = Input;

const Navbar = (props) => {
  const { courseId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialTab = searchParams.get('tab') || 'general';

  const [isLoading, setIsLoading] = useState(true)
  const [current, setCurrent] = useState('general');
  const [announcements, setAnnouncements] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [newAnnouncement, setNewAnnouncement] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [showDescription, setShowDescription] = useState(false);

  const [courseData, setCourse] = useState({});  // Added state for course data
  const [assignments, setAssignments] = useState([]);  // Added state for assignments
  const [participants, setParticipants] = useState({ teachers: [], assosciate_teachers:[], students: [] });  // Added state for participants
  const { userToken } = useContext(SherpaContext);
  

  const queryParams = new URLSearchParams(props.location.search);

  const getCurrentPage = () => {
    return queryParams.get('page') ? parseInt(queryParams.get('page')) : 1;
  }

  const COLUMNS = [
    {
      title: 'Assignment',
      dataIndex: 'title',
      key: 'title',
      width: '20%',
      render: (title) => {
        if (title?.length > 100) {
          return title.substring(0, 100) + "...";
        } else {
          return title;
        }
      }
    },
    {
      title: 'Status',
      key: 'is_complete',
      width: '5%',
      render: (_, record) => {
        const { conversation } = record;
        if (!conversation) {
          return <Tag color="red" className="status-tag">Unattempted</Tag>;
        }
        return conversation.is_complete ? <Tag color="lime" className="status-tag">Completed</Tag> : <Tag color="orange" className="status-tag">In Progress</Tag>;
      }
    },
    {
      title: 'Completed',
      dataIndex: ['conversation', 'completed_time'],
      key: 'completed_time',
      width: '5%',
      render: (timestamp) => {
        if (timestamp) {
          const now = convertUtcToNow(timestamp);
          return convertTimeToTimeAgo(now) ?? '';
        }
        return '';
      }
    },
    {
      title: 'Action',
      key: 'action',
      width: '5%',
      render: (_, record) => {
        if (!record.conversation || Object.keys(record.conversation).length === 0) {
          return <Button type="primary" onClick={() => { props.history.push('/student/assignment/' + record._id) }}>Start</Button>;
        }

        let showFeedback = record.student_ai_feedback;
        if (record.show_ai_feedback != null) {
          showFeedback = record.show_ai_feedback;
        }

        return record.conversation.is_evaluated && showFeedback ? 
          <Button type="primary" onClick={() => { props.history.push('/student/evaluate/' + record.conversation._id) }}>See Feedback</Button> :
          !record.conversation.is_complete ?
          <Button type="primary" onClick={() => { props.history.push('/student/conversation/' + record.conversation._id) }}>Resume</Button> :
          <Button type="primary" onClick={() => { props.history.push('/student/evaluate/' + record.conversation._id) }}>See Transcript</Button>;
      },
    }
  ];

  const fetchCourseData = async () => {
    try {
      if (!courseData || courseData.length === 0) {
        setIsLoading(true);
      }
      const coursedata = await getCourseById(courseId);
      setCourse(coursedata);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchAnnouncements = async () => {
    try {
      if (announcements.length === 0 || !announcements) {
        setIsLoading(true);
      }
      const announcementData = await getCourseAnnouncements(courseId);
      setAnnouncements(announcementData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchAssignments = async () => {
    try {
      if (assignments.length === 0 || !assignments) {
        setIsLoading(true);
      }
      const assignmentData = await getCourseAssignments(courseId);
      setAssignments(assignmentData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

    const fetchParticipants = async () => {
      try {
        if (participants.teachers.length ===0){
          setIsLoading(true)
        }
        const participantData = await getCourseParticipants(courseId);
        if (participantData["assosciate_teachers"] == undefined) {
          participantData["assosciate_teachers"] = [];
        }
        setParticipants({teachers: participantData["teachers"], assosciate_teachers:participantData["assosciate_teachers"], students:participantData["students"]});
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

  useEffect(() => {
    setCurrent(initialTab);
    if (current === 'general') {
      fetchCourseData();
      fetchAnnouncements();
    } else if (current === 'assignments') {
      fetchAssignments();
    } else if (current === 'participants') {
      fetchParticipants();
    }
  }, [current, courseId, location.search]);

  const handleClick = e => {
    setCurrent(e.key);
    history.push(`?tab=${e.key}`);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredStudents = participants.students.filter(student =>
    student.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderGeneral = () => {
    const locationState = location.state || {};
    const backgroundIndex = locationState.backgroundIndex || 0; // Default to 0 if not provided

    return (
      <>
        {isLoading ? 
          <div className='flex justify-center items-center w-full h-screen'>
            <ReactLoading type={"spin"} color={"purple"} height={30} width={30} />
          </div>
          :
          <div>
            <Card style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '20px', backgroundColor: "#EEECFF" }}>
              <Image
                width={910}
                height={220}
                src={backgrounds[backgroundIndex]}
                alt="Course background"
                style={{ objectFit: 'cover', borderRadius: '10px' }}
                preview={false}
              />
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
                <div>
                  <Typography.Title level={4}>Class Title: {courseData.title ? courseData.title.charAt(0).toUpperCase() + courseData.title.slice(1) : ''}</Typography.Title>
                  <p>Subject: {courseData.subject}</p>
                  <p>Grade: {courseData.grade}</p>
                  {showDescription && <p>Description: {courseData.description}</p>}
                </div>
                <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', scale: "1.2", marginRight: "30px", flexDirection: "column", marginTop: "40px" }}>
                  <div style={{ border: '1px solid #d9d9d9', borderRadius: '5px', padding: '5px 10px', display: 'flex', alignItems: 'center', backgroundColor: "white" }}>
                    <Typography.Text strong style={{ marginRight: '20px' }}>{courseData.course_code}</Typography.Text>
                    <CopyToClipboard text={courseData.course_code}>
                      <Tooltip title="Copy Class Code">
                        <Button shape="circle" icon={<CopyOutlined />} onClick={() => toast.info("Code copied")} />
                      </Tooltip>
                    </CopyToClipboard>
                  </div>
                  <Tooltip title="More Info">
                    <InfoCircleOutlined style={{ fontSize: '17px', cursor: 'pointer', marginTop: "10px" }} onClick={() => setShowDescription(!showDescription)} />
                  </Tooltip>
                </div>
              </div>
            </Card>
            <Card>
              {announcements.length > 0 && (
                <div style={{ marginTop: '20px' }}>
                  <Typography.Title level={4}>Recent Announcements</Typography.Title>
                  {announcements.map((announcement, index) => (
                    <Card key={index} style={{ height: "auto", whiteSpace: 'normal', overflowWrap: 'break-word', minWidth: '300px', maxWidth: '100%', margin: 0, border: '0.25px solid grey', marginBottom: '10px' }}>
                      <Typography.Title level={4} style={{ margin: 0 }}>
                        {announcement.title}
                      </Typography.Title>
                      <p>{announcement.description}</p>
                      <Typography.Text type="secondary" style={{ display: 'block', fontSize: '12px' }}>
                        Posted on {new Date(announcement.created_at).toLocaleDateString()} at {new Date(announcement.created_at).toLocaleTimeString()}
                      </Typography.Text>
                    </Card>
                  ))}
                </div>
              )}
            </Card>
          </div>
        }
      </>
    );
  };

  const renderParticipants = () => (
    <>
    {isLoading?
    <div className='flex justify-center items-center w-full h-screen'>
    <ReactLoading type={"spin"} color={"purple"} height={30} width={30} />
    </div>
    :
    <div>
      <Typography.Title level={4} style={{marginLeft:"15px"}}>Teachers</Typography.Title>
      <List
        style={{ marginLeft:"25px"}}
        dataSource={participants.teachers}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar icon={<UserOutlined />} />}
              title={item.name}
            />
          </List.Item>
        )}
      />
      <Divider />
      {participants.assosciate_teachers.length > 0 && (
        <>
          <Typography.Title level={4} style={{ marginLeft: "15px" }}>
            Assosciate Teachers
          </Typography.Title>
          <List
            style={{ marginLeft: "25px" }}
            dataSource={participants.assosciate_teachers}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<Avatar icon={<UserOutlined />} />}
                  title={item.name}
                />
              </List.Item>
            )}
          />
        </>
      )}
      <Divider />
      <Typography.Title level={4} style={{marginLeft:"15px"}}>Students</Typography.Title>
      <Input
        placeholder="Search students..."
        prefix={<SearchOutlined />}
        onChange={handleSearchChange}
        value={searchTerm}
        style={{ marginBottom: 20, marginLeft: 25, marginRight: 25, width:"60%" }}
      />
      <List
        style={{ marginLeft:"25px"}}
        dataSource={[...filteredStudents, ...participants.students.filter(student => !filteredStudents.includes(student))]}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar icon={<UserOutlined />} />}
              title={item.name}
            />
          </List.Item>
        )}
      />
    </div>
    }
    </>
  );
  

  const renderAssignments = () => (
    <div>
      <div className="bg-white w-full flex justify-between items-center" style={{ borderBottom: '1.5px solid #E2E8F0', fontFamily: 'Inter !important' }}>
        <div className="text-md m-4" style={{ fontWeight: 600 }}>
          Course Assignments
        </div>
      </div>
      <div className="w-full flex justify-center">
        {!isLoading ?
          <div className="flex flex-col gap-8 m-8 w-full">
            <div className="rounded-lg bg-white w-full" style={{ border: '1.5px solid #E2E8F0' }} >
              <Table style={{ fontSize: '20px !important' }}
                rowClassName={() => 'clickable-row'}
                columns={COLUMNS} dataSource={assignments} onChange={(pagination, filters, sorter, extra) => {
                  // update assignment ids
                  const assignment_ids = filters.assignment;
                  if (assignment_ids) {
                    const assignmentsString = assignment_ids.join(',');
                    queryParams.set('assignments', assignmentsString);
                    props.history.push({ search: queryParams.toString() });
                  } else {
                    queryParams.delete('assignments');
                    props.history.push({ search: queryParams.toString() });
                  }

                  // pagination
                  if (pagination?.current) {
                    queryParams.set('page', pagination?.current);
                    props.history.push({ search: queryParams.toString() });
                  }
                }}
                pagination={{ position: ['topRight'], current: getCurrentPage(), pageSize: 20, total: 0, showSizeChanger: false }}
              />
            </div>
          </div>
          :
          <div className="flex justify-center items-center w-full h-screen">
            <ReactLoading type={"spin"} color={"purple"} height={30} width={30} />
          </div>
        }
      </div>
    </div>
  );

  return (
    <div style={{ maxWidth: '1000px', margin: '0 auto', padding: '0 20px', maxHeight: "400px" }}>
      <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" style={{ lineHeight: '64px' }}>
        <Menu.Item key="general" style={{ fontSize: '16px' }}>General</Menu.Item>
        <Menu.Item key="assignments" style={{ fontSize: '16px' }}>Assignments</Menu.Item>
        <Menu.Item key="participants" style={{ fontSize: '16px' }}>Participants</Menu.Item>
      </Menu>
      {current === 'general' && renderGeneral()}
      {current === 'assignments' && renderAssignments()}
      {current === 'participants' && renderParticipants()}
    </div>
  );
};

export default Navbar;
