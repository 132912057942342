import React, { useEffect, useState, useContext } from 'react';
import { Flex, Select, Button } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { SherpaContext } from '../utils/sherpaContext';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { color } from 'framer-motion';

const AdminMain = ({ type, children }) => {
  const { userToken } = useContext(SherpaContext);
  const location = useLocation();
  const history = useHistory();
  const { courseId } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const [weeksAgo, setWeeksAgo] = useState(parseInt(urlParams.get('weeks_ago')) || 1);
  const [dropdownOptions, setDropdownOptions] = useState(courseId? [{ value: 'assignments', label: 'Assignments' }, { value: 'conversations', label: 'Conversations' }] : [{ value: 'classes', label: 'Classes' }, { value: 'teachers', label: 'Teachers' }, { value: 'participants', label: 'Participants' }]);
  const [userRole, setUserRole] = useState('');


  useEffect(() => {
    console.log("courseId is : ", courseId);
    if (courseId) {
      setDropdownOptions([{ value: 'assignments', label: 'Assignments' }, { value: 'conversations', label: 'Conversations' }, { value: 'participants', label: 'Participants' }]);
    }
    else {
      setDropdownOptions([{ value: 'classes', label: 'Classes' }, { value: 'teachers', label: 'Teachers' }]);
    }
  }, [courseId]);

  useEffect(() => {
    if (!weeksAgo) {
      urlParams.set('weeks_ago', '1');
      setWeeksAgo(1);
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    }

    // Update dropdown options based on route and role
    if (userToken.details.super_admin===true) {
      setUserRole('super_admin')
    }

    if (userRole === 'super_admin' && !courseId) {
      setDropdownOptions([{ value: 'classes', label: 'Classes' }, { value: 'teachers', label: 'Teachers' }, {value: 'bugreports', label: "Bug Reports"}, { value: 'admins', label: 'Admins' }]);
    }

  }, [weeksAgo, location.pathname, userRole]);

  return (
    <>      
      <div className="bg-white w-full flex justify-between items-center" style={{ borderBottom: '1.5px solid #E2E8F0', fontFamily: 'Inter !important' }}>
        <Button
        type="default"
        icon={<AiOutlineArrowLeft />}
        onClick={() => history.goBack()}
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          fontWeight: '500',
          margin: '1vw',
          alignSelf: 'center',
        }}
      >
        Back
      </Button>
        <div className="text-md m-4" style={{ fontWeight: 600 }}>{type.charAt(0).toUpperCase() + type.slice(1)}</div>
        <div>
          <Select
            className="w-48 mr-4"
            value={weeksAgo}
            onChange={(weeks_ago) => {
              setWeeksAgo(weeks_ago);
              urlParams.set('weeks_ago', weeks_ago.toString());
              window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
            }}
          >
            <Select.Option value={14}>All Time</Select.Option>
            <Select.Option value={1}>This Week</Select.Option>
            <Select.Option value={2}>1 Week Ago</Select.Option>
            <Select.Option value={3}>2 Weeks Ago</Select.Option>
            <Select.Option value={4}>3 Weeks Ago</Select.Option>
            <Select.Option value={5}>4 Weeks Ago</Select.Option>
            <Select.Option value={6}>5 Weeks Ago</Select.Option>
            <Select.Option value={7}>6 Weeks Ago</Select.Option>
            <Select.Option value={8}>7 Weeks Ago</Select.Option>
            <Select.Option value={9}>8 Weeks Ago</Select.Option>
            <Select.Option value={10}>9 Weeks Ago</Select.Option>
            <Select.Option value={11}>10 Weeks Ago</Select.Option>
            <Select.Option value={12}>11 Weeks Ago</Select.Option>
            <Select.Option value={13}>12 Weeks Ago</Select.Option>
          </Select>
          <Select
            className="w-48 mr-4"
            value={type}
            onChange={(id) => {
              history.push('/admin/' + id + (courseId ? '/' + courseId : ''));
            }}
          >
            {dropdownOptions.map(option => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div className="w-full flex flex-col justify-center">
        {React.cloneElement(children, { weeksAgo: weeksAgo, history: history })}
      </div>
    </>
  );
};

export default AdminMain;
