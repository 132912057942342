import React, { useContext, useState, useEffect } from 'react';
import { Card, Button, Modal, Input, Tooltip, Form, message, Select } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { SherpaContext } from '../utils/sherpaContext';
import { getCourses, createCourse } from '../api/course';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
// import './customStyles.css';

import bg1 from "../img/shortbg1.jpeg";
import bg2 from "../img/shortbg2.jpeg";
import bg3 from "../img/shortbg3.jpeg";
import bg4 from "../img/shortbg4.jpeg";
import bg5 from "../img/shortbg5.jpeg";
import bg6 from "../img/shortbg6.jpeg";

const backgrounds = [bg1, bg2, bg3, bg4, bg5, bg6];

const Classteacherdashboard = () => {
  const { userToken } = useContext(SherpaContext);
  const [classes, setClasses] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [subject, setSubject] = useState("");
  const [grade, setGradeLevel] = useState("");

  const fetchClasses = async () => {
    try {
      getCourses()
        .then((response) => {
          const coursesWithBackgrounds = response.map((course) => ({
            ...course,
            backgroundIndex: Math.floor(Math.random() * backgrounds.length), // Assign a random background index
          }));
          setClasses(coursesWithBackgrounds);
        })
        .catch((error) => {
          console.error("Error fetching courses:", error);
        });
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  useEffect(() => {
    fetchClasses();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const [form] = Form.useForm();

  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        handleOk(values, form); // Pass form values to handleOk
      })
      .catch((errorInfo) => {
        message.error("Please fill in all the required fields");
      });
  };

  const handleOk = async (course, form) => {
    try {
      await createCourse(course);
      toast.success("Course created successfully!");
      fetchClasses();
      form.resetFields(); // Reset form fields after successful submission
    } catch (error) {
      toast.error("Failed to create course. Please try again.");
      console.error(error);
    }

    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCopyCode = (code, event) => {
    navigator.clipboard.writeText(code);
    event.stopPropagation();
  };

  return (
    <div>
      <style>
        {`
          .custom-ant-card .ant-card-body {
            padding: 0px;
            border-radius: 0 0 8px 8px;
            width: 100%;
          }
        `}
      </style>
      <div
        className="bg-white w-full flex justify-between items-center h-16"
        style={{
          borderBottom: "1.5px solid #E2E8F0",
          fontFamily: "Inter !important",
        }}
      >
        <div className="text-md m-4" style={{ fontWeight: 600 }}>
          Teacher Dashboard
        </div>
      </div>
      <div className="w-full py-8 bg-[#f9f9f9] flex justify-center">
        <div className="w-[800px] flex flex-col">
          <div className="text-2xl font-semibold my-4">
            <span className="text-4xl mr-2" id="getting_started_title">
              🚀
            </span>
            {userToken?.details?.name?.split(" ")?.[0]
              ? "Hi " + userToken?.details?.name?.split(" ")?.[0] + "."
              : ""}
          </div>
          {classes.length === 0 ? (
            <p className="leading-relaxed" style={{ lineHeight: "0.1" }}>
              Welcome to Sherpa! You do not have any classes.
            </p>
          ) : (
            <p className="leading-relaxed" style={{ lineHeight: "0.1" }}>
              Welcome to Sherpa! Your classes are listed below:
            </p>
          )}
        </div>
      </div>
      <div className="w-full py-8 bg-[#f9f9f9] flex justify-center">
        <div className="w-[800px] flex flex-wrap justify-between">
          <div className="flex justify-between items-center my-4 w-full">
            <div className="text-2xl font-semibold">Your Classes</div>
            <Button
              onClick={showModal}
              className="text-white font-semibold cursor-pointer flex gap-1 items-center justify-center"
              size="large"
              type="primary"
              style={{ padding: "8px 20px" }}
            >
              Add Class
            </Button>
          </div>
          <div className='hello' style={{
  display: "grid",
  gridTemplateColumns: "repeat(3, 1fr)", /* Three equal columns */
  gap: "20px", /* Adjust gap between cards */
  alignItems: 'center',
  width: "100%"
}}>
          {classes.map((classItem, index) => (
            <Link
              key={classItem.id}
              to={{
                pathname: `/teacher/teachercourse/${classItem._id}`,
                state: { backgroundIndex: classItem.backgroundIndex }, // Pass backgroundIndex as state
              }}
              style={{ textDecoration: "none" }}
            >
              <Card
                key={index}
                className="custom-ant-card" // Add custom class name
                style={{
                  width: "240px", 
                  marginBottom: '16px', 
                  display: 'flex', 
                  flexWrap: "nowrap",
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  border: '1px solid #E2E8F0',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  height:'250px',
                
                }}

              >
                  <div style={{ width: '100%', height: '120px', backgroundImage: `url(${backgrounds[classItem.backgroundIndex]})`, backgroundSize: 'cover' }}></div>
                <div style={{ padding: '16px', width: '100%', backgroundColor: '#fff' }}>
                  <p style={{ fontWeight: 'bold', fontSize: '18px' ,position:'relative',top:'-20px'}}> {classItem.title.charAt(0).toUpperCase() + classItem.title.slice(1)}</p>
                  {/* <p>{classItem.description}</p> */}
                  <p style={{position:'relative',top:'-40px'}}>Grade : {classItem.grade}</p>
                  <p style={{position:'relative',top:'-40px'}}>{`Start Date: ${new Date(classItem.created_at).toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}`}</p>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                   
                  </div>
                </div>
              </Card>
            </Link>
          
          ))}
          </div>
        </div>
      </div>

      <Modal
        title="Create New Class"
        open={isModalVisible}
        onOk={handleFormSubmit}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="title"
            rules={[{ required: true, message: "Class title is required" }]}
          >
            <Input placeholder="Enter class title" />
          </Form.Item>
          <Form.Item
            name="description"
            rules={[
              { required: true, message: "Class description is required" },
            ]}
          >
            <Input placeholder="Enter class description" />
          </Form.Item>
          <Form.Item
            name="subject"
            rules={[{ required: true, message: "Subject is required" }]}
          >
            <Select placeholder="Select subject">
              <Select.Option value="English">English</Select.Option>
              <Select.Option value="History">History</Select.Option>
              <Select.Option value="Computer Science">Computer Science</Select.Option>
              <Select.Option value="Math">Math</Select.Option>
              <Select.Option value="Science">Science</Select.Option>
              <Select.Option value="Geography">Geography</Select.Option>
              <Select.Option value="Language">Language</Select.Option>
              <Select.Option value="Social Science">Social Science</Select.Option>
              <Select.Option value="Other">Other</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="grade"
            rules={[{ required: true, message: "Grade level is required" }]}
          >
            <Select placeholder="Select grade">
                                <Select.Option value={1}>1st grade</Select.Option>
                                <Select.Option value={2}>2nd grade</Select.Option>
                                <Select.Option value={3}>3rd grade</Select.Option>
                                <Select.Option value={4}>4th grade</Select.Option>
                                <Select.Option value={5}>5th grade</Select.Option>
                                <Select.Option value={6}>6th grade</Select.Option>
                                <Select.Option value={7}>7th Grade</Select.Option>
                                <Select.Option value={8}>8th Grade</Select.Option>
                                <Select.Option value={9}>9th Grade</Select.Option>
                                <Select.Option value={10}>10th Grade</Select.Option>
                                <Select.Option value={11}>11th Grade</Select.Option>
                                <Select.Option value={12}>12th Grade</Select.Option>
                                <Select.Option value={13}>College</Select.Option>
                              </Select>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Classteacherdashboard;
