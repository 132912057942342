import React, { useContext, useEffect, useState } from 'react';
import { Form, Input, Button, Card, Select, Slider } from 'antd';
import { SherpaContext } from '../utils/sherpaContext';
import { toast } from 'react-toastify';
import { submitSurvey, getConversationCount } from '../api/survey';

const TeacherForm = () => {
	return (
		<>
			<Form.Item
					name="student_learning"
					label="Using Sherpa, what have you learned about your students?"
					rules={[
						{
								required: true,
								message: "Please enter at least 10 words.",
								min: 10,
						},
				]}
			>
					<Input.TextArea rows={4}/>
			</Form.Item>
			<Form.Item
					name="question_rating"
					label="What do you think about the questions that the Sherpa AI asked? Please explain."
					rules={[
						{
								required: true,
								message: "Please enter at least 10 words.",
								min: 10,
						},
				]}
			>
					<Input.TextArea rows={4}/>
			</Form.Item>

			<Form.Item
					name="use_cases"
					label="How are you using Sherpa in your classroom?"
					rules={[
						{
								required: true,
								message: "Please enter at least 10 words.",
								min: 10,
						},
				]}
			>
					<Input.TextArea rows={4}/>
			</Form.Item>

			<Form.Item
					label={<span style={{ fontSize: '1.2em' }}>On a scale of 1-10, how likely is it that you would recommend Sherpa to another teacher?</span>}
					name="recommend"
					rules={[
							{
									required: true,
									message: "You cannot leave this field empty!",
							},
					]}
			>
					<Slider 
							min={1} 
							max={10} 
							marks={{ 
									1: 'Not likely', 
									10: 'Very likely' 
							}}
					/>
			</Form.Item>
	</>
	)
}

const Survey = (props) => {
    const [surveyForm] = Form.useForm();
    const { updateIntroStatus, userToken } = useContext(SherpaContext)
    const [convoCount, setConvoCount] = useState(null)

    useEffect(()=>{
        const getCount = async () => {
            const count = await getConversationCount()
            setConvoCount(count.count)
        }
        getCount()
    }, [])

    const onFinish = async (values) => {
        try {
            await updateIntroStatus("completed_survey")
            await submitSurvey(values)
            
            if (userToken.details.role === "student"){
                props.history.push('/student')
            } else {
                props.history.push('/teacher/gettingstarted')
            }
            
            
        } catch (error) {
            // Handle error
            toast.error(error.message);
        }
    };


    return convoCount && (
        <div className="flex w-full h-screen justify-center items-center py-16">
            <Card className="w-[800px] my-64">
                <div className="text-3xl font-bold text-center my-16">We Would Love Your Feedback! ❤️ </div>
                <p className="text-lg">Thanks so much for continuing to use Sherpa! We are trying our best to improve Sherpa, and we'd love to hear from you. This should take you no longer than <b>5 minutes</b>.<br/><br/>
                All data collected will be used purely to improve the Sherpa application, and will be kept <b>strictly private</b>, so please be as <b>honest as possible</b>.</p>
                <br/>
                <Form
                    form={surveyForm}
                    layout="vertical"
                    className="w-full"
                    onFinish={onFinish}
                >
                    {userToken.details.role === "student" ? 
                    <>
                    
                    {convoCount <= 3 ? 
                    <Form.Item
                        label={<span style={{ fontSize: '1.2em' }}>How do you feel when you engage in a conversation with the Sherpa AI? Please explain.</span>}
                        name="feel"
                        rules={[
                            {
                                required: true,
                                message: "Please enter at least 10 words.",
                                min: 10,
                            },
                        ]}
                    >
                        <Input.TextArea rows={4} placeholder="I feel nervous/excited/awkward/anxious because ..." />
                    </Form.Item>
                    : 
                    <Form.Item
                        label={<span style={{ fontSize: '1.2em' }}>How has your perception and feeling towards the Sherpa AI changed over time? Please explain.</span>}
                        name="feel_over_time"
                        rules={[
                            {
                                required: true,
                                message: "Please enter at least 10 words.",
                                min: 10,
                            },
                        ]}  
                    >
                       <Input.TextArea rows={4} />
                    </Form.Item>
                    }
                    <Form.Item
                        label={<span style={{ fontSize: '1.2em' }}>After talking to the Sherpa AI, what do you think you learned in the conversation? Please explain.</span>}
                        name="learning"
                        rules={[
                            {
                                required: true,
                                message: "Please enter at least 10 words.",
                                min: 10,
                            },
                        ]}  
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>
                    <Form.Item
                        label={<span style={{ fontSize: '1.2em' }}>Which feeling is the most representative of how you feel towards the Sherpa AI?</span>}
                        name="feeling_option"
                        rules={[
                            {
                                required: true,
                                message: "Please select something!",
                            },
                        ]}
                    >
                        <Select placeholder="Select your feeling">
                            <Select.Option value="like">I really like using Sherpa!</Select.Option>
                            <Select.Option value="excited">I feel excited to talk to Sherpa.</Select.Option>
                            <Select.Option value="positive">I feel positive talking to Sherpa.</Select.Option>
                            <Select.Option value="neutral">I feel neutral / indifferent talking to Sherpa — I don’t really have an opinion.</Select.Option>
                            <Select.Option value="nervous">I feel nervous / stressed out when I talk to Sherpa.</Select.Option>
                            <Select.Option value="uncomfortable">I feel uncomfortable talking to Sherpa.</Select.Option>
                            <Select.Option value="dislike">I really do not like using Sherpa.</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label={<span style={{ fontSize: '1.2em' }}>How likely is it that you would recommend another teacher to use Sherpa in their classroom?</span>}
                        name="recommend"
                        rules={[
                            {
                                required: true,
                                message: "You cannot leave this field empty!",
                            },
                        ]}
                    >
                    <Slider 
                            min={1} 
                            max={10} 
                            marks={{ 
                                    1: 'Not likely', 
                                    10: 'Very likely' 
                            }}
                    />
                    </Form.Item>
                    <div className="w-full justify-end flex gap-4">
                        <Button onClick={async ()=>{
                            await updateIntroStatus("completed_survey")
                            props.history.push('/student')
                        }}>Skip</Button>
                        <Button htmlType='submit' type="primary" style={{ background: '#9C1FFF' }}>Submit!</Button>
                    </div>
                    </>
                    :
                    <>
                    <TeacherForm />
                    <div className="w-full justify-end flex gap-4">
                        <Button onClick={async ()=>{
                            await updateIntroStatus("completed_survey")
                            props.history.push('/student')
                        }}>Skip</Button>
                        <Button htmlType='submit' type="primary" style={{ background: '#9C1FFF' }}>Submit!</Button>
                    </div>
                    </>
                    }
                </Form>
            </Card>
        </div>
    );
};

export default Survey;

