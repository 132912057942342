import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Select, Button, InputNumber, Tooltip } from "antd";
import { updateAssignment as apiupdateAssignment } from "../api/assignment";
import { InfoCircleOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

const EditAssignmentModal = ({isOpen, setIsOpen, initialValues = {}, assignment_id, updateAssignment }) => {
  const [mainForm] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeLimit, setTimeLimit] = useState(initialValues.time_limit);
  const handleTimeLimitChange = value => {
    setTimeLimit(value);
    mainForm.setFieldsValue({ time_limit: value });
  };
  const handleCancel = () => {
    setIsOpen(false);
  };
  return (
    <Modal
      title="Edit Assignment"
      open={isOpen}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        form={mainForm}
        onFinish={async (values) => {
          setIsSubmitting(true);
          const conversation_flow = initialValues.conversation_flow;
          conversation_flow.forEach((item, index) => {
            if (item.type === 'base') {
              const conceptIndex = item.level - 1;
              item.concept = values.concepts[conceptIndex] || null;
            }
          });
          const request_body = {
            ...values,
            conversation_flow
          };
          if (!values.time_limit) {
            request_body.time_limit_seconds = null;
          }
          updateAssignment(request_body);
          const updatedAssignment = await apiupdateAssignment(assignment_id, request_body);
          if (updatedAssignment) {
            toast.success('Successfully updated');
          }
          setIsSubmitting(false);
          setIsOpen(false);
        }}
        layout="vertical"
        initialValues={{
          ...initialValues,
          concepts: initialValues.concepts || ['']
        }}
      >
        <Form.Item
          label={
            <span>
              Title
            </span>
          }
          name="title"
          rules={[{ required: true, message: 'Please input the title!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={
            <span>
              Each student should have a minimum word count of...
            </span>
          }
          name="min_words"
          rules={[{ required: true, message: 'Please select the minimum words!' }]}
        >
          <Select>
            <Select.Option value={10}>10</Select.Option>
            <Select.Option value={20}>20</Select.Option>
            <Select.Option value={30}>30</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={
            <span>
              Do you want students to have limited time before they start talking?
            </span>
          }
          name="time_limit"
          rules={[{ required: true, message: 'Please select the time limit!' }]}
        >
          <Select onChange={handleTimeLimitChange}>
            <Select.Option value={true}>Yes</Select.Option>
            <Select.Option value={false}>No</Select.Option>
          </Select>
        </Form.Item>
        {timeLimit && (
          <Form.Item
            label={
              <span>
                Students have a waiting time of...
              </span>
            }
            name="time_limit_seconds"
            rules={[{ required: true, message: 'Please select the time limit seconds!' }]}
          >
            <Select>
              <Select.Option value={5}>5</Select.Option>
              <Select.Option value={10}>10</Select.Option>
              <Select.Option value={20}>20</Select.Option>
              <Select.Option value={60}>60</Select.Option>
              <Select.Option value={120}>120</Select.Option>
            </Select>
          </Form.Item>
        )}
        <Form.Item
          label={
            <span>
              How do you want students to speak on it?
            </span>
          }
          name="recording_format"
          rules={[{ required: true, message: 'Please select the recording format!' }]}
        >
          <Select>
            <Select.Option value="students_can_choose">Students have the option to turn on their camera.</Select.Option>
            <Select.Option value="with_video">Students must turn on their camera.</Select.Option>
            <Select.Option value="only_audio">Students do not have the option to turn on their camera.</Select.Option>
          </Select>
        </Form.Item>
        {initialValues.concepts && initialValues.concepts.map((concept, index) => (
          <Form.Item
            key={index}
            label={
              <span>
                Concept {index + 1}&nbsp;
                <Tooltip title={`${index === 0 ? "First" : index === 1 ? "Second" : "Third"} concept that AI will ask about. If left empty, the AI will choose a concept.`}>
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            }
            name={['concepts', index]}
          >
            <Input />
          </Form.Item>
        ))}
        <Form.Item>
          <div className="flex w-full justify-end">
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              Submit
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default EditAssignmentModal;
