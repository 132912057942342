import React, { useEffect, useState } from 'react';
import ReactLoading from "react-loading";
import { Button, Tag, Table, Statistic } from 'antd';
import { getConversations } from '../api/adminNew';
import { convertUtcToNow, convertTimeToTimeAgo } from '../utils/general';
import { useParams, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const ConversationDashboard = (props) => {
  const { courseId } = useParams();
  const location = useLocation();
  const [course, setCourse] = useState({});
  // const { title, subject, grade } = location.state || {};

  const COLUMNS = [
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => {
        created_at = convertUtcToNow(created_at);
        return convertTimeToTimeAgo(created_at);
      }
    },
    {
      title: 'Is Complete',
      dataIndex: 'is_complete',
      key: 'is_complete',
      render: (is_complete) => {
        return is_complete ? <Tag color="green">Yes!</Tag> : <Tag color="red">No :(</Tag>;
      }
    },
    {
      title: 'With Video',
      dataIndex: 'with_video',
      key: 'with_video',
      render: (with_video) => {
        return with_video ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>;
      }
    },
    {
      title: 'Assignment',
      dataIndex: 'assignment_id',
      key: 'assignment_id',
      render: (assignment_id) => {
        return <Button type="dashed" onClick={() => {
          props.history.push('/teacher/assignment/' + assignment_id);
        }}>View Assignment</Button>;
      }
    },
    {
      title: 'View Conversation',
      dataIndex: '_id',
      key: '_id',
      render: (id) => {
        return <Button type="primary" onClick={() => {
          props.history.push('/teacher/conversation/' + id);
        }}>View Conversation</Button>;
      }
    },
  ];

  const filterConversations = (conversations, weeksAgo) => {
    if (weeksAgo === 14) return conversations; // All time option

    const now = new Date();
    return conversations.filter(conversation => {
      const createdAt = new Date(conversation.created_at);
      const daysDifference = Math.floor((now - createdAt) / (1000 * 60 * 60 * 24));
      const weeksDifference = Math.floor(daysDifference / 7);
      return weeksDifference + 1  === weeksAgo;
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [conversations, setConversations] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getConversations(courseId)
      .then(response => {
        if (response.status === 200) {
          setConversations(response.data.conversations);
          setCourse(response.data.course);
          setIsLoading(false);
        } else {
          console.error('Error fetching conversations:', response);
          setIsLoading(false);
        }
      }).catch(error => {
        console.error('Error fetching conversations:', error);
        setIsLoading(false);
      });
  }, [courseId]);

  const filteredConversations = filterConversations(conversations, props.weeksAgo);

  return (
    <>
      <div className="w-full flex flex-col justify-center">
        {course && (
          <div className="m-8 flex flex-col gap-8">
            <div className="rounded-lg bg-white w-full" style={{ border: '1.5px solid #E2E8F0' }}>
              <div className="p-8 flex justify-between items-center">
                <Statistic title="Course Title" value={course.title} />
                <Statistic title="Subject" value={course.subject} />
                <Statistic title="Grade" value={course.grade} />
              </div>
            </div>
          </div>
        )}
        {!isLoading && conversations ? (
          <div className="m-8 flex flex-col gap-8">
            <div className="rounded-lg bg-white w-full" style={{ border: '1.5px solid #E2E8F0' }}>
              <div className="p-8 flex justify-between items-center">
                <Statistic title="% Conversations Complete" value={Math.round((conversations.filter(convo => convo.is_complete === true).length / conversations.length) * 100).toString() + "%"} />
              </div>
            </div>
            <div className="rounded-lg bg-white w-full" style={{ border: '1.5px solid #E2E8F0' }}>
              <Table columns={COLUMNS} dataSource={filteredConversations} />
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center w-full h-screen">
            <ReactLoading type={"spin"} color={"purple"} height={30} width={30} />
          </div>
        )}
      </div>
    </>
  );
}

export default ConversationDashboard;
