import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, Typography, Spin, message } from 'antd';
import { AiOutlineArrowRight } from "react-icons/ai";
import { getUser, updateUser } from '../api/user';
import { reload } from 'firebase/auth';

const { Title } = Typography;

const Profile = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});
  const [editing, setEditing] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    getUser()
      .then(data => {
        setUser(data);
        form.setFieldsValue(data); // Set form fields with user data
        setLoading(false);
      })
      .catch(error => {
        message.error('Failed to load profile');
      });
  }, [form]);

  const onFinish = (values) => {
    updateUser(values)
      .then(data => {
        setUser(data);
        setEditing(false);
      })
      .catch(error => {
        message.error('Failed to update profile');
      });
  };

  if (loading) {
    return <Spin tip="Loading..." />;
  }

  return (
    <div className="flex items-center justify-center w-full h-screen gap-8">
      <div className="w-4/6 pl-36 flex flex-col gap-6">
        {/* <Title>Profile</Title> */}
        <Card>
          {editing ? (
            <Form
              layout="vertical"
              initialValues={user}
              onFinish={onFinish}
            >
              <Form.Item
                label="Name"
                name="name"
                rules={[{ required: true, message: 'Please input your name!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please input your email!' }]}
              >
                <Input />
              {/* </Form.Item>
              <Form.Item
                label="Grade"
                name="grade"
                rules={[{ required: true, message: 'Please input your age!' }]}
              >
                <Input /> */}
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Save
                </Button>
                <Button
                  style={{ marginLeft: '10px' }}
                  onClick={() => setEditing(false)}
                >
                  Cancel
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <div>
              <p><strong>Name:</strong> {user.name}</p>
              <p><strong>Email:</strong> {user.email}</p>
              {/* <p><strong>Grade:</strong> {user.grade}</p> */}
              <p><strong>Role:</strong> {user.role}</p>
              <p><strong>Status:</strong> {user.status}</p>
              <Button type="primary" onClick={() => setEditing(true)}>
                Edit Profile
              </Button>
            </div>
          )}
        </Card>
      </div>
      
    </div>
  );
}

export default Profile;
