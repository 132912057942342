import React from "react";
import { Button } from "antd";
import { motion } from "framer-motion";
import { markNotification } from "../api/course";
import { useHistory } from "react-router-dom";
import { useContext, useState } from "react";
import { SherpaContext } from "../utils/sherpaContext";
import { toast } from "react-toastify";

const NotificationsComponent = ({notifications}) => {
    const { userToken } = useContext(SherpaContext);
    const [Notifications, setNotifications] = useState(notifications);

    const history = useHistory();

    const permissionsMapping = {
        "admin_access": "Full administrative access to the course.",
        "read_access": "Permission to read course content",
        "view_submissions": "Permission to view student submissions.",
        "add_assignments": "Permission to add new assignments.",
    };

    const handleNotificationSub = (Notification, status) => {
          let request_body = { "notification_id": Notification._id, "status": status };
        
          markNotification(request_body).then(res => {
           // Redirect to course
            if (res.status === 200) {
                if(status === "approve"){
                    toast.success("You have successfully joined the course!");
                    const courseID = Notification.course_id;
                    // redirect to course 
                    window.location.href = `teachercourse/${courseID}`;
                }
                else if(status === "reject"){
                    toast.success("You have successfully rejected the course invitation!");
                }
                //updating state to re-render the notifications
                setNotifications(Notifications.filter(n => n._id !== Notification._id));
                                
            }
          }).catch(err => { console.log(err) });
    };

    return (
        <div className="notifications" style={{ width: "25%", position: "fixed", right: 5, top: 20, zIndex: 100 }}>
        {Notifications.map((Notification, index) => {
            return (
                <motion.div
                    key={index}
                    initial={{ x: '100%' }}
                    animate={{ x: 0 }}
                    transition={{ type: 'spring', stiffness: 120, delay: index * 0.1 }}
                    style={{
                        backgroundColor: "white",
                        borderRadius: 10,
                        border: "1px solid purple",
                        marginBottom: 10,
                        padding: 15,
                        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)"
                    }}
                >
                    <p style={{margin:0}}>You have been invited to <strong>{Notification.course_name}</strong> with the following permissions:</p>
                    <ul style={{margin:0}}>
                        {Notification.permissions.map((permission, pIndex) => (
                            <li key={pIndex} style={{ marginLeft: "-1vw" }}>{permissionsMapping[permission]}</li>
                        ))}
                    </ul>
                    <div style={{ display: "flex", justifyContent: "space-around", marginTop: 10 }}>
                        <Button type="dashed" style={{ color: 'green', borderColor: "green" }} onClick={() => handleNotificationSub(Notification, "approve")}>Accept</Button>
                        <Button type="dashed" style={{ color: "red", borderColor: "red" }} onClick={() => handleNotificationSub(Notification, "reject")}>Decline</Button>
                    </div>
                </motion.div>
            );
        })}
    </div>
    );
};

export default NotificationsComponent;