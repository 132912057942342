import SherpaClient from "./main";

// Function to build query parameters
const buildQueryParams = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + encodeURIComponent(params[key]))
    .join("&");
};

// Get Conversation
export const getConversation = async (conversationId, params = null) => {
  const response = await SherpaClient.get(
    "/conversations/" +
      conversationId +
      (params ? "?" + buildQueryParams(params) : "") // add query params
  );
  return {
    ...response.data,
    id: conversationId,
  };
};

export const improveTranscript = async (conversationId) => {
  const response = await SherpaClient.post(
    "/evaluate/improve_transcript?conversation_id=" + conversationId
  );
  return response;
};

export const improveVideo = async (videoid) => {
  const response = await SherpaClient.post(
    "/evaluate/improve_video?video_id=" + videoid
  );
  return response;
};

// Get All Conversations
export const getAllConversations = async (
  assignments,
  page,
  all = false,
  include_main_user_conversations = false,
  do_not_only_include_user_assignments = false,
  only_include_main_user_conversations = false,
  sort_by_is_complete = false
) => {
  const response = await SherpaClient.post("/conversations", {
    assignments: assignments,
    page: page,
    all: all,
    include_main_user_conversations: include_main_user_conversations,
    only_include_main_user_conversations: only_include_main_user_conversations,
    do_not_only_include_user_assignments: do_not_only_include_user_assignments,
    sort_by_is_complete: sort_by_is_complete,
  });
  return response.data;
};

// Create a New Conversation
export const createConversation = async (
  assignment_id,
  student_id,
  student_work,
  with_video
) => {
  const data = {
    assignment_id: assignment_id,
    student_id: student_id,
    student_work: student_work,
    is_complete: false,
    up_to: 0,
    with_video: with_video,
    completed_time: null,
  };
  const response = await SherpaClient.put("/conversations", data);
  return response.data;
};

// Update Conversation
export const updateConversation = async (conversation_id, updated_object) => {
  const response = await SherpaClient.put(
    "/conversations/" + conversation_id,
    updated_object
  );
  return response.data;
};
