import React, { useContext, useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SherpaContext } from '../utils/sherpaContext';
import { joinCourse } from '../api/course';
import Logo from '../img/logo_black_text.svg';
import { RaceBy } from '@uiball/loaders'


const JoinCoursePage = (props) => {
    const { courseCode } = useParams();
    const history = useHistory();
    const { userToken } = useContext(SherpaContext);

    const joinCoursefunction = async () => {
        try {
          const response = await joinCourse(courseCode);
          const course_id = response.course_id;
            if (response) {
                props.history.push(`/studentcourse/${course_id}`);
                toast.success(response.message);
          }
        } catch (error) {
          console.error('Error joining course:', error);
        }
      };
    useEffect(() => {  
        joinCoursefunction();
    }, [courseCode, history]);

    return (
        <div className="flex items-center justify-center w-full h-screen flex-col gap-8">
        <img src={Logo} className="w-32" />
        <RaceBy 
            size={120}
            lineWeight={5}
            speed={1.4} 
            color="#a68bfa" 
        />
        </div>
    );
};

export default JoinCoursePage;