import React, { useContext, useState, useEffect } from "react";
import {
  Table,
  Modal,
  Menu,
  List,
  Typography,
  Avatar,
  Button,
  Divider,
  Card,
  Image,
  Tooltip,
  Input,
  Row,
  Col,
  Select, Dropdown, Popover,
} from "antd";
import {
  UserOutlined,
  CopyOutlined,
  SendOutlined,
  SearchOutlined,
  PlusOutlined, InfoCircleOutlined, EllipsisOutlined,
  ReloadOutlined, RedoOutlined
} from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  getCourseById,
  getCourseAssignments,
  getCourseParticipants,
  getCourseAnnouncements,
  createCourseAnnouncement,
  getCourseSubmissions,
  getCourses,
  addAssignmentToCourse,
  editCourse,
  deleteCourse,
  resetJoiningCode
} from "../api/course";
import { toast } from "react-toastify";
import { convertTimeToTimeAgo, convertUtcToNow } from "../utils/general";
import { deletecourseAssignment } from "../api/assignment";
import AddTeacher from "../components/AddTeacher";
import ReactLoading from "react-loading";
import { AiOutlinePlus } from "react-icons/ai";
import { SherpaContext } from "../utils/sherpaContext";
// import Image from 'next/image';

import bg1 from '../img/shortbg1.jpeg';
import bg2 from '../img/shortbg2.jpeg';
import bg3 from '../img/shortbg3.jpeg';
import bg4 from '../img/shortbg4.jpeg';
import bg5 from '../img/shortbg5.jpeg';
import bg6 from '../img/shortbg6.jpeg';
import AccessDenied from "../general/AccessDenied";

const backgrounds = [bg1, bg2, bg3, bg4, bg5, bg6];

const { TextArea } = Input;
const { Option } = Select;

const courseInfo = {
  imageUrl: "/path_to_your_image.jpg"  // Replace with your actual image path
};

const Navbar = (props) => {
  const { courseId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const initialTab = searchParams.get("tab") || "general";

  const [current, setCurrent] = useState("general");
  const [announcements, setAnnouncements] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const [courseData, setCourse] = useState({});  // Added state for course data
  const [assignments, setAssignments] = useState([]);  // Added state for assignments
  const [participants, setParticipants] = useState({ teachers: [], assosciate_teachers:[], students: [] });  // Added state for participants
  const { userToken } = useContext(SherpaContext);
  const [selectedAssignment, setSelectedAssignment] = useState(JSON.parse(localStorage.getItem(`selectedAssignment-${courseId}`)) || null);  
  const [conversations, setConversations] = useState([]);// State for submissions
  const [isloading, setIsLoading] = useState(true);
  const [isAddTeacherOpen, setIsAddTeacherOpen] = useState(false); // Popup to add new teacher
  const [isAddtoCourseOpen, setIsAddtoCourseOpen] = useState(false); // Popup to add assigment to another course
  const [courses, setCourses] = useState([]); // List of courses to add assignment to
  const [assignmenttoCopy, setAssignmenttoCopy] = useState({}); // Assignment to copy to another course
  const [showDescription, setShowDescription] = useState(false);
  const [isEditCourseOpen, setIsEditCourseOpen] = useState(false);
  const [permissions, setPermissions] = useState([]);


  const COLUMNS = [
    {
      title: "Title",
      width: "80%",
      dataIndex: "title",
      key: "title",
      render: (title) => {
        if (title?.length > 100) {
          return title.substring(0, 100) + "...";
        } else {
          return title;
        }
      },
    },
    {
      title: "Created",
      width: "10%",
      dataIndex: "created_at",
      key: "created_at",
      render: (timestamp) => {
        const now = convertUtcToNow(timestamp);
        return convertTimeToTimeAgo(now);
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (_, record) => (
        <div className="flex gap-2">
          <Button 
          type="primary"
          onClick={()=>{
            props.history.push('/teacher/assignment/' + record._id)          
          }}>
            View
          </Button>
          {/* <Button type="primary" style={{ background: '#9C1FFF'}} onClick={()=>{
            navigator.clipboard.writeText(window.location.origin + "/student/assignment/" + record._id)      
            toast.success("Copied Share Link to Clipboard!")
          }}>
            Share
          </Button> */}
          <Button className="w-full flex text-center justify-center" danger onClick={()=>{
            Modal.confirm({
              title: 'Are you sure you want to delete this assignment? You will delete all associated conversations to this assignment.',
              onOk: async () => {
                await deletecourseAssignment(record._id, courseId)
                toast.success("Assignment deleted!")
                fetchAssignments()
              },
              onCancel() {
                // console.log('Cancel');
              },
            });
          }}>Delete</Button>
          <Button
          
            onClick={()=>{handleAddtoCourseClick(record)}}
          >
            Add to other course
          </Button>
        </div>
      ),
    },
  ];

  const fetchcourseData = async () => {
    try {
      if (courseData.length===0 || !courseData || !announcements){
        setIsLoading(true);
      }
      const coursedata = await getCourseById(courseId);
      setCourse(coursedata);
      setPermissions(coursedata.permissions);
      console.log(coursedata.permissions)
      const announcementData = await getCourseAnnouncements(courseId);
      setAnnouncements(announcementData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchAssignments = async () => {
    try {
      if (assignments.length===0 || !assignments){
        setIsLoading(true);
      }
      const assignmentData = await getCourseAssignments(courseId);
      setAssignments(assignmentData.assignments);
      setPermissions(assignmentData.permissions);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchParticipants = async () => {
    try {
      if (participants.teachers.length===0){
        setIsLoading(true);
      }
      const participantData = await getCourseParticipants(courseId);
      if (participantData["assosciate_teachers"] == undefined) {
        participantData["assosciate_teachers"] = [];
      }
      setParticipants({teachers: participantData["teachers"], assosciate_teachers:participantData["assosciate_teachers"], students:participantData["students"]});
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchSubmissions = async () => {
    try {
      if (!assignments || assignments.length===0 || !conversations || conversations.length === 0){
        setIsLoading(true);
      }
      const assignmentData = await getCourseAssignments(courseId);
      setAssignments(assignmentData.assignments);
      setPermissions(assignmentData.permissions);
      const submissionData = await getCourseSubmissions(courseId);
      setConversations(submissionData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  // const fetchTeacherPermissions = async (teacherId) => {
  //   try {
  //     const response = await getTeacherPermissions(courseId, teacherId);
  //     setPermissions(response.permissions);
  //   } catch (error) {
  //     toast.error("Failed to fetch permissions. Please try again.");
  //     console.error("Error fetching permissions:", error);
  //   }
  // };

  useEffect(() => {
    if (current === 'general') {
      fetchcourseData();
    } else if (current === 'assignments') {
      fetchAssignments();
    } else if (current === 'participants') {
      fetchParticipants();
    } else if (current === 'submissions') {
      fetchSubmissions();
    }
  }, [current, courseId, location.search]);

  useEffect(() => {
    setCurrent(initialTab);
  }, [location.search]);

  useEffect(() => {
    localStorage.setItem(`selectedAssignment-${courseId}`, JSON.stringify(selectedAssignment));
  }, [selectedAssignment, courseId]);

  const handleClick = (e) => {
    if (e.key === 'edit-course') {
      handleEditCourseClick();
    } else {
      setCurrent(e.key);
      history.push(`?tab=${e.key}`);
    }
  };
  
  

  const handleEditCourseClick = () => {
    setIsEditCourseOpen(true);
  };
  

  const [isEditLoading, setIsEditLoading] = useState(false);

const handleEditCourse = async () => {
  setIsEditLoading(true); // Start loading
  try {
    await editCourse(courseId, courseData);
    await fetchcourseData(); // Fetch the updated course data
    toast.success("Course updated successfully!");
    setIsEditCourseOpen(false); // Close the modal
  } catch (error) {
    toast.error("Failed to update course. Please try again.");
    console.error("Error updating course:", error);
  } finally {
    setIsEditLoading(false); // Stop loading
  }
};


  const handleDeleteCourse = () => {
    deleteCourse(courseId).then((response) => {
      if (response.status === 200) {
        toast.success("Course deleted successfully!");
        history.push("/teacher/Classteacherdashboard");
      } else {
        toast.error("Failed to delete course. Please try again.");
      }
    }).catch((error) => {
      toast.error("An error occurred while deleting the course. Please try again.");
      console.error("Error deleting course:", error);
    });
  };

  const handleDeleteCourseFromModal = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this course? \n You will lose all associated data.',
      onOk: async () => {
        try {
          await deleteCourse(courseId);
          toast.success("Course deleted successfully!");
          history.push("/teacher/Classteacherdashboard");
        } catch (error) {
          toast.error("Failed to delete course. Please try again.");
          console.error("Error deleting course:", error);
        }
      },
      onCancel() {
        // console.log('Cancel');
      },
    });
  };
  

  const handleResetJoiningCode = () => {
    resetJoiningCode(courseId).then((response) => {
      if (response.status === 200) {
        fetchcourseData();
        toast.success("Joining code reset successfully!");
      } else {
        toast.error("Failed to reset joining code. Please try again.");
      }
    }).catch((error) => {
      toast.error("An error occurred while resetting the joining code. Please try again.");
      console.error("Error resetting joining code:", error);
    });
  };

  const handleAddtoCourseClick = (assignment) => {
    setAssignmenttoCopy(assignment)
    setIsAddtoCourseOpen(true);

    try {
      getCourses().then((response) => {
        // remove the course that the assignment is already in
        response = response.filter((course) => course._id !== courseId);
        setCourses(response);
      }).catch((error) => {
        console.error("Error fetching courses:", error);
      });
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  const handleCourseChange = async (value) => {
    try {
      await addAssignmentToCourse(courseId, assignmenttoCopy._id, {course_id: value})
      setIsAddtoCourseOpen(false);
      toast.success("Assignment added to course successfully!");
    } catch (error) {
      toast.error("Failed to add assignment to course. Please try again.");
    }
  };

  const handleAnnouncementSubmit = async () => {
    if (title.trim() && description.trim()) {
      const newEntry = {
        title,
        description,
      };

      try {
        await createCourseAnnouncement(courseId, newEntry);

        // Fetch updated announcements
        const updatedAnnouncements = await getCourseAnnouncements(courseId);
        // Update the state with the new list of announcements
        setAnnouncements(updatedAnnouncements);
        // Clear the input fields
        setTitle("");
        setDescription("");

        toast.success("Announcement created successfully!");
      } catch (error) {
        toast.error("Failed to create announcement. Please try again.");
        console.error("Error creating announcement:", error);
      }
    } else {
      toast.warn("Please fill in both the title and description.");
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAddStudentClick = () => {
    const baseUrl = window.location.origin;
    const courseUrl = `${baseUrl}/student/join-link/${courseData.course_code}`;
    // copy this url
    navigator.clipboard.writeText(courseUrl)
      .then(() => {
        toast.success("Invite link copied to clipboard!")
      })
      .catch(err => {
        console.error('Failed to copy link: ', err);
      });
  };

  const handleAddTeacherClick = () => {
    setIsAddTeacherOpen(true);
  };

  const filteredStudents = participants.students.filter((student) =>
    student.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleAssignmentChange = (value) => {
    setSelectedAssignment(value);
  };
  const flattenedConversations =
    selectedAssignment === null || selectedAssignment === undefined
      ? conversations.flatMap((conversation) => conversation.conversations)
      : conversations
          .filter(
            (conversation) => conversation.assignment_id === selectedAssignment,
          )
          .flatMap((conversation) => conversation.conversations);
  const columns = [
    {
      title: 'Student',
      dataIndex: 'student_name',
      key: 'student_name',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: 'Is Complete',
      dataIndex: 'is_complete',
      key: 'is_complete',
      render: (isComplete) => (isComplete ? 'Yes' : 'No'),
    },
    {
      title: 'Completed',
      dataIndex: 'completed_time',
      key: 'completed_time',
      render: (text) => (text ? new Date(text).toLocaleString() : 'N/A'),
    },
    {
      title: 'Feedback',
      dataIndex: '_id',
      key: 'feedback',
      render: (_id) => (
        <Button
          type="primary"
          icon={<SendOutlined />}
          onClick={() => {
            // redirect along with filtered conversations for that selected assignment
            props.history.push(`/teacher/conversation/${_id}`, { conversations: flattenedConversations });
          }}
        >
          Feedback
        </Button>
      ),
    }
  ];

  // const menu = (
  //   <Menu>
  //     <Menu.Item key="edit" onClick={() => toast.info('Edit option clicked')}>
  //       Edit
  //     </Menu.Item>
  //     <Menu.Item key="delete" onClick={() => {
  //       Modal.confirm({
  //         title: 'Are you sure you want to delete this course? \n You will lose all associated data.',
  //         onOk: handleDeleteCourse,
  //         onCancel() {
  //           console.log('Cancel');
  //         },
  //       });
      
  //     }}>
  //       Delete
  //     </Menu.Item>
  //     <Menu.Item key="reset" onClick={() => toast.info('Reset option clicked')}>
  //       Reset
  //     </Menu.Item>
  //   </Menu>
  // );

  const renderGeneral = () => {
    const locationState = location.state || {};
    const backgroundIndex = locationState.backgroundIndex || 0; // Default to 0 if not provided

    return (
    <>
    {isloading ? 
      <div className='flex justify-center items-center w-full h-screen'>
        <ReactLoading type={"spin"} color={"purple"} height={30} width={30} /> 
      </div>
      : 
        <div>
          <Card
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "20px",
            backgroundColor: "#EEECFF",
          }}
        >
            <Image
              width={900}
              height={220}
              src={backgrounds[backgroundIndex]}
              alt="Course background"
              style={{ objectFit: "cover", borderRadius: "10px" }}
              preview={false}
            />
            <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
              <div>
                <Typography.Title level={4}>
                Class Title: {courseData.title}
              </Typography.Title>
                <p>Subject: {courseData.subject}</p>
                <p>Grade: {courseData.grade}</p>
                {showDescription && <p>Description: {courseData.description}</p>}
              </div>
              <div style={{ display: 'flex',flexDirection:"column", alignItems: 'center', gap: '10px', marginTop: '40px' }}>
                <div
                style={{
                  border: "1px solid #d9d9d9",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "white",
                }}
              >
                  <Typography.Text strong style={{ marginRight: "20px" }}>
                  {courseData.course_code}
                </Typography.Text>
                  <CopyToClipboard text={courseData.course_code}>
                    <Tooltip title="Copy Class Code">
                      <Button
                      shape="circle"
                      icon={<CopyOutlined />}
                      onClick={() => toast.info("Code copied")}
                    />
                    </Tooltip>
                  </CopyToClipboard>
                  {permissions.includes("admin_access") &&
                    <Tooltip title="Reset Joining Code">
                      <Button
                      shape="circle"
                      onClick={handleResetJoiningCode}
                      style={{ marginLeft: "10px" }}
                      icon= {<RedoOutlined />}
                      >
                      </Button>
                    </Tooltip>
                  }
                </div>
                <Tooltip title="More Info">
                <InfoCircleOutlined style={{ fontSize: '20px', cursor: 'pointer' , marginTop:"12px", marginLeft:"10vw"}} onClick={() => setShowDescription(!showDescription)} />
              </Tooltip>
              {/* <Dropdown overlay={menu} trigger={['click']}>
                <EllipsisOutlined style={{ fontSize: '24px', cursor: 'pointer' }} />
              </Dropdown> */}
              </div>
            </div>
          </Card>
          <Card>
            {announcements.length > 0 && (
              <div style={{ marginTop: "20px" }}>
                <Typography.Title level={4}>
                Recent Announcements
              </Typography.Title>
                {announcements.map((announcement, index) => (
                  <Card
                  key={index}
                  style={{
                    height: "auto",
                    whiteSpace: "normal",
                    overflowWrap: "break-word",
                    minWidth: "300px",
                    maxWidth: "100%",
                    margin: 0,
                    border: "0.25px solid grey",
                    marginBottom: "10px",
                  }}
                >
                    <Typography.Title level={4} style={{ margin: 0 }}>
                      {announcement.title}
                    </Typography.Title>
                    <p>{announcement.description}</p>
                    <Typography.Text
                    type="secondary"
                    style={{ display: "block", fontSize: "12px" }}
                  >
                      Posted on{" "}
                    {new Date(announcement.created_at).toLocaleDateString()} at{" "}
                    {new Date(announcement.created_at).toLocaleTimeString()}
                    </Typography.Text>
                  </Card>
                ))}
              </div>
            )}
          </Card>
        </div>
    }
    </>
    );
  };

  const renderParticipants = () => (
    <>
    {isloading ?
      <div className='flex justify-center items-center w-full h-screen'>
        <ReactLoading type={"spin"} color={"purple"} height={30} width={30} />
      </div>
      :
      <div>
        <Row
          justify="space-between"
          align="middle"
          style={{
            marginBottom: 20,
            marginLeft: 25,
            marginRight: 25,
            width: "60%",
          }}
        >
          <Col>
            <Typography.Title level={4} style={{ marginLeft: "15px" }}>
              Teachers
            </Typography.Title>
          </Col>
          {permissions.includes("admin_access") &&
            <Col>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={handleAddTeacherClick}
              >
                Add Teacher
              </Button>
            </Col>
          }
        </Row>
        <List
          style={{ marginLeft: "25px" }}
          dataSource={participants.teachers}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar icon={<UserOutlined />} />}
                title={item.name}
              />
            </List.Item>
          )}
        />
        <Divider />
        {/* if (participants.assosciate_teachers.length > 0) { */}
        <>
          {participants.assosciate_teachers.length > 0 && (
            <>
              <Col>
                <Typography.Title level={4} style={{ marginLeft: "40px" }}>
                  Assosciate Teachers
                </Typography.Title>
              </Col>
              <List
                style={{ marginLeft: "25px" }}
                dataSource={participants.assosciate_teachers}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar icon={<UserOutlined />} />}
                      title={item.name}
                    />
                  </List.Item>
                )}
              />
            </>
          )}
        </>
        <Divider />
        <Row
          justify="space-between"
          align="middle"
          style={{
            marginBottom: 20,
            marginLeft: 25,
            marginRight: 25,
            width: "60%",
          }}
        >
          <Col>
            <Typography.Title level={4} style={{ marginLeft: "15px" }}>
              Students
            </Typography.Title>
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleAddStudentClick}
            >
              Add Students
            </Button>
          </Col>
        </Row>
        <Input
          placeholder="Search students..."
          prefix={<SearchOutlined />}
          onChange={handleSearchChange}
          value={searchTerm}
          style={{
            marginBottom: 20,
            marginLeft: 25,
            marginRight: 25,
            width: "60%",
          }}
        />
        <List
          style={{ marginLeft: "25px" }}
          dataSource={[
            ...filteredStudents,
            ...participants.students.filter(
              (student) => !filteredStudents.includes(student),
            ),
          ]}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar icon={<UserOutlined />} />}
                title={item.name}
              />
            </List.Item>
          )}
        />
      </div>
    }
    </>
  );

  const menuStyle = {
    fontSize: "16px",
  };

  const renderAssignments = () => (
    <>
    {isloading ?
      <div className='flex justify-center items-center w-full h-screen'>
        <ReactLoading type={"spin"} color={"purple"} height={30} width={30} />
      </div>
      :
      <div>
        <div
        className="bg-white w-full flex justify-between items-center"
        style={{
          borderBottom: "1.5px solid #E2E8F0",
          fontFamily: "Inter !important",
        }}
      >
          <div className="text-md m-4" style={{ fontWeight: 600 }}>
            Course Assignments
          </div>
          {(permissions.includes("add_assignments") || permissions.includes("admin_access")) &&
            <Button
            type="primary"
            style={{ background: "#9C1FFF" }}
            className="text-md m-4 flex flex-row items-center gap-2"
            onClick={() => {
                props.history.push("/teacher/create/" + courseId);
              }}
          >
              <AiOutlinePlus color="white" />
            <span>New Assignment</span>
            </Button>
          }
        </div>
        <div className="w-full flex justify-center">
          {assignments ? (
            <div
            className="rounded-lg bg-white w-full m-8"
            style={{ border: "1.5px solid #E2E8F0" }}
          >
              <Table columns={COLUMNS} dataSource={assignments} />
            </div>
          ) : (
            <div className="flex justify-center items-center w-full h-screen">
              <ReactLoading
              type={"spin"}
              color={"purple"}
              height={30}
              width={30}
            />
            </div>
          )}
        </div>
      </div>
    }
    <Modal title="Add Assignment to Course" open={isAddtoCourseOpen} onOk={handleAddtoCourseClick} onCancel={()=>{setIsAddtoCourseOpen(false)}}>
      <Select
        style={{ width: 200 }}
        placeholder="Select a course to add to"
        onChange={handleCourseChange}
      >
        {courses.map((course) => (
          <Option key={course._id} value={course._id}>
            {course.title}
          </Option>
        ))}
      </Select>
    </Modal>

    </>
  );

  const renderSubmissions = () => (
    <>
    {!isloading ?
      <>
      {(permissions.includes("admin_access") || permissions.includes("view_submissions"))?
        <>
        {isloading ?
          <div className='flex justify-center items-center w-full h-screen'>
            <ReactLoading type={"spin"} color={"purple"} height={30} width={30} />
          </div>
          :
          <div>
            <div className="bg-white w-full flex justify-between items-center" style={{ borderBottom: '1.5px solid #E2E8F0', fontFamily: 'Inter !important' }}>
              <div className="text-md m-4" style={{ fontWeight: 600 }}>
                Course Submissions
              </div>
            </div>
            <div className="w-full flex justify-center">
              <div className="rounded-lg bg-white w-full m-8" style={{ border: '1.5px solid #E2E8F0' }}>
                <div className="m-4">
                  <Select
                    style={{ width: 200 }}
                    placeholder="Select an assignment"
                    onChange={handleAssignmentChange}
                    value={selectedAssignment}
                  >
                    {assignments.map((assignment) => (
                      <Option key={assignment._id} value={assignment._id}>
                        {assignment.title}
                      </Option>
                    ))}
                  </Select>
                </div>
                <Table columns={columns} dataSource={flattenedConversations} rowKey={(record) => record._id} />
              </div>
            </div>
          </div>
        }
        </>
        :
        <AccessDenied/>
      }
      </>
    :
    <div className='flex justify-center items-center w-full h-screen'>
      <ReactLoading type={"spin"} color={"purple"} height={30} width={30} />
    </div>
    }
    </>
  );
  return (
    <div style={{  gap:"20vw" }}>
      <Menu
        onClick={handleClick}
        selectedKeys={[current]}
        mode="horizontal"
        style={{  lineHeight: "64px" }}         
      >
        <Menu.Item key="general" style={menuStyle}>
          General
        </Menu.Item>
        <Menu.Item key="assignments" style={menuStyle}>
          Assignments
        </Menu.Item>
        <Menu.Item key="participants" style={menuStyle}>
          <AddTeacher
            isOpen={isAddTeacherOpen}
            setIsOpen={setIsAddTeacherOpen}
            courseId={courseId}
          />
          Participants
        </Menu.Item>
        <Menu.Item key="submissions" style={menuStyle}>
          Submissions
        </Menu.Item>
        {current === "general" && permissions.includes("admin_access") &&
          <div style={{position:"absolute", right:"1vw"}}>
            <Button
              onClick={handleEditCourseClick}
              style={{ alignSelf: "center", marginRight: "16px" }}
            >
              Edit Course
            </Button>
          </div>
        }

      </Menu>
      {current === "general" && renderGeneral()}
      {current === "assignments" && renderAssignments()}
      {current === "participants" && renderParticipants()}
      {current === "submissions" && renderSubmissions()}

      <Modal
  title="Edit Course"
  open={isEditCourseOpen}
  onOk={handleEditCourse}
  onCancel={() => setIsEditCourseOpen(false)}
  footer={[
    <Button key="delete" danger onClick={handleDeleteCourseFromModal}>
      Delete Course
    </Button>,
    <Button key="cancel" onClick={() => setIsEditCourseOpen(false)}>
      Cancel
    </Button>,
    <Button key="submit" type="primary" onClick={handleEditCourse} disabled={isEditLoading}>
      {isEditLoading ? <ReactLoading type={"spin"} color={"white"} height={20} width={20} /> : "OK"}
    </Button>,
  ]}
>
  <Input
    placeholder="Title"
    value={courseData.title}
    onChange={(e) => setCourse({ ...courseData, title: e.target.value })}
    style={{ marginBottom: 10 }}
  />
  <Select
    placeholder="Select grade"
    value={courseData.grade}
    onChange={(value) => setCourse({ ...courseData, grade: value })}
    style={{ width: '100%', marginBottom: 10 }}
  >
    <Select.Option value={1}>1st grade</Select.Option>
    <Select.Option value={2}>2nd grade</Select.Option>
    <Select.Option value={3}>3rd grade</Select.Option>
    <Select.Option value={4}>4th grade</Select.Option>
    <Select.Option value={5}>5th grade</Select.Option>
    <Select.Option value={6}>6th grade</Select.Option>
    <Select.Option value={7}>7th Grade</Select.Option>
    <Select.Option value={8}>8th Grade</Select.Option>
    <Select.Option value={9}>9th Grade</Select.Option>
    <Select.Option value={10}>10th Grade</Select.Option>
    <Select.Option value={11}>11th Grade</Select.Option>
    <Select.Option value={12}>12th Grade</Select.Option>
    <Select.Option value={13}>College</Select.Option>
  </Select>
  <Select
    placeholder="Select subject"
    value={courseData.subject}
    onChange={(value) => setCourse({ ...courseData, subject: value })}
    style={{ width: '100%', marginBottom: 10 }}
  >
    <Select.Option value="English">English</Select.Option>
    <Select.Option value="History">History</Select.Option>
    <Select.Option value="Computer Science">Computer Science</Select.Option>
    <Select.Option value="Math">Math</Select.Option>
    <Select.Option value="Science">Science</Select.Option>
    <Select.Option value="Geography">Geography</Select.Option>
    <Select.Option value="Language">Language</Select.Option>
    <Select.Option value="Social Science">Social Science</Select.Option>
    <Select.Option value="Other">Other</Select.Option>
  </Select>
  <TextArea
    placeholder="Description"
    value={courseData.description}
    onChange={(e) => setCourse({ ...courseData, description: e.target.value })}
    style={{ marginBottom: 10 }}
  />
</Modal>




    </div>
    
  );
};

export default Navbar;
