import SherpaClient from "./main";

export const submitExperiment = async (eval_data) => {
  const response = await SherpaClient.post("/experiment", eval_data);
  return response.data;
};

export const getExperimentCount = async () => {
  const response = await SherpaClient.get("/experiment");
  return response.data;
};
