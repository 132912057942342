import SherpaClient from "./main";

// Get all admins
export const getAdmins = async () => {
  const response = await SherpaClient.get("/admin/admins");
  return response;
};

// add new admin
export const addAdmin = async (requestBody) => {
  const response = await SherpaClient.post("/admin/add", requestBody);
  return response;
};

// remove admin
export const removeAdmin = async (admin_id) => {
  const response = await SherpaClient.delete(`/admin/remove/${admin_id}`);
  return response;
};

// Get courses
export const getCourses = async () => {
  const response = await SherpaClient.get("/institute/courses");
  return response;
};

// Get Institute teachers
export const getTeachers = async () => {
  const response = await SherpaClient.get("/institute/teachers");
  return response;
};

//FOR A COURSE

// Get assignments
export const getAssignments = async (course_id) => {
  const response = await SherpaClient.get(`/institute/${course_id}/assignments`);
  return response;
};

// Get conversations
export const getConversations = async (course_id) => {
  const response = await SherpaClient.get(`/institute/${course_id}/conversations`);
  return response;
};

// Get Participants
export const getParticipants = async (course_id) => {
  const response = await SherpaClient.get(`/institute/${course_id}/participants`);
  return response.data;
};