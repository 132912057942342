
import React, { useContext, useEffect, useState, useRef } from 'react';

import { useParams, useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import ReactPlayer from 'react-player/lazy'
import { AiOutlineRight, AiOutlineLeft, AiOutlineArrowRight } from "react-icons/ai"
import { Button, Card, Collapse, Modal, Form, Input, Tooltip, Segmented, Tag, Switch, Select } from 'antd'
import ScrollFade from "@benestudioco/react-scrollfade";
import { IoMdVolumeHigh,IoMdPause } from 'react-icons/io'
import { getAllStudentConversationsForAssignment } from '../api/dashboard';
import { getConversation, improveTranscript, updateConversation } from '../api/conversation';
import { evaluateConversationAsync, updateEval } from '../api/evaluate';
import { toast } from "react-toastify";
import { Steps as IntroSteps } from 'intro.js-react';
import { SherpaContext } from '../utils/sherpaContext';
import BugReportModal from "../components/BugReportModal"
import { getRole } from '../utils/lti'
import { BsArrowRight } from 'react-icons/bs';
import InfoModal from '../components/InfoModal';
import { AiFillStar } from 'react-icons/ai';
import { IoIosWarning } from 'react-icons/io';
import { getPresignedUrl } from '../api/s3';
import { reportBug } from '../api/bugreports';
import { convertUtcToNow, convertMinutesToReadableTime } from '../utils/general';
import { Link } from "react-router-dom";


const INTRO_STEPS = [
{
    title: 'Welcome to the evaluation screen!',
    intro: "Congrats on finishing your first conversation! Here is where you can evaluate your student's responses",
    position: 'top'
  },
  {
    element: '#main_evaluate_component',
    title: "Video + Transcript",
    intro: "This is the main evaluation component. On the left, is the video of the student's response. On the right, is what they said."
  },
  {
    element: '#next_previous_buttons',
    title: "Next Question",
    intro: "Use these buttons to switch between different questions. Press the right button to go to the next question."
  },
  {
    element: "#ai_evaluation",
    title: "AI Evaluation",
    intro: "We use AI to surface 'warnings' to teachers - moments that we think may reveal a lack of understanding. If a response is unproblematic, Sherpa 'verifies' them.",
  },
  {
    element: "#evaluation_details",
    title: "Evaluation Details",
    intro: "We provide more details about this evaluation here as well. You can see how confident Sherpa was in its evaluation, what you can do as a teacher to help clarify the misunderstood concepts, and more.",
  },
]

const WARNING_TYPES = {
  "warning_contradictory": "Contradictory Response",
  "warning_unsubstantiated": "Unsubstantiated Response",
  "warning_repetition": "Repetitive Response",
  "warning_incorrect": "Incorrect Response",
}
const TeacherConversation = ({ history, studentView=false}) => {
  const { updateIntroStatus, userToken, ltiToken } = useContext(SherpaContext)

  const conversationId = useParams();
  //create a state variable tha stores conversationId as string
  const [conversationid, setconversationid] = useState(conversationId.conversationId)
  const location = useLocation();
  const { conversations } = location.state || {}; // Destructure conversations from location.state
  const urlParams = new URLSearchParams(location.search);

  const [editEvaluationModal, setEditEvaluationModal] = useState(false)
  const [essayModalIsOpen, setEssayModalIsOpen] = useState(false)
  const [infoModalIsOpen, setInfoModalIsOpen] = useState(false)
  const [sherpaThinkingModal, setSherpaThinkingModal] = useState(false)
  const [stepsEnabled, setStepsEnabled] = useState(false)
  // const [stepsEnabled, setStepsEnabled] = useState(!userToken?.details?.intro_status?.evaluate && !ltiToken && conversationid === "64caccd50a8609647d034416")
  const [conversationIsFinished, setConversationIsFinished] = useState(false)
  const [allConversations, setAllConversations] = useState([])
  const [evaluationConversation, setEvaluationConversation] = useState(null)
  const [currentEvaluation, setCurrentEvaluation] = useState(null)
  const [isEvaluating, setIsEvaluating] = useState(false)
 


  const [questionNum, setQuestionNum] = useState(() => {
    const qNum = parseInt(urlParams.get('q_num'));
    return qNum ? qNum : 1;  // Default to 1 if no q_num is provided
  });
  const [showAIFeedback, setShowAIFeedback] = useState(null);
  const [allowStudentsToSeeFeedback, setAllowStudentsToSeeFeedback] = useState(null);
  const [editEvaluationStatus, setEditEvaluationStatus] = useState(null);


  function decodeUnicode(str) {
    return str.replace(/\\u([\d\w]{4})/gi, function (match, grp) {
      return String.fromCharCode(parseInt(grp, 16));
    });
  }


  const handleConversationChange = () => {
    const index = conversations.findIndex(conversation => conversation._id === conversationid);
    const nextIndex = index+1;
    if ( nextIndex>=0 && nextIndex <= conversations.length - 1){
      setconversationid(conversations[nextIndex]._id);
    }
    else{
      toast.error("No more conversations to show");
    }
  }


  useEffect(()=>{
    if (evaluationConversation) {
      // if the conversation itself has no opinion
      if (evaluationConversation.conversation.show_ai_feedback == null){
        // default to the assignment stuff
        setShowAIFeedback((studentView && (evaluationConversation.assignment.student_ai_feedback)) || !studentView) // this is more about what to see as a user
        setAllowStudentsToSeeFeedback(evaluationConversation.assignment.student_ai_feedback) // this is tied to the specific permission that students are allowed to see
      } else {
        // but, if there is an opinion, default to the conversation itself!
        setShowAIFeedback((studentView && (evaluationConversation.conversation.show_ai_feedback)) || !studentView)
        setAllowStudentsToSeeFeedback(evaluationConversation.conversation.show_ai_feedback)
      }
    }
  }, [evaluationConversation, studentView])


  useEffect(()=>{
    if (evaluationConversation){
      if (evaluationConversation.videos.length > 0){
        const currVideoId = evaluationConversation.videos[questionNum - 1]?.id;
        const evaluation = evaluationConversation.evaluations.find(item => item.video_id === currVideoId)

        setCurrentEvaluation(evaluation)
        setEditEvaluationStatus(evaluation?.status)
      }
    }

  }, [questionNum, evaluationConversation])

  useEffect(() => {

    // Set question num to 1
    if (!questionNum) {
      urlParams.set('q_num', '1');
      setQuestionNum(1)
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    }

    console.log(conversationid);
    // Get a conversation's details
    getConversation(conversationid).then(async response => {

      // Is Conversation Finished?
      setConversationIsFinished(response.conversation.is_complete)

      console.log(response)
      // Conversation Details for Evaluation
      setEvaluationConversation(response);

      // Steps Enabled
      // if (response.evaluations.length > 0){
      //   if (!userToken?.details?.intro_status?.evaluate && !ltiToken) {
      //     setStepsEnabled(true)
      //   }
      // }

      // All conversations associated with this assignment ... lowkey don't need anymore...
      setAllConversations(await getAllStudentConversationsForAssignment(response.student.uid, response.conversation.assignment_id))
    });

  }, [conversationid]);

  const incrementQNumAndUpdateURL = () => {
    let newQNum = questionNum + 1;
    urlParams.set('q_num', newQNum.toString());
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    setQuestionNum(questionNum + 1)
  }

  const decrementQNumAndUpdateURL = () => {
    let newQNum = questionNum - 1;
    urlParams.set('q_num', newQNum.toString());
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    setQuestionNum(questionNum - 1)
  }

  const updateQNum = (new_num) => {
    let newQNum = new_num;
    urlParams.set('q_num', newQNum.toString());
    window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    setQuestionNum(newQNum)
  }


  const onEvaluate = async () => {
    //  setIsEvaluating(true)

      //  Fix all transcripts
      // await improveTranscript(conversationid)

      // Evaluate Conversation
      Promise.resolve(evaluateConversationAsync(conversationid))

      // Update Conversation
      // console.log("we here yup")

      updateConversation(conversationid, {
        "is_evaluating": true
      })

      // Update this locally!
      setEvaluationConversation({
        ...evaluationConversation,
        conversation: {
          ...evaluationConversation.conversation,
          is_evaluating: true,
        }
      })
      // Stop loading
      // setIsEvaluating(false)
  }

  const updateEvaluation = async (video_id, updated_values) => {

    // Modify values
    updated_values["confidence_score"] = "Your teacher has overwritten the AI evaluation"
    updated_values["confidence_reason"] = "Your teacher has overwritten the AI evaluation"

    // Update Mongo!
    await updateEval(currentEvaluation.id, updated_values)

    // Update
    setEvaluationConversation({
      ...evaluationConversation,
      evaluations: evaluationConversation.evaluations.map(evaluation => {
        if (evaluation.video_id === video_id){
          return {
            ...evaluation,
            ...updated_values
          }
        } else {
          return evaluation
        }
      })
    })

  }

  const [feedbackModal, setFeedbackModel] = useState(false)
  const [hasStartedPlaying, setHasStartedPlaying] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const audioRef = useRef()

  useEffect(()=>{
    setHasStartedPlaying(false)
    setIsPlaying(false)
  }, [questionNum])

  useEffect(()=>{
    if (audioRef.current) {
      if (!isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
    }
  }, [isPlaying])

  return evaluationConversation && showAIFeedback != null ? (
    <>
    <Modal style={{
            zIndex: 999999999999999999999,
            height: '100px'
      }} title="More Information" open={essayModalIsOpen} footer={[
      ]} onCancel={() => {
        setEssayModalIsOpen(false)
      }}>

          <div>
              Conversation started on <b>{convertUtcToNow(evaluationConversation.conversation.created_at).toLocaleString()}</b>. <br/>
              {evaluationConversation.conversation.is_complete && evaluationConversation.videos[evaluationConversation.videos.length - 1].created_at ?
                <>
                  Conversation ended on <b>{convertUtcToNow(evaluationConversation.videos[evaluationConversation.videos.length - 1].created_at).toLocaleString()}</b>. <br/>
                  This conversation lasted <b>{convertMinutesToReadableTime(Math.floor((convertUtcToNow(evaluationConversation.videos[evaluationConversation.videos.length - 1].created_at) - convertUtcToNow(evaluationConversation.conversation.created_at)) / 60000))}</b>.
                </>
                : null
              }
          </div>
          {!studentView ? 
            <Button onClick={()=>{
              history.push('/teacher/assignment/' + evaluationConversation.assignment.id)
            }}  type='primary'>
              See Assignment
            </Button>
            : null  
          }
          <br />
          {evaluationConversation.assignment.assignment_type === "Reading Responses" ?
            <>
              <b>Reading Response</b> <br/>
              {evaluationConversation.assignment.text}
            </>
            : evaluationConversation.assignment.assignment_type === "Student-Based Assignment" ?
            <>
              <b>Student Work</b> <br/>
              {evaluationConversation.conversation.student_work}
            </>
            : null
          }
    </Modal>
    <InfoModal
      isOpen={infoModalIsOpen}
      setIsOpen={setInfoModalIsOpen}
      title="Share Feedback"
      main={
        <div className="w-full flex flex-col items-start mt-4 gap-4">
          <div className="flex gap-2 w-full justify-between">
            <span>Allow students to view the AI evaluation?</span>
            <Switch checked={allowStudentsToSeeFeedback} onChange={async (value)=>{
              await updateConversation(conversationid, {
                "show_ai_feedback": value
              })
              setEvaluationConversation({
                ...evaluationConversation,
                conversation: {
                  ...evaluationConversation.conversation,
                  show_ai_feedback: value
                }
              })
            }}/>
          </div>
          <Input disabled={!allowStudentsToSeeFeedback} style={{height: '40px'}}value={window.location.origin + "/student/evaluate/" + conversationid} />
          <Button disabled={!allowStudentsToSeeFeedback} className="w-full text-white font-semibold cursor-pointer" size='large' type='primary'
              onClick={async ()=>{
                  navigator.clipboard.writeText(window.location.origin + "/student/evaluate/" + conversationid)

                  toast.success("Copied Link to Clipboard!")
              }}
          >
              Copy Link to Share Feedback with {evaluationConversation.student?.name?.split(" ")[0]}
          </Button>
      </div>
      }
    />

    <BugReportModal type="Thank you! We would love to hear your thoughts." isOpen={feedbackModal} source="Evaluation_Feedback" setIsOpen={setFeedbackModel} onComplete={()=>{

      if (userToken.details.role === "teacher") {
        history.push('/teacher/gettingstarted')
      }

    }} customForm={<>
      <Form.Item
          name="details"
          label="Did you agree with how we evaluated your conversation? Would you do it differently?"
          //rules={[{ required: true, message: 'This is required!'}]}
      >
          <Input.TextArea rows={4}/>
      </Form.Item>
      <Form.Item
          name="useful"
          label="Would you find an evaluation like this useful in your classroom?"
          //rules={[{ required: true, message: 'This is required!'}]}
      >
          <Input.TextArea rows={4}/>
      </Form.Item>
      </>} />
    <BugReportModal customOnSubmit={async (values)=>{
      await updateEvaluation(currentEvaluation.video_id, values)
      await reportBug("override_ai_evaluation", currentEvaluation.video_id, {
        "old": currentEvaluation,
        "new": values
      })
      toast.success("Updated AI Evaluation!")
    }} type="Edit AI Evaluation" isOpen={editEvaluationModal} source="BadEvaluation" setIsOpen={setEditEvaluationModal} initialValues={currentEvaluation} customForm={<>
        <Form.Item
          label="Type of Evaluation"
          name="status"
          rules={[
            { required: true, message: "This can't be empty." },
          ]}
        >
          <Select
            onChange={(value)=>{
              setEditEvaluationStatus(value)
            }}
          >
              <Select.Option value="warning">Improvement</Select.Option>
              <Select.Option value="verified">Verified</Select.Option>
            </Select>
        </Form.Item>

        {editEvaluationStatus !== "verified" ?
        <>
         <Form.Item
            name="action"
            label="Summary"
            rules={[{ required: true, message: 'This is required!'}]}
        >
            <Input />
        </Form.Item>
          <Form.Item
              name="reason"
              label="Feedback"
              rules={[{ required: true, message: 'This is required!'}]}
          >
              <Input.TextArea rows={4}/>
          </Form.Item>
        </>
        : null}

      </>}
      />
    <IntroSteps
      enabled={stepsEnabled}
      steps={INTRO_STEPS}
      initialStep={0}
      options={{
        doneLabel: "Done",
        exitOnOverlayClick: false,
        exitOnEsc: false,
        disableInteraction: true,
      }}
      onExit={()=>{
        setStepsEnabled(false)
      }}
      onComplete={async ()=>{
        await updateIntroStatus("evaluate")
      }}
    />

    {/* Nav Bar */}
    <div className={`bg-white w-full flex justify-between items-center h-16`} style={{borderBottom: '1.5px solid #E2E8F0', fontFamily: 'Inter !important'}} >

        <div className="flex items-center cursor-pointer pl-4 gap-2" onClick={()=>{
          // if (conversationid === userToken.details.getting_started?.main_conversation) {
          //   setFeedbackModel(true);
          // } else {
            history.goBack();
          // }
        }}>
          <AiOutlineLeft />
          Back
        </div>

         {/* Assignment Name with Hyperlink */}
      <div className="text-md m-4" style={{ fontWeight: 600 }}>
        <a href={`/teacher/assignment/${evaluationConversation.assignment.id}`}>{evaluationConversation.assignment.title}</a>
      </div>


        <div className="flex items-center gap-2 pr-4 cursor-pointer">
          {!studentView ?
            <Button onClick={handleConversationChange}>
              Next Student
            </Button>
            
            : null   
          }
          {!studentView ?
          <Button onClick={()=>{
            setEssayModalIsOpen(true)
          }}>
            More Information
          </Button>
          : null}
          {!studentView ?
            <Button onClick={()=>{
              // history.push('/teacher/assignment/' + evaluationConversation.assignment.id)
              setInfoModalIsOpen(true)
            }} type='primary'>
              Share Feedback with {evaluationConversation.student?.name?.split(" ")[0]}
            </Button>
          :
          null}
          {/* <Select
            className="w-48"
            value={conversationid}
            onChange={(id) => {
              setconversationid(id)
              window.history.replaceState({}, '', `${window.location.origin}/teacher/conversation/${id}?q_num=${questionNum}`);
            }}
          >
            {allConversations.reverse().map((conversation, i) => {
              return (
                <Select.Option key={conversation['id']} value={conversation['id']}>
                  Attempt {i + 1}
                </Select.Option>
              )
            })}

          </Select> */}
        </div>
    </div>

    <div className="w-full py-8 bg-[#f9f9f9] flex justify-center">

            <div className="px-8 justify-center flex flex-col w-full">
            <div className="text-2xl font-semibold my-2 flex justify-between w-full items-center">
              <div>{evaluationConversation.student?.name?.split(" ")[0]}'s Conversation</div>
              <div>{userToken.details.role === "teacher" ?
                <Button onClick={()=>{
                  if (studentView) {
                    history.push('/teacher/conversation/' + conversationid)
                  } else {
                    history.push('/student/evaluate/' + conversationid)
                  }
                }} type='dashed'>
                  Switch to {studentView ? "Teacher" : "Student"} Mode
                </Button>
              :
                null
              }</div>
            </div>
              { showAIFeedback ?
              <>
                {evaluationConversation.assignment.assignment_type === "IB Oral" ?
                  <div className="text-md opacity-70 flex flex-col w-full mt-4">
                    <div className="leading-relaxed" style={{fontWeight: 600}}>
                    <div>
                    {Object.entries(evaluationConversation.evaluations.find(evaluation => evaluation.video_id === evaluationConversation.videos[0].id)?.reason.grade || {}).map(([index, criteria], i) => {
  return (
    <div key={i} style={{ marginBottom: '20px' }}>
      {Object.entries(criteria).map(([key, value], j) => {
        if (key.startsWith("Criteria")) {
          const splitKey = key.split("_");
          const criteriaLabel = `${splitKey[0]} ${splitKey[1]}`;
          const valueType = splitKey[2];
          let content = null;

          if (valueType === "Score") {
            const suggestionsKey = `Criterion_${splitKey[1]}_Suggestions`;
            const suggestions = criteria[suggestionsKey];
            content = (
              <>
                <h3 style={{ fontWeight: 'bold' }}>{`${criteriaLabel} Score: ${value}`}</h3>
                {suggestions && <p>{`${criteriaLabel} Suggestions: ${suggestions}`}</p>}
              </>
            );
          }

          return (
            <div key={j}>
              {content}
            </div>
          );
        } else if (key === 'Final_Score') {
          return (
            <div key={`final-${j}`} style={{ marginTop: '20px' }}>
              <h2 style={{ fontWeight: 'bold' }}>Final Grade: {value}</h2>
            </div>
          );
        }
        return null;
      })}
    </div>
  );
})}
</div>
                    </div>
                  </div>
                :
                <>
                  <div className="my-4 flex flex-col gap-8">
                        {evaluationConversation.evaluations.find(item => item.status !== "verified") ?
                          <div className="flex gap-2">
                            {evaluationConversation.evaluations.find(item => item.status === "warning") ? <Tag color="orange" className="status-tag">{evaluationConversation.evaluations.filter(item => item.status === "warning").length} {evaluationConversation.evaluations.filter(item => item.status === "warning").length > 1 ? "Improvements" : "Improvement"}</Tag> : null }
                            {evaluationConversation.evaluations.find(item => item.status === "highlight") ? <Tag color="green" className="status-tag">{evaluationConversation.evaluations.filter(item => item.status === "highlight").length} {evaluationConversation.evaluations.filter(item => item.status === "highlight").length > 1 ? "Highlights" : "Highlight"}</Tag> : null}
                            {evaluationConversation.evaluations.find(item => item.status === "incomplete") ? <Tag color="orange" className="status-tag">{evaluationConversation.evaluations.filter(item => item.status === "incomplete").length} {evaluationConversation.evaluations.filter(item => item.status === "incomplete").length > 1 ? "Improvements" : "Improvement"}</Tag> : null }
                          </div>
                        : null }
                  </div>
                  {evaluationConversation.evaluations.filter(item => item.status === "warning").length ?
                      <div className="text-md opacity-70 flex flex-col w-full mt-4">
                          <div className="leading-relaxed" style={{
                            fontWeight: 600
                          }}>
                            Potential Concerns
                          </div>
                          <ol className="flex flex-col gap-3">
                            {evaluationConversation.evaluations.map((evaluation, index) => {
                              if (evaluation.status === "warning") {
                                return (
                                  <li key={index} className="leading-relaxed w-full">
                                    <div className="flex items-center gap-2">
                                      <span>
                                        {evaluation?.correction ?
                                          <>{evaluation.correction.charAt(0).toUpperCase() + evaluation.correction.slice(1)}</>
                                        :
                                          <>{evaluation.action.charAt(0).toUpperCase() + evaluation.action.slice(1)}</>
                                        }
                                      </span>
                                        <span className="text-purple-500 hover:underline cursor-pointer">
                                          <span onClick={()=>{
                                            const videoIndex = evaluationConversation.videos.findIndex(video => video.id === evaluation.video_id);
                                            updateQNum(videoIndex + 1);
                                          }}>
                                            (See Question {index + 2})
                                          </span>
                                      </span>
                                    </div>
                                  </li>
                                )
                              }
                              return null;
                            })}

                          </ol>
                      </div>
                    : null}
                  {evaluationConversation.evaluations.filter(item => item.status === "highlight").length ?
                      <div className="text-md opacity-70 flex flex-col w-full mt-4">
                          <div className="leading-relaxed" style={{
                            fontWeight: 600
                          }}>
                            {evaluationConversation.student?.name.split(" ")[0]}'s highlight moments
                          </div>
                          <ol className="flex flex-col gap-3">
                            {evaluationConversation.evaluations.map((evaluation, index) => {
                              if (evaluation.claim && evaluation.status === "highlight") {
                                return (
                                  <li key={index} className="leading-relaxed w-full">
                                    <div className="flex flex-col">
                                      <span>
                                        {evaluation.claim.charAt(0).toUpperCase() + evaluation.claim.slice(1)}
                                      </span>
                                      <span  onClick={()=>{
                                        const videoIndex = evaluationConversation.videos.findIndex(video => video.id === evaluation.video_id);
                                        updateQNum(videoIndex + 1);
                                      }} className="mt-2 flex gap-2 text-purple-500 items-center hover:underline cursor-pointer"><span>See Question {index + 2}</span><AiOutlineArrowRight /></span>
                                    </div>
                                  </li>
                                )
                              }
                              return null;
                            })}

                          </ol>
                      </div>
                    : null}
                  {evaluationConversation.evaluations.filter(item => item.status === "incomplete").length ?
                    <div className="text-md opacity-70 flex flex-col w-full mt-4">
                        <div className="leading-relaxed" style={{
                          fontWeight: 600
                        }}>
                          Concepts that {evaluationConversation.student?.name.split(" ")[0]} should <i>revisit</i>
                        </div>
                        <ol className="flex flex-col gap-3">
                          {evaluationConversation.evaluations.map((evaluation, index) => {
                            if (evaluation.action) {
                              return (
                                <li key={index} className="leading-relaxed w-full">
                                  <div className="flex flex-col">
                                    <span>{evaluation.action.charAt(0).toUpperCase() + evaluation.action.slice(1)} </span><span className="mt-2 flex gap-2 text-purple-500 items-center hover:underline cursor-pointer"><span onClick={()=>{
                                      const videoIndex = evaluationConversation.videos.findIndex(video => video.id === evaluation.video_id);
                                      updateQNum(videoIndex + 1);
                                    }}>See Question {index + 2}</span><AiOutlineArrowRight /></span>
                                  </div>
                                </li>
                              )
                            }
                            return null;
                          })}

                        </ol>
                    </div>
                  : null}
                </>
                }
              </>
              :
                <div className="text-md opacity-70 flex flex-col w-full mt-4">
                  <div className="leading-relaxed">
                    Your teacher has not released feedback for this conversation.
                  </div>
                </div>
              }

              {evaluationConversation.evaluations.length === 0?

                <div id="total_identified" className="w-full flex items-center justify-between mt-4">

                  <div className="flex flex-col justify-start items-start gap-4 opacity-70">
                  {
                  isEvaluating ?
                  <div className="flex flex-col gap-4">
                    <div>Evaluating... this will take around 2-3 minutes. Do not leave this web-page.</div>
                  </div>

                  : conversationIsFinished ?
                  <div className="flex flex-col gap-4">
                    <div>Sherpa has not evaluated this student's responses.</div>
                  </div>
                  :
                  <div className="flex flex-col gap-4">
                    <div>You can only evaluate once the student has completed the conversation</div>
                  </div>
                  }

              </div>
              {evaluationConversation.conversation.is_evaluating ?
                <div className="flex gap-2 items-center">
                  <div>Currently evaluating... (refresh in 2-3 mins) </div>
                  <ReactLoading
                    type={"spin"}
                      color={"purple"}
                      height={16}
                      width={16}
                  />
                </div>
                :
              <Button loading={isEvaluating} onClick={onEvaluate} id="evaluate_button" disabled={!conversationIsFinished} type="primary">
                Click Here to Evaluate Student Transcript
              </Button>
              }
                </div>
            :
            <div className="w-full flex items-center justify-between">
          </div>}
            </div>
        </div>
    {/* If conversation is finished, and we've also evaluated it! SHOW THE THING */}
    <div className="flex justify-center items-center" style={{
      fontFamily: 'Inter'
    }}>
      <div className="w-full flex flex-col gap-8 p-8">
        <div className="w-full flex justify-between items-center">
          <Segmented
            value={questionNum}
          onChange={(value)=>{
            updateQNum(value)
          }}className="p-2" options={evaluationConversation.assignment.conversation_flow.slice(0, -1).map((question, i) => {
            const currVideo = evaluationConversation.videos[i]
            const currEval = currVideo ? evaluationConversation.evaluations.find(item => item.video_id === currVideo.id) : null
            return {
              label: (
                <div className="px-2 py-1 flex gap-2 items-center">
                  {showAIFeedback && evaluationConversation.evaluations.length > 0 ?
                  <>
                      {currEval?.status === "warning" ?
                        <IoIosWarning size={20}/>
                      : currEval?.status === "highlight" ?
                        <AiFillStar size={20}/>
                      : null }
                  </>
                  : null}
                  <div>Question {i + 1}</div>
                </div>
              ),
              value: i + 1
            }
          })} />
         { questionNum !== null ? 
          // <></>
          <div id="next_previous_buttons" className="flex flex-row cursor-pointer gap-2">
                    <Button 
                    
                      type="primary"
                      onClick={()=>{
                        decrementQNumAndUpdateURL()
                      }}
                      disabled={questionNum === 1}
                      className="flex flex-row gap-2 items-center"
                      
                    >
                      {/* <AiOutlineLeft size={20}/>  */}
                      <span>Previous</span>
                    </Button>

                    {/* {(currentEvaluation && !currentEvaluation.feedback) ? 
                      <Tooltip title="Give Feedback on AI Evaluation Below First">
                        <Button
                          type='dashed'
                          disabled={true}
                        >
                        <div className="items-center gap-2 flex">
                          <span>Next Question</span>
                          <AiOutlineRight size={20}/>
                        </div>
                      </Button>
                      </Tooltip>

                    : evaluationConversation.evaluations.length === 0 ? 

                    <Tooltip title="Evaluate conversation first!">
                      <Button
                      type='dashed'
                      disabled={true}
                    >
                      <div className="items-center gap-2 flex">
                        <span>Next Question</span>
                        <AiOutlineRight size={20}/>
                      </div>
                    </Button>
                    </Tooltip>
                    : */}
                    <Button
                      type='primary'
                        onClick={()=>{
                            incrementQNumAndUpdateURL()
                        }}
                        disabled={questionNum === evaluationConversation.videos.length}
                        className="flex flex-row gap-2 items-center"
                      >
                        <span>Next</span>
                        {/* <AiOutlineRight size={20}/> */}
                      </Button>
                    {/* } */}

          </div>
          : null}
        </div>
        {questionNum ?
        <>

        {evaluationConversation.assignment.assignment_type === "IB Orsdal" && questionNum !== 1 ?
          <div className="rounded-lg w-full bg-[#F9F9F9] p-8 justify-between flex gap-2 items-start" style={{
            border: '1.5px solid #e8e8e8',
          }}>
            <div className="flex flex-col gap-8">
              <span>{evaluationConversation.conversation.feedback[questionNum - 1]}</span>
            </div>
          </div>
        :
        showAIFeedback && evaluationConversation.evaluations.length > 0 ?
        <div className="justify-between flex items-center">
          {currentEvaluation?.status === "warning" ?
            <div id="ai_evaluation" className={`rounded-lg w-full bg-[#FFF7E6]`} style={{
              border: '1.5px solid #FED591',
              color: '#D46B08'
            }}>
              <div className={`p-8 justify-between flex gap-2 items-start`}>
                <div className="flex flex-col gap-8">
                    <span className="leading-relaxed"><b>Improvement: </b>{currentEvaluation.reason}</span>
                </div>
                {!studentView ?
                <Button
                  type='primary'
                  style={{
                    background: '#D46B08'
                  }}
                  onClick={()=>{
                    setEditEvaluationModal(true)
                  }}
                >
                  Edit Feedback
                </Button>
                : null}
              </div>
            </div>
            : currentEvaluation?.status === "incomplete" ?
            <div id="ai_evaluation" className={`rounded-lg w-full text-orange-800 bg-[#FFF7E6]`} style={{
              border: '1.5px solid #FED591'
            }}>
              <div className={`p-8 justify-between flex gap-2 items-start`}>
                <div className="flex flex-col gap-8">
                    <span className="leading-relaxed"><b>Improvement: </b>{currentEvaluation.reason}</span>
                </div>
              </div>
            </div>
            : currentEvaluation?.status === "highlight" ?
            <div id="ai_evaluation" className={`rounded-lg w-full text-green-800 bg-[#F6FFEC]`} style={{
              border: '1.5px solid #B7EA8F'
            }}>
              <div className={`p-8 justify-between flex gap-2 items-start`}>
                <div className="flex flex-col gap-8">
                    <span className="leading-relaxed"><b>Highlight: </b>{currentEvaluation.reason}</span>
                </div>
              </div>
            </div>
            : currentEvaluation?.status === "verified" ?
            <div id="ai_evaluation" className={`rounded-lg w-full text-green-800 bg-[#f7fff2]`} style={{
              border: '1.5px solid #daffbc',
            }}>
              <div className={`p-8 justify-between flex gap-2 items-start`}>
                <div className="flex flex-col gap-8 opacity-70 mt-1">
                    <span>This answer is verified by Sherpa.  </span>
                </div>
                {!studentView ?
                <Button
                  type='primary'
                  style={{
                    background: '#599573'
                  }}
                  onClick={()=>{
                    setEditEvaluationModal(true)
                  }}
                >
                  Edit Feedback
                </Button>
                : null}
              </div>
            </div>
            : questionNum === 1 || (questionNum === 2 && evaluationConversation.assignment.assignment_type === 'IB Oral')?
            <div id="ai_evaluation" className={`rounded-lg w-full text-gray-800 bg-[#F9F9F9]`} style={{
              border: '1.5px solid #e8e8e8',
            }}>
              <div className={`p-8 justify-between flex gap-2 items-start`}>
                <div className="flex flex-col gap-8 opacity-60">
                  <span>{"This question cannot be evaluated"}</span>
                </div>
              </div>
            </div>
            : questionNum === 6 ?
            <div id="ai_evaluation" className={`rounded-lg w-full text-gray-800 bg-[#F9F9F9]`} style={{
              border: '1.5px solid #e8e8e8',
            }}>
              <div className={`p-8 justify-between flex gap-2 items-start`}>
                <div className="flex flex-col gap-8 opacity-60">
                    <span>This question cannot be evaluated. </span>
                </div>
              </div>
            </div>
            : null
          }
        </div>
        : null}

          {/* feedback! */}
          {/* {!studentView && showAIFeedback && currentEvaluation && !currentEvaluation?.feedback ?
          <div style={{border: '1.5px solid #E2E8F0', fontFamily: 'Inter !important'}} className="rounded-lg bg-white">
            <div className="p-8 flex items-center justify-between">
              <span>Do you agree with the Sherpa AI?</span>
              <div className="flex gap-3 flex-row items-center">
              <Button
                type="dashed"
                onClick={()=>{
                  setBadEvaluationModal(true)
                }}
              >
                I do not agree
              </Button>
              <Button
                type="dashed"
                onClick={()=>{
                  setBadEvaluationModal(true)
                }}
              >
                I somewhat disagree
              </Button>
              <Button
                type="dashed"
                onClick={()=>{
                  updateEvaluation(currentEvaluation.video_id, {
                    "type": "I somewhat agree"
                  })
                }}
              >
                I somewhat agree
              </Button>
              <Button
                type="dashed"
                onClick={()=>{
                  updateEvaluation(currentEvaluation.video_id, {
                    "type": "I strongly agree"
                  })
                }}
              >
                I strongly agree
              </Button>
              </div>
            </div>
          </div>
          : null} */}

      <div id="main_evaluate_component" style={{border: '1.5px solid #E2E8F0', fontFamily: 'Inter !important'}} className="rounded-lg bg-white flex flex-col gap-4 w-full">

          <div className="p-8">
          <div className="w-full flex justify-between items-center mb-4">
              <div className="text-xl">Student Transcript</div>
              <div className="text-sm text-gray-400">{evaluationConversation.videos[questionNum - 1]?.created_at ? convertUtcToNow(evaluationConversation.videos[questionNum - 1].created_at).toLocaleString() : null}</div>
          </div>
          {evaluationConversation.videos.length > 0 ?
          <div className="flex flex-col w-full gap-8">
            <div className="w-full flex flex-row gap-8">
              {evaluationConversation.conversation.with_video !== false && evaluationConversation.videos[questionNum - 1] ?
              <ReactPlayer controls url={evaluationConversation.videos[questionNum - 1]?.url ?? ""} width="100%" className="student-video-player" />
              : null }
              <Card className="flex flex-col w-full overflow-auto border-0 bg-[#fcfcfc]">
                <ScrollFade />
                {/* {evaluationConversation.videos[questionNum - 1].question_type ?
                  <div className="text-sm text-gray">
                    {evaluationConversation.videos[questionNum - 1].question_type?.type.charAt(0).toUpperCase() + evaluationConversation.videos[questionNum - 1].question_type?.type.slice(1)} {evaluationConversation.videos[questionNum - 1].question_type?.type === "base" ? "Level " + evaluationConversation.videos[questionNum - 1].question_type.level : null}
                  </div>
                : null} */}

                <div className="text-lg mb-4 flex items-center gap-4">
                  {evaluationConversation.videos[questionNum - 1] ?
                    <Button
                      shape="circle"
                      onClick={async ()=>{
                        if (!hasStartedPlaying) {
                          let audio = new Audio(await getPresignedUrl(evaluationConversation.videos[questionNum - 1].uuid + "_audio.webm"));
                          audioRef.current = audio
                          setHasStartedPlaying(true)
                        }

                        setIsPlaying(!isPlaying)

                      }}
                      icon={isPlaying ? <IoMdPause size={22} /> : <IoMdVolumeHigh size={22} />}
                    />
                  : null }
                  <span>Question {questionNum}</span>


                </div>

                {evaluationConversation.videos[questionNum - 1] ?
                <>
                  <b>Sherpa: {evaluationConversation.videos[questionNum - 1].question}</b>
                  <Tooltip id="highlight" className="w-12" />
                  <p className="text-black opacity-60">
                    <b>{evaluationConversation.student.name}: </b>{evaluationConversation.videos[questionNum - 1].improved_response}
                  </p>
                </>
                :
                <>
                  This question has not been asked, and the student has not responded to it.
                </>}
              </Card>
            </div>

          </div>
          :
          <>
            There are no videos in this conversation!
          </>
        }
        </div>
        </div>

        { currentEvaluation && showAIFeedback && (questionNum > 2 || evaluationConversation.assignment.assignment_type !== 'IB Oral') ?
        <div style={{border: '1.5px solid #E2E8F0', fontFamily: 'Inter !important'}} className="rounded-lg bg-white p-8 flex flex-col gap-4">
            <div className="text-xl">AI Details</div>
                          <Collapse
                            bordered={false}
                          >
                            {currentEvaluation.confidence_score && currentEvaluation.confidence_reason ?
                              <Collapse.Panel
                                header='How confident is Sherpa?'
                                key='1'
                                className="w-full"
                              >
                                <b>Score: </b>{currentEvaluation.confidence_score}<br/>
                                <b>Reason: </b>{currentEvaluation.confidence_reason}
                              </Collapse.Panel>
                            : null}
                            {currentEvaluation.status !== "verified" && currentEvaluation.action ?
                              <Collapse.Panel
                                header={'How can ' + evaluationConversation.student.name + ' improve their answer?'}
                                key='2'
                                className="w-full"
                              >
                                <p>{currentEvaluation.action}</p>
                              </Collapse.Panel>
                            : null}
                            {currentEvaluation.model_answer && (questionNum > 2 || evaluationConversation.assignment.assignment_type !== 'IB Oral') ?
                              <Collapse.Panel
                                header='What is an ideal student response to this question?'
                                key='3'
                                className="w-full"
                              >
                                <p>{currentEvaluation.model_answer?.model_answer ? decodeUnicode(currentEvaluation.model_answer.model_answer) : decodeUnicode(currentEvaluation.model_answer)}</p>



                              </Collapse.Panel>
                            : null}
                          </Collapse>
        </div>
        : null }
        
        </> : 
          <div className="opacity-40">Click a question to <i>see more</i> details!</div>
        }

    </div>
    </div>
    </>
  ) :
    <div className="justify-center items-center flex w-full h-screen">
      <ReactLoading
        type={"spin"}
        color={"purple"}
        height={30}
        width={30}
      />
    </div>
  ;
}

export default TeacherConversation;


// import React, { useContext, useEffect, useState, useRef } from 'react';

// import { useParams } from 'react-router-dom';
// import ReactLoading from 'react-loading';
// import ReactPlayer from 'react-player/lazy'
// import { AiOutlineRight, AiOutlineLeft, AiOutlineArrowRight } from "react-icons/ai"
// import { Button, Card, Collapse, Modal, Form, Input, Tooltip, Segmented, Tag, Switch, Select } from 'antd'
// import ScrollFade from "@benestudioco/react-scrollfade";
// import { IoMdVolumeHigh,IoMdPause } from 'react-icons/io'
// import { getAllStudentConversationsForAssignment } from '../api/dashboard';
// import { getConversation, improveTranscript, updateConversation } from '../api/conversation';
// import { evaluateConversationAsync, updateEval } from '../api/evaluate';
// import { toast } from "react-toastify";
// import { Steps as IntroSteps } from 'intro.js-react';
// import { SherpaContext } from '../utils/sherpaContext';
// import BugReportModal from "../components/BugReportModal"
// import { getRole } from '../utils/lti'
// import { BsArrowRight } from 'react-icons/bs';
// import InfoModal from '../components/InfoModal';
// import { AiFillStar } from 'react-icons/ai';
// import { IoIosWarning } from 'react-icons/io';
// import { getPresignedUrl } from '../api/s3';
// import { reportBug } from '../api/bugreports';
// import { convertUtcToNow, convertMinutesToReadableTime } from '../utils/general';

// const INTRO_STEPS = [
// {
//     title: 'Welcome to the evaluation screen!',
//     intro: "Congrats on finishing your first conversation! Here is where you can evaluate your student's responses",
//     position: 'top'
//   },
//   {
//     element: '#main_evaluate_component',
//     title: "Video + Transcript",
//     intro: "This is the main evaluation component. On the left, is the video of the student's response. On the right, is what they said."
//   },
//   {
//     element: '#next_previous_buttons',
//     title: "Next Question",
//     intro: "Use these buttons to switch between different questions. Press the right button to go to the next question."
//   },
//   {
//     element: "#ai_evaluation",
//     title: "AI Evaluation",
//     intro: "We use AI to surface 'warnings' to teachers - moments that we think may reveal a lack of understanding. If a response is unproblematic, Sherpa 'verifies' them.",
//   },
//   {
//     element: "#evaluation_details",
//     title: "Evaluation Details",
//     intro: "We provide more details about this evaluation here as well. You can see how confident Sherpa was in its evaluation, what you can do as a teacher to help clarify the misunderstood concepts, and more.",
//   },
// ]

// const WARNING_TYPES = {
//   "warning_contradictory": "Contradictory Response",
//   "warning_unsubstantiated": "Unsubstantiated Response",
//   "warning_repetition": "Repetitive Response",
//   "warning_incorrect": "Incorrect Response",
// }
// const TeacherConversation = ({ history, studentView=false}) => {
//   const { updateIntroStatus, userToken, ltiToken } = useContext(SherpaContext)

//   const { conversationid } = useParams();

//   const [editEvaluationModal, setEditEvaluationModal] = useState(false)
//   const [essayModalIsOpen, setEssayModalIsOpen] = useState(false)
//   const [infoModalIsOpen, setInfoModalIsOpen] = useState(false)
//   const [sherpaThinkingModal, setSherpaThinkingModal] = useState(false)
//   const [stepsEnabled, setStepsEnabled] = useState(false)
//   // const [stepsEnabled, setStepsEnabled] = useState(!userToken?.details?.intro_status?.evaluate && !ltiToken && conversationid === "64caccd50a8609647d034416")
//   const [conversationIsFinished, setConversationIsFinished] = useState(false)
//   const [allConversations, setAllConversations] = useState([])
//   const [evaluationConversation, setEvaluationConversation] = useState(null)
//   const [currentEvaluation, setCurrentEvaluation] = useState(null)
//   const [isEvaluating, setIsEvaluating] = useState(false)

//   const urlParams = new URLSearchParams(window.location.search);
//   const [questionNum, setQuestionNum] = useState(parseInt(urlParams.get('q_num')) ?? null)
//   const [showAIFeedback, setShowAIFeedback] = useState(null)
//   const [allowStudentsToSeeFeedback, setAllowStudentsToSeeFeedback] = useState(null)
//   const [editEvaluationStatus, setEditEvaluationStatus] = useState(null)

//   useEffect(()=>{
//     if (evaluationConversation) {
//       // if the conversation itself has no opinion
//       if (evaluationConversation.conversation.show_ai_feedback == null){
//         // default to the assignment stuff
//         setShowAIFeedback((studentView && (evaluationConversation.assignment.student_ai_feedback)) || !studentView) // this is more about what to see as a user
//         setAllowStudentsToSeeFeedback(evaluationConversation.assignment.student_ai_feedback) // this is tied to the specific permission that students are allowed to see
//       } else {
//         // but, if there is an opinion, default to the conversation itself!
//         setShowAIFeedback((studentView && (evaluationConversation.conversation.show_ai_feedback)) || !studentView)
//         setAllowStudentsToSeeFeedback(evaluationConversation.conversation.show_ai_feedback)
//       }
//     }
//   }, [evaluationConversation, studentView])


//   useEffect(()=>{
//     if (evaluationConversation){
//       if (evaluationConversation.videos.length > 0){
//         const currVideoId = evaluationConversation.videos[questionNum - 1]?.id;
//         const evaluation = evaluationConversation.evaluations.find(item => item.video_id === currVideoId)

//         setCurrentEvaluation(evaluation)
//         setEditEvaluationStatus(evaluation?.status)
//       }
//     }

//   }, [questionNum, evaluationConversation])

//   useEffect(() => {

//     // Set question num to 1
//     // if (!questionNum) {
//     //   urlParams.set('q_num', '1');
//     //   setQuestionNum(1)
//     //   window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
//     // }

//     // Get a conversation's details
//     getConversation(conversationid).then(async response => {

//       // Is Conversation Finished?
//       setConversationIsFinished(response.conversation.is_complete)

//       console.log(response)
//       // Conversation Details for Evaluation
//       setEvaluationConversation(response);

//       // Steps Enabled
//       // if (response.evaluations.length > 0){
//       //   if (!userToken?.details?.intro_status?.evaluate && !ltiToken) {
//       //     setStepsEnabled(true)
//       //   }
//       // }

//       // All conversations associated with this assignment ... lowkey don't need anymore...
//       setAllConversations(await getAllStudentConversationsForAssignment(response.student.uid, response.conversation.assignment_id))
//     });

//   }, [conversationid]);

//   const incrementQNumAndUpdateURL = () => {
//     let newQNum = questionNum + 1;
//     urlParams.set('q_num', newQNum.toString());
//     window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
//     setQuestionNum(questionNum + 1)
//   }

//   const decrementQNumAndUpdateURL = () => {
//     let newQNum = questionNum - 1;
//     urlParams.set('q_num', newQNum.toString());
//     window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
//     setQuestionNum(questionNum - 1)
//   }

//   const updateQNum = (new_num) => {
//     let newQNum = new_num;
//     urlParams.set('q_num', newQNum.toString());
//     window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
//     setQuestionNum(newQNum)
//   }


//   const onEvaluate = async () => {
//     //  setIsEvaluating(true)

//       //  Fix all transcripts
//       // await improveTranscript(conversationid)

//       // Evaluate Conversation
//       Promise.resolve(evaluateConversationAsync(conversationid))

//       // Update Conversation
//       updateConversation(conversationid, {
//         "is_evaluating": true
//       })

//       // Update this locally!
//       setEvaluationConversation({
//         ...evaluationConversation,
//         conversation: {
//           ...evaluationConversation.conversation,
//           is_evaluating: true,
//         }
//       })
//       // Stop loading
//       // setIsEvaluating(false)
//   }

//   const updateEvaluation = async (video_id, updated_values) => {

//     // Modify values
//     updated_values["confidence_score"] = "Your teacher has overwritten the AI evaluation"
//     updated_values["confidence_reason"] = "Your teacher has overwritten the AI evaluation"

//     // Update Mongo!
//     await updateEval(currentEvaluation.id, updated_values)

//     // Update
//     setEvaluationConversation({
//       ...evaluationConversation,
//       evaluations: evaluationConversation.evaluations.map(evaluation => {
//         if (evaluation.video_id === video_id){
//           return {
//             ...evaluation,
//             ...updated_values
//           }
//         } else {
//           return evaluation
//         }
//       })
//     })

//   }

//   const [feedbackModal, setFeedbackModel] = useState(false)
//   const [hasStartedPlaying, setHasStartedPlaying] = useState(false)
//   const [isPlaying, setIsPlaying] = useState(false)
//   const audioRef = useRef()

//   useEffect(()=>{
//     setHasStartedPlaying(false)
//     setIsPlaying(false)
//   }, [questionNum])

//   useEffect(()=>{
//     if (audioRef.current) {
//       if (!isPlaying) {
//         audioRef.current.pause();
//       } else {
//         audioRef.current.play();
//       }
//     }
//   }, [isPlaying])

//   return evaluationConversation && showAIFeedback != null ? (
//     <>
//     <Modal style={{
//             zIndex: 999999999999999999999,
//             height: '100px'
//       }} title="More Information" open={essayModalIsOpen} footer={[
//       ]} onCancel={() => {
//         setEssayModalIsOpen(false)
//       }}>

//           <div>
//               Conversation started on <b>{convertUtcToNow(evaluationConversation.conversation.created_at).toLocaleString()}</b>. <br/>
//               {evaluationConversation.conversation.is_complete && evaluationConversation.videos[evaluationConversation.videos.length - 1].created_at ?
//                 <>
//                   Conversation ended on <b>{convertUtcToNow(evaluationConversation.videos[evaluationConversation.videos.length - 1].created_at).toLocaleString()}</b>. <br/>
//                   This conversation lasted <b>{convertMinutesToReadableTime(Math.floor((convertUtcToNow(evaluationConversation.videos[evaluationConversation.videos.length - 1].created_at) - convertUtcToNow(evaluationConversation.conversation.created_at)) / 60000))}</b>.
//                 </>
//                 : null
//               }
//           </div>
//           <br />
//           {evaluationConversation.assignment.assignment_type === "Reading Responses" ?
//             <>
//               <b>Reading Response</b> <br/>
//               {evaluationConversation.assignment.text}
//             </>
//             : evaluationConversation.assignment.assignment_type === "Student-Based Assignment" ?
//             <>
//               <b>Student Work</b> <br/>
//               {evaluationConversation.conversation.student_work}
//             </>
//             : null
//           }
//     </Modal>
//     <InfoModal
//       isOpen={infoModalIsOpen}
//       setIsOpen={setInfoModalIsOpen}
//       title="Share Feedback"
//       main={
//         <div className="w-full flex flex-col items-start mt-4 gap-4">
//           <div className="flex gap-2 w-full justify-between">
//             <span>Allow students to view the AI evaluation?</span>
//             <Switch checked={allowStudentsToSeeFeedback} onChange={async (value)=>{
//               await updateConversation(conversationid, {
//                 "show_ai_feedback": value
//               })
//               setEvaluationConversation({
//                 ...evaluationConversation,
//                 conversation: {
//                   ...evaluationConversation.conversation,
//                   show_ai_feedback: value
//                 }
//               })
//             }}/>
//           </div>
//           <Input disabled={!allowStudentsToSeeFeedback} style={{height: '40px'}}value={window.location.origin + "/student/evaluate/" + conversationid} />
//           <Button disabled={!allowStudentsToSeeFeedback} className="w-full text-white font-semibold cursor-pointer" size='large' type='primary'
//               onClick={async ()=>{
//                   navigator.clipboard.writeText(window.location.origin + "/student/evaluate/" + conversationid)

//                   toast.success("Copied Link to Clipboard!")
//               }}
//           >
//               Copy Link to Share Feedback with {evaluationConversation.student?.name?.split(" ")[0]}
//           </Button>
//       </div>
//       }
//     />

//     <BugReportModal type="Thank you! We would love to hear your thoughts." isOpen={feedbackModal} source="Evaluation_Feedback" setIsOpen={setFeedbackModel} onComplete={()=>{

//       if (userToken.details.role === "teacher") {
//         history.push('/teacher/gettingstarted')
//       }

//     }} customForm={<>
//       <Form.Item
//           name="details"
//           label="Did you agree with how we evaluated your conversation? Would you do it differently?"
//           rules={[{ required: true, message: 'This is required!'}]}
//       >
//           <Input.TextArea rows={4}/>
//       </Form.Item>
//       <Form.Item
//           name="useful"
//           label="Would you find an evaluation like this useful in your classroom?"
//           rules={[{ required: true, message: 'This is required!'}]}
//       >
//           <Input.TextArea rows={4}/>
//       </Form.Item>
//       </>} />
//     <BugReportModal customOnSubmit={async (values)=>{
//       await updateEvaluation(currentEvaluation.video_id, values)
//       await reportBug("override_ai_evaluation", currentEvaluation.video_id, {
//         "old": currentEvaluation,
//         "new": values
//       })
//       toast.success("Updated AI Evaluation!")
//     }} type="Edit AI Evaluation" isOpen={editEvaluationModal} source="BadEvaluation" setIsOpen={setEditEvaluationModal} initialValues={currentEvaluation} customForm={<>
//         <Form.Item
//           label="Type of Evaluation"
//           name="status"
//           rules={[
//             { required: true, message: "This can't be empty." },
//           ]}
//         >
//           <Select
//             onChange={(value)=>{
//               setEditEvaluationStatus(value)
//             }}
//           >
//               <Select.Option value="warning">Improvement</Select.Option>
//               <Select.Option value="verified">Verified</Select.Option>
//             </Select>
//         </Form.Item>

//         {editEvaluationStatus !== "verified" ?
//         <>
//          <Form.Item
//             name="action"
//             label="Summary"
//             rules={[{ required: true, message: 'This is required!'}]}
//         >
//             <Input />
//         </Form.Item>
//           <Form.Item
//               name="reason"
//               label="Feedback"
//               rules={[{ required: true, message: 'This is required!'}]}
//           >
//               <Input.TextArea rows={4}/>
//           </Form.Item>
//         </>
//         : null}

//       </>}
//       />
//     <IntroSteps
//       enabled={stepsEnabled}
//       steps={INTRO_STEPS}
//       initialStep={0}
//       options={{
//         doneLabel: "Done",
//         exitOnOverlayClick: false,
//         exitOnEsc: false,
//         disableInteraction: true,
//       }}
//       onExit={()=>{
//         setStepsEnabled(false)
//       }}
//       onComplete={async ()=>{
//         await updateIntroStatus("evaluate")
//       }}
//     />

//     {/* Nav Bar */}
//     <div className={`bg-white w-full flex justify-between items-center h-16`} style={{borderBottom: '1.5px solid #E2E8F0', fontFamily: 'Inter !important'}} >

//         <div className="flex items-center cursor-pointer pl-4 gap-2" onClick={()=>{
//           // if (conversationid === userToken.details.getting_started?.main_conversation) {
//           //   setFeedbackModel(true);
//           // } else {
//             history.goBack();
//           // }
//         }}>
//           <AiOutlineLeft />
//           Back
//         </div>

//         <div className="text-md m-4" style={{
//           fontWeight: 600}}
//         >{evaluationConversation.student.name}</div>

//         <div className="flex items-center gap-2 pr-4 cursor-pointer">
//           {!studentView ?
//             <Button onClick={()=>{
//               history.push('/teacher/assignment/' + evaluationConversation.assignment.id)
//             }}>
//               See Assignment
//             </Button>
//             : null
//           }
//           {!studentView ?
//           <Button onClick={()=>{
//             setEssayModalIsOpen(true)
//           }}>
//             More Information
//           </Button>
//           : null}
//           {!studentView ?
//             <Button onClick={()=>{
//               // history.push('/teacher/assignment/' + evaluationConversation.assignment.id)
//               setInfoModalIsOpen(true)
//             }} type='primary'>
//               Share Feedback with {evaluationConversation.student?.name?.split(" ")[0]}
//             </Button>
//           :
//           null}
//           {/* <Select
//             className="w-48"
//             value={conversationid}
//             onChange={(id) => {
//               setconversationid(id)
//               window.history.replaceState({}, '', `${window.location.origin}/teacher/conversation/${id}?q_num=${questionNum}`);
//             }}
//           >
//             {allConversations.reverse().map((conversation, i) => {
//               return (
//                 <Select.Option key={conversation['id']} value={conversation['id']}>
//                   Attempt {i + 1}
//                 </Select.Option>
//               )
//             })}

//           </Select> */}
//         </div>
//     </div>

//     <div className="w-full py-8 bg-[#f9f9f9] flex justify-center">

//             <div className="px-8 justify-center flex flex-col w-full">
//             <div className="text-2xl font-semibold my-2 flex justify-between w-full items-center">
//               <div>{evaluationConversation.student?.name?.split(" ")[0]}'s Conversation</div>
//               <div>{userToken.details.role === "teacher" ?
//                 <Button onClick={()=>{
//                   if (studentView) {
//                     history.push('/teacher/conversation/' + conversationid)
//                   } else {
//                     history.push('/student/evaluate/' + conversationid)
//                   }
//                 }} type='dashed'>
//                   Switch to {studentView ? "Teacher" : "Student"} Mode
//                 </Button>
//               :
//                 null
//               }</div>
//             </div>
//               { showAIFeedback ?
//               <>
//                 <div className="my-4 flex flex-col gap-8">
//                       {evaluationConversation.evaluations.find(item => item.status !== "verified") ?
//                         <div className="flex gap-2">
//                           {evaluationConversation.evaluations.find(item => item.status === "warning") ? <Tag color="orange" className="status-tag">{evaluationConversation.evaluations.filter(item => item.status === "warning").length} {evaluationConversation.evaluations.filter(item => item.status === "warning").length > 1 ? "Improvements" : "Improvement"}</Tag> : null }
//                           {evaluationConversation.evaluations.find(item => item.status === "highlight") ? <Tag color="green" className="status-tag">{evaluationConversation.evaluations.filter(item => item.status === "highlight").length} {evaluationConversation.evaluations.filter(item => item.status === "highlight").length > 1 ? "Highlights" : "Highlight"}</Tag> : null}
//                           {evaluationConversation.evaluations.find(item => item.status === "incomplete") ? <Tag color="orange" className="status-tag">{evaluationConversation.evaluations.filter(item => item.status === "incomplete").length} {evaluationConversation.evaluations.filter(item => item.status === "incomplete").length > 1 ? "Improvements" : "Improvement"}</Tag> : null }
//                         </div>
//                       : null }
//                 </div>
//           {/* <Button loading={isEvaluating} onClick={onEvaluate} id="evaluate_button" disabled={!conversationIsFinished} type="primary">
//             Re-Evaluate Transcript
//           </Button> */}
//               {evaluationConversation.evaluations.filter(item => item.status === "warning").length ?
//                   <div className="text-md opacity-70 flex flex-col w-full mt-4">
//                       <div className="leading-relaxed" style={{
//                         fontWeight: 600
//                       }}>
//                         Potential Concerns
//                       </div>
//                       <ol className="flex flex-col gap-3">
//                         {evaluationConversation.evaluations.map((evaluation, index) => {
//                           if (evaluation.status === "warning") {
//                             return (
//                               <li key={index} className="leading-relaxed w-full">
//                                 <div className="flex items-center gap-2">
//                                   <span>
//                                     {evaluation?.correction ?
//                                       <>{evaluation.correction.charAt(0).toUpperCase() + evaluation.correction.slice(1)}</>
//                                     :
//                                       <>{evaluation.action.charAt(0).toUpperCase() + evaluation.action.slice(1)}</>
//                                     }
//                                   </span>
//                                     <span className="text-purple-500 hover:underline cursor-pointer">
//                                       <span onClick={()=>{
//                                         const videoIndex = evaluationConversation.videos.findIndex(video => video.id === evaluation.video_id);
//                                         updateQNum(videoIndex + 1);
//                                       }}>
//                                         (See Question {index + 2})
//                                       </span>
//                                       {/* <AiOutlineArrowRight /> */}
//                                   </span>
//                                 </div>
//                               </li>
//                             )
//                           }
//                           return null;
//                         })}

//                       </ol>
//                   </div>
//                 : null}
//               {evaluationConversation.evaluations.filter(item => item.status === "highlight").length ?
//                   <div className="text-md opacity-70 flex flex-col w-full mt-4">
//                       <div className="leading-relaxed" style={{
//                         fontWeight: 600
//                       }}>
//                         {evaluationConversation.student?.name.split(" ")[0]}'s highlight moments
//                       </div>
//                       <ol className="flex flex-col gap-3">
//                         {evaluationConversation.evaluations.map((evaluation, index) => {
//                           if (evaluation.claim && evaluation.status === "highlight") {
//                             return (
//                               <li key={index} className="leading-relaxed w-full">
//                                 <div className="flex flex-col">
//                                   <span>
//                                     {evaluation.claim.charAt(0).toUpperCase() + evaluation.claim.slice(1)}
//                                   </span>
//                                   <span  onClick={()=>{
//                                     const videoIndex = evaluationConversation.videos.findIndex(video => video.id === evaluation.video_id);
//                                     updateQNum(videoIndex + 1);
//                                   }} className="mt-2 flex gap-2 text-purple-500 items-center hover:underline cursor-pointer"><span>See Question {index + 2}</span><AiOutlineArrowRight /></span>
//                                 </div>
//                               </li>
//                             )
//                           }
//                           return null;
//                         })}

//                       </ol>
//                   </div>
//                 : null}
//                 {evaluationConversation.evaluations.filter(item => item.status === "incomplete").length ?
//                   <div className="text-md opacity-70 flex flex-col w-full mt-4">
//                       <div className="leading-relaxed" style={{
//                         fontWeight: 600
//                       }}>
//                         Concepts that {evaluationConversation.student?.name.split(" ")[0]} should <i>revisit</i>
//                       </div>
//                       <ol className="flex flex-col gap-3">
//                         {evaluationConversation.evaluations.map((evaluation, index) => {
//                           if (evaluation.action) {
//                             return (
//                               <li key={index} className="leading-relaxed w-full">
//                                 <div className="flex flex-col">
//                                   <span>{evaluation.action.charAt(0).toUpperCase() + evaluation.action.slice(1)} </span><span className="mt-2 flex gap-2 text-purple-500 items-center hover:underline cursor-pointer"><span onClick={()=>{
//                                     const videoIndex = evaluationConversation.videos.findIndex(video => video.id === evaluation.video_id);
//                                     updateQNum(videoIndex + 1);
//                                   }}>See Question {index + 2}</span><AiOutlineArrowRight /></span>
//                                 </div>
//                               </li>
//                             )
//                           }
//                           return null;
//                         })}

//                       </ol>
//                   </div>
//                 : null}
//               </>
//               :
//                 <div className="text-md opacity-70 flex flex-col w-full mt-4">
//                   <div className="leading-relaxed">
//                     Your teacher has not released feedback for this conversation.
//                   </div>
//                 </div>
//               }

//               {evaluationConversation.evaluations.length === 0 ?

//                 <div id="total_identified" className="w-full flex items-center justify-between mt-4">

//                   <div className="flex flex-col justify-start items-start gap-4 opacity-70">
//                   {
//                   isEvaluating ?
//                   <div className="flex flex-col gap-4">
//                     <div>Evaluating... this will take around 2-3 minutes. Do not leave this web-page.</div>
//                   </div>

//                   : conversationIsFinished ?
//                   <div className="flex flex-col gap-4">
//                     <div>Sherpa has not evaluated this student's responses.</div>
//                   </div>
//                   :
//                   <div className="flex flex-col gap-4">
//                     <div>You can only evaluate once the student has completed the conversation</div>
//                   </div>
//                   }

//               </div>
//               {evaluationConversation.conversation.is_evaluating ?
//                 <div className="flex gap-2 items-center">
//                   <div>Currently evaluating... (refresh in 2-3 mins) </div>
//                   <ReactLoading
//                     type={"spin"}
//                       color={"purple"}
//                       height={16}
//                       width={16}
//                   />
//                 </div>
//                 :
//               <Button loading={isEvaluating} onClick={onEvaluate} id="evaluate_button" disabled={!conversationIsFinished} type="primary">
//                 Click Here to Evaluate Student Transcript
//               </Button>
//               }
//                 </div>
//             :
//             <div className="w-full flex items-center justify-between">
//           </div>}
//             </div>
//         </div>
//     {/* If conversation is finished, and we've also evaluated it! SHOW THE THING */}
//     <div className="flex justify-center items-center" style={{
//       fontFamily: 'Inter'
//     }}>
//       <div className="w-full flex flex-col gap-8 p-8">
//         <div className="w-full flex justify-between items-center">
//           <Segmented
//             value={questionNum}
//           onChange={(value)=>{
//             updateQNum(value)
//           }}className="p-2" options={evaluationConversation.assignment.conversation_flow.slice(0, -1).map((question, i) => {
//             const currVideo = evaluationConversation.videos[i]
//             const currEval = currVideo ? evaluationConversation.evaluations.find(item => item.video_id === currVideo.id) : null
//             return {
//               label: (
//                 <div className="px-2 py-1 flex gap-2 items-center">
//                   {showAIFeedback && evaluationConversation.evaluations.length > 0 ?
//                   <>
//                       {currEval?.status === "warning" ?
//                         <IoIosWarning size={20}/>
//                       : currEval?.status === "highlight" ?
//                         <AiFillStar size={20}/>
//                       : null }
//                   </>
//                   : null}
//                   <div>Question {i + 1}</div>
//                 </div>
//               ),
//               value: i + 1
//             }
//           })} />
//           {questionNum ?
//           <></>
//           // <div id="next_previous_buttons" className="flex flex-row cursor-pointer gap-4">
//           //           <Button
//           //             type="dashed"
//           //             onClick={()=>{
//           //               decrementQNumAndUpdateURL()
//           //             }}
//           //             disabled={questionNum === 1}
//           //             className="flex flex-row gap-2 items-center"
//           //           >
//           //             <AiOutlineLeft size={20}/>
//           //             <span>Previous Question</span>
//           //           </Button>

//           //           {/* {(currentEvaluation && !currentEvaluation.feedback) ?
//           //             <Tooltip title="Give Feedback on AI Evaluation Below First">
//           //               <Button
//           //                 type='dashed'
//           //                 disabled={true}
//           //               >
//           //               <div className="items-center gap-2 flex">
//           //                 <span>Next Question</span>
//           //                 <AiOutlineRight size={20}/>
//           //               </div>
//           //             </Button>
//           //             </Tooltip>

//           //           : evaluationConversation.evaluations.length === 0 ?

//           //           <Tooltip title="Evaluate conversation first!">
//           //             <Button
//           //             type='dashed'
//           //             disabled={true}
//           //           >
//           //             <div className="items-center gap-2 flex">
//           //               <span>Next Question</span>
//           //               <AiOutlineRight size={20}/>
//           //             </div>
//           //           </Button>
//           //           </Tooltip>
//           //           : */}
//           //           <Button
//           //             type='dashed'
//           //               onClick={()=>{
//           //                   incrementQNumAndUpdateURL()
//           //               }}
//           //               disabled={questionNum === evaluationConversation.videos.length}
//           //               className="flex flex-row gap-2 items-center"
//           //             >
//           //               <span>Next Question</span>
//           //               <AiOutlineRight size={20}/>
//           //             </Button>
//           //           {/* } */}

//           // </div>
//           : null}
//         </div>
//         {questionNum ?
//         <>


//         {showAIFeedback && evaluationConversation.evaluations.length > 0 ?
//         <div className="justify-between flex items-center">
//           {currentEvaluation?.status === "warning" ? // #ffe6e8
//             <div id="ai_evaluation" className={`rounded-lg w-full bg-[#FFF7E6]`} style={{
//               // border: '1.5px solid #edb1b3'
//               border: '1.5px solid #FED591',
//               color: '#D46B08'
//             }}>
//               <div className={`p-8 justify-between flex gap-2 items-start`}>
//                 <div className="flex flex-col gap-8">
//                     <span className="leading-relaxed"><b>Improvement: </b>{currentEvaluation.reason}</span>
//                 </div>
//                 {!studentView ?
//                 <Button
//                   type='primary'
//                   style={{
//                     background: '#D46B08'
//                   }}
//                   onClick={()=>{
//                     setEditEvaluationModal(true)
//                   }}
//                 >
//                   Edit Feedback
//                 </Button>
//                 : null}
//               </div>
//             </div>
//             : currentEvaluation?.status === "incomplete" ?
//             <div id="ai_evaluation" className={`rounded-lg w-full text-orange-800 bg-[#FFF7E6]`} style={{
//               border: '1.5px solid #FED591'
//             }}>
//               <div className={`p-8 justify-between flex gap-2 items-start`}>
//                 <div className="flex flex-col gap-8">
//                     <span className="leading-relaxed"><b>Improvement: </b>{currentEvaluation.reason}</span>
//                 </div>
//               </div>
//             </div>
//             : currentEvaluation?.status === "highlight" ?
//             <div id="ai_evaluation" className={`rounded-lg w-full text-green-800 bg-[#F6FFEC]`} style={{
//               border: '1.5px solid #B7EA8F'
//             }}>
//               <div className={`p-8 justify-between flex gap-2 items-start`}>
//                 <div className="flex flex-col gap-8">
//                     <span className="leading-relaxed"><b>Highlight: </b>{currentEvaluation.reason}</span>
//                 </div>
//               </div>
//             </div>
//             : currentEvaluation?.status === "verified" ?
//             <div id="ai_evaluation" className={`rounded-lg w-full text-green-800 bg-[#f7fff2]`} style={{
//               border: '1.5px solid #daffbc',
//             }}>
//               <div className={`p-8 justify-between flex gap-2 items-start`}>
//                 <div className="flex flex-col gap-8 opacity-70 mt-1">
//                     <span>This answer is verified by Sherpa.  </span>
//                 </div>
//                 {!studentView ?
//                 <Button
//                   type='primary'
//                   style={{
//                     background: '#599573'
//                   }}
//                   onClick={()=>{
//                     setEditEvaluationModal(true)
//                   }}
//                 >
//                   Edit Feedback
//                 </Button>
//                 : null}
//               </div>
//             </div>
//             : <div id="ai_evaluation" className={`rounded-lg w-full text-gray-800 bg-[#F9F9F9]`} style={{
//               border: '1.5px solid #e8e8e8',
//             }}>
//               <div className={`p-8 justify-between flex gap-2 items-start`}>
//                 <div className="flex flex-col gap-8 opacity-60">
//                     <span>This question cannot be evaluated. </span>
//                 </div>
//               </div>
//             </div>
//           }

//         </div>
//         : null}
//           {/* feedback! */}
//           {/* {!studentView && showAIFeedback && currentEvaluation && !currentEvaluation?.feedback ?
//           <div style={{border: '1.5px solid #E2E8F0', fontFamily: 'Inter !important'}} className="rounded-lg bg-white">
//             <div className="p-8 flex items-center justify-between">
//               <span>Do you agree with the Sherpa AI?</span>
//               <div className="flex gap-3 flex-row items-center">
//               <Button
//                 type="dashed"
//                 onClick={()=>{
//                   setBadEvaluationModal(true)
//                 }}
//               >
//                 I do not agree
//               </Button>
//               <Button
//                 type="dashed"
//                 onClick={()=>{
//                   setBadEvaluationModal(true)
//                 }}
//               >
//                 I somewhat disagree
//               </Button>
//               <Button
//                 type="dashed"
//                 onClick={()=>{
//                   updateEvaluation(currentEvaluation.video_id, {
//                     "type": "I somewhat agree"
//                   })
//                 }}
//               >
//                 I somewhat agree
//               </Button>
//               <Button
//                 type="dashed"
//                 onClick={()=>{
//                   updateEvaluation(currentEvaluation.video_id, {
//                     "type": "I strongly agree"
//                   })
//                 }}
//               >
//                 I strongly agree
//               </Button>
//               </div>
//             </div>
//           </div>
//           : null} */}

//       <div id="main_evaluate_component" style={{border: '1.5px solid #E2E8F0', fontFamily: 'Inter !important'}} className="rounded-lg bg-white flex flex-col gap-4 w-full">

//           <div className="p-8">
//           <div className="w-full flex justify-between items-center mb-4">
//               <div className="text-xl">Student Transcript</div>
//               <div className="text-sm text-gray-400">{evaluationConversation.videos[questionNum - 1]?.created_at ? convertUtcToNow(evaluationConversation.videos[questionNum - 1].created_at).toLocaleString() : null}</div>
//           </div>
//           {evaluationConversation.videos.length > 0 ?
//           <div className="flex flex-col w-full gap-8">
//             <div className="w-full flex flex-row gap-8">
//               {evaluationConversation.conversation.with_video !== false && evaluationConversation.videos[questionNum - 1] ?
//               <ReactPlayer controls url={evaluationConversation.videos[questionNum - 1]?.url ?? ""} width="100%" className="student-video-player" />
//               : null }
//               <Card className="flex flex-col w-full overflow-auto border-0 bg-[#fcfcfc]">
//                 <ScrollFade />
//                 {/* {evaluationConversation.videos[questionNum - 1].question_type ?
//                   <div className="text-sm text-gray">
//                     {evaluationConversation.videos[questionNum - 1].question_type?.type.charAt(0).toUpperCase() + evaluationConversation.videos[questionNum - 1].question_type?.type.slice(1)} {evaluationConversation.videos[questionNum - 1].question_type?.type === "base" ? "Level " + evaluationConversation.videos[questionNum - 1].question_type.level : null}
//                   </div>
//                 : null} */}

//                 <div className="text-lg mb-4 flex items-center gap-4">
//                   {evaluationConversation.videos[questionNum - 1] ?
//                     <Button
//                       shape="circle"
//                       onClick={async ()=>{
//                         if (!hasStartedPlaying) {
//                           let audio = new Audio(await getPresignedUrl(evaluationConversation.videos[questionNum - 1].uuid + "_audio.webm"));
//                           audioRef.current = audio
//                           setHasStartedPlaying(true)
//                         }

//                         setIsPlaying(!isPlaying)

//                       }}
//                       icon={isPlaying ? <IoMdPause size={22} /> : <IoMdVolumeHigh size={22} />}
//                     />
//                   : null }
//                   <span>Question {questionNum}</span>


//                 </div>

//                 {evaluationConversation.videos[questionNum - 1] ?
//                 <>
//                   <b>Sherpa: {evaluationConversation.videos[questionNum - 1].question}</b>
//                   <Tooltip id="highlight" className="w-12" />
//                   <p className="text-black opacity-60">
//                     <b>{evaluationConversation.student.name}: </b>{evaluationConversation.videos[questionNum - 1].improved_response}
//                   </p>
//                 </>
//                 :
//                 <>
//                   This question has not been asked, and the student has not responded to it.
//                 </>}
//               </Card>
//             </div>

//           </div>
//           :
//           <>
//             There are no videos in this conversation!
//           </>
//         }
//         </div>
//         </div>

//         { currentEvaluation && showAIFeedback ?
//         <div style={{border: '1.5px solid #E2E8F0', fontFamily: 'Inter !important'}} className="rounded-lg bg-white p-8 flex flex-col gap-4">
//             <div className="text-xl">AI Details</div>
//                           <Collapse
//                             bordered={false}
//                           >
//                             {currentEvaluation.confidence_score && currentEvaluation.confidence_reason ?
//                               <Collapse.Panel
//                                 header='How confident is Sherpa?'
//                                 key='1'
//                                 className="w-full"
//                               >
//                                 <b>Score: </b>{currentEvaluation.confidence_score}<br/>
//                                 <b>Reason: </b>{currentEvaluation.confidence_reason}
//                               </Collapse.Panel>
//                             : null}
//                             {currentEvaluation.status !== "verified" && currentEvaluation.action ?
//                               <Collapse.Panel
//                                 header={'How can ' + evaluationConversation.student.name + ' improve their answer?'}
//                                 key='2'
//                                 className="w-full"
//                               >
//                                 <p>{currentEvaluation.action}</p>
//                               </Collapse.Panel>
//                             : null}
//                             {currentEvaluation.model_answer ?
//                               <Collapse.Panel
//                                 header='What is an ideal student response to this question?'
//                                 key='3'
//                                 className="w-full"
//                               >
//                                 <p>{currentEvaluation.model_answer?.model_answer ? currentEvaluation.model_answer.model_answer : currentEvaluation.model_answer }</p>
//                               </Collapse.Panel>
//                             : null}
//                           </Collapse>
//         </div>
//         : null }

//         </> :
//           <div className="opacity-40">Click a question to <i>see more</i> details!</div>
//         }

//     </div>
//     </div>
//     </>
//   ) :
//     <div className="justify-center items-center flex w-full h-screen">
//       <ReactLoading
//         type={"spin"}
//         color={"purple"}
//         height={30}
//         width={30}
//       />
//     </div>
//   ;
// }

// export default TeacherConversation;
