import React, { useEffect, useState } from 'react';
import ReactLoading from "react-loading";
import { Button, Table, Statistic, Modal } from 'antd';
import { convertUtcToNow, convertTimeToTimeAgo, limitLength } from '../utils/general';
import { getCourses } from '../api/adminNew';

const CourseDashboard = (props) => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentDescription, setCurrentDescription] = useState('');

  const COLUMNS = [
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => {
        created_at = convertUtcToNow(created_at);
        return convertTimeToTimeAgo(created_at);
      },
    },
    {
      title: 'Title',
      key: 'title',
      align: 'center',
      render: (_,record) => {
        const limitedTitle = limitLength(record.title, 20);
        // a button with title, that redirects to admin course details, using course_id
        return <Button
         type="default"
        title={record.title}
        onClick={() =>
          props.history.push({
            pathname: `/admin/assignments/${record._id}`,
            state: { title: record.title, subject: record.subject, grade: record.grade }
          })
        }>
          {limitedTitle}
          </Button>;
      },
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: 'Created By',
      key: '_id',
      render: (_,record) => {
        return <span>{limitLength(record.teacher.name, 50)} <br /> {limitLength(record.teacher.email, 50)}</span>;
      },
     
    },
    {
    title: 'Grade',
    key: '_id',
    render: (_,record) => {
 
      return <span>{record.grade}</span>;
    },
  },
    {
      title: 'Number of Assignments',
      dataIndex: 'assignment_ids',
      key: 'num_assignments',
      align: 'center',
      // calculate using the number of "assignment_ids" field present
      render: (assignment_ids) => {
        return assignment_ids.length;
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (description) => {
        return (
          <>
            {limitLength(description, 10)}...
            <Button type="link" onClick={() => showModal(description)}>Read More</Button>
          </>
        );
      },
    },
  ];

  const filterCourses = (courses, weeksAgo) => {
    if (weeksAgo === 14) return courses; // All time option
  
    const now = new Date();
    return courses.filter(course => {
      const createdAt = new Date(course.created_at);
      const daysDifference = Math.floor((now - createdAt) / (1000 * 60 * 60 * 24));
      const weeksDifference = Math.floor(daysDifference / 7);

      return weeksDifference + 1 === weeksAgo;
    });
  };

  const showModal = (description) => {
    setCurrentDescription(description);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getCourses()
      .then((response) => {
        if (response.status === 200) {
          setCourses(response.data);
          setIsLoading(false);
        } else {
          console.error('Error fetching courses:', response);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error('Error fetching courses:', error);
        setIsLoading(false);
      });
  }, []);

  const filteredCourses = filterCourses(courses, props.weeksAgo);


  return (
    <>
      <div className="w-full flex flex-col justify-center">
        {courses && !isLoading ? (
          <div className="m-8 flex flex-col gap-8">
            <div className="rounded-lg bg-white w-full" style={{ border: '1.5px solid #E2E8F0' }}>
              <div className="p-8 flex justify-between items-center">
                <Statistic title="Number of Courses" value={filteredCourses.length} />
                <Statistic title="Average Assignments per Course" value={Math.round(filteredCourses.reduce((acc, curr) => acc + curr.assignment_ids.length, 0) / filteredCourses.length)} />
              </div>
            </div>
            <div className="rounded-lg bg-white w-full" style={{ border: '1.5px solid #E2E8F0' }}>
              <Table
                pagination={{ pageSize: 100 }}
                columns={COLUMNS}
                dataSource={filteredCourses}
              />
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center w-full h-screen">
            <ReactLoading type={"spin"} color={"purple"} height={30} width={30} />
          </div>
        )}
      </div>
      <Modal title="Course Description" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>{currentDescription}</p>
      </Modal>
    </>
  );
};

export default CourseDashboard;