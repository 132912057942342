import React, { useEffect, useState } from 'react';
import { updateConversation } from '../api/conversation';
import { Button, Form, Input, Select } from "antd";

const SetupIBOral = ({
  conversation,
  setConversation,
  assignment
}) => {
  const [detailsForm] = Form.useForm();
  const [sourceType1, setSourceType1] = useState(null);
  const [sourceType2, setSourceType2] = useState(null);

  useEffect(() => {
    if (conversation && conversation.student_work) {
      detailsForm.setFieldsValue({
        "globalIssue": conversation.student_work.globalIssue,
        "source1": conversation.student_work.source1,
        "source2": conversation.student_work.source2,
        "excerpt1": conversation.student_work.excerpt1,
        "excerpt2": conversation.student_work.excerpt2,
      });
    }
  }, [conversation, detailsForm]);

  const submitHandler = async () => {
    try {
      const formValues = detailsForm.getFieldsValue(true);

      if (formValues.globalIssue && formValues.source1 && formValues.excerpt1 && formValues.source2 && formValues.excerpt2) {
        await updateConversation(conversation.id, {
          "student_work": {
            "globalIssue": formValues.globalIssue,
            "source1": formValues.source1,
            "excerpt1": formValues.excerpt1,
            "source2": formValues.source2,
            "excerpt2": formValues.excerpt2,
          },
        });

        setConversation({
          ...conversation,
          "student_work": {
            "globalIssue": formValues.globalIssue,
            "source1": formValues.source1,
            "excerpt1": formValues.excerpt1,
            "source2": formValues.source2,
            "excerpt2": formValues.excerpt2,
          }
        });
      }
    } catch (error) {
      console.error('Failed to submit form:', error);
    }
  };

  const sourceOptions = [
    { label: 'Literary Source (text)', value: 'literary' },
    { label: 'Non-literary Source (image or video)', value: 'non-literary' }
  ];

  return (
    <>
      <div className="justify-center items-center flex-col w-full h-full flex pt-96">
        <div className="text-left flex flex-col w-full">
          <div className="px-8 text-md text-xl font-bold">Task</div>
          <div className="px-8 text-sm">{assignment.task_prompt}</div>
        </div>

        <div className="flex flex-col gap-4 items-center justify-center mt-36 w-full">
          <Form
            form={detailsForm}
            layout="vertical"
            className="w-full px-8"
            onFinish={submitHandler}
            style={{ minHeight: '500px' }} // Increased the minimum height to make the form longer vertically
          >
            <Form.Item
              label="Paste your global issue."
              name="globalIssue"
              rules={[{ required: true, message: "Your issue can't be empty!" }]}
            >
              <Input.TextArea rows={4} maxLength={25000} style={{ resize: 'none' }} />
            </Form.Item>

            <Form.Item
              label="Select your first source type"
              name="sourceType1"
              rules={[{ required: true, message: "Please select a source type!" }]}
            >
              <Select options={sourceOptions} onChange={setSourceType1} />
            </Form.Item>

            {sourceType1 === 'literary' && (
              <>
                <Form.Item
                  label="Paste your literary source."
                  name="source1"
                  rules={[{ required: true, message: "Your source can't be empty!" }]}
                >
                  <Input.TextArea rows={4} maxLength={25000} style={{ resize: 'none' }} />
                </Form.Item>
                <Form.Item
                  label="Paste your extract."
                  name="excerpt1"
                  rules={[{ required: true, message: "Your excerpt can't be empty!" }]}
                >
                  <Input.TextArea rows={4} maxLength={25000} style={{ resize: 'none' }} />
                </Form.Item>
              </>
            )}

            {sourceType1 === 'non-literary' && (
              <>
                <Form.Item
                  label="What is the name of your source?"
                  name="source1"
                  rules={[{ required: true, message: "Your source name can't be empty!" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Describe the extract briefly."
                  name="excerpt1"
                  rules={[{ required: true, message: "Your description can't be empty!" }]}
                >
                  <Input.TextArea rows={4} maxLength={25000} style={{ resize: 'none' }} />
                </Form.Item>
              </>
            )}

            <Form.Item
              label="Select your second source type"
              name="sourceType2"
              rules={[{ required: true, message: "Please select a source type!" }]}
            >
              <Select options={sourceOptions} onChange={setSourceType2} />
            </Form.Item>

            {sourceType2 === 'literary' && (
              <>
                <Form.Item
                  label="Paste your literary source."
                  name="source2"
                  rules={[{ required: true, message: "Your source can't be empty!" }]}
                >
                  <Input.TextArea rows={4} maxLength={25000} style={{ resize: 'none' }} />
                </Form.Item>
                <Form.Item
                  label="Paste your extract."
                  name="excerpt2"
                  rules={[{ required: true, message: "Your excerpt can't be empty!" }]}
                >
                  <Input.TextArea rows={4} maxLength={25000} style={{ resize: 'none' }} />
                </Form.Item>
              </>
            )}

            {sourceType2 === 'non-literary' && (
              <>
                <Form.Item
                  label="What is the name of your source?"
                  name="source2"
                  rules={[{ required: true, message: "Your source name can't be empty!" }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Describe the extract briefly."
                  name="excerpt2"
                  rules={[{ required: true, message: "Your description can't be empty!" }]}
                >
                  <Input.TextArea rows={4} maxLength={25000} style={{ resize: 'none' }} />
                </Form.Item>
              </>
            )}

            <div className="w-full flex justify-end" style={{ paddingBottom: '10px' }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ background: '#9C1FFF' }}
              >
                Next
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default SetupIBOral

