import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import { getAssignment } from '../api/assignment';
import { SherpaContext } from '../utils/sherpaContext';
import { createConversation, getAllConversations, getConversation } from '../api/conversation';
import { compileCurrentTranscript } from '../api/videos';
import { lti_getStudentSubmission } from '../api/lti';
import { Button, Modal } from 'antd';
import { toast } from 'react-toastify';
import { AiOutlineLeft } from "react-icons/ai"
import { getRole } from '../utils/lti';
import { Steps as IntroSteps } from 'intro.js-react';
import { Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import AllowPermissionsModal from '../components/AllowPermissionsModal';

import ReactLoading from 'react-loading'

const INTRO_STEPS = [
{
    element: "#speak-on-it-modal",
    title: 'Student View 🧑🏻‍🎓',
    intro: 'This is what your students will see!',
    position: 'top'
  },
  {
    element: "#speak-on-it-modal",
    title: "What questions does Sherpa ask?",
    intro: <>
      Each conversation is unique and personalized to your student's work. Sherpa asks a range of questions, including <b>follow-up</b> questions that are unique to your student's responses.<br/>
      {/* <b>Probing Questions: </b> Questions that builds on top of a student's previous response, probing students to think deeper. <br/><br/>
      <b>Explanation of Specific Phrase, Argument, or Idea: </b> Students are asked to elaborate on what they mean about a specific phrase, or idea they mentioned. <br/><br/> */}
    </>
  }
]
const AssignmentConversation = (props) => {
  const {
      userToken,
      ltiToken,
      updateIntroStatus
    } = useContext(SherpaContext)
    
  // const [stepsEnabled, setStepsEnabled] = useState(!userToken?.details?.intro_status?.start_conversation && !ltiToken)
  const [withVideo, setWithVideo] = useState(false)
  const [stepsEnabled, setStepsEnabled] = useState(false)
  const [permission, setPermission] = useState(false)
  const [conversation, setConversation] = useState(null)
  const [conversationDetails, setConversationDetails] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [studentSubmission, setStudentSubmission] = useState(null)
  const [assignment, setAssignment] = useState(null)
  const [permissionModalOpen, setPermissionModalOpen] = useState(false)
  const [chatState, setChatState] = useState(null)


  // useEffect(()=>{
  //   if (!navigator.userAgent.includes('Safari')) {
  //     navigator.permissions.query({name:'microphone'}).then((result)=>{
  //       if (result.state === 'granted') {
  //         setPermission(true)
  //       } 
  //     })
  //   } else {
  //     setPermission(true)
  //   }

  // }, [])
  const { assignmentid } = useParams();

    useEffect(()=>{

        const init = async () => {
            // Get assignment
            const data = await getAssignment(assignmentid)
            setAssignment(data)

            // Set default video format
            setWithVideo(data.recording_format === "with_video")

            // Get conversations
            const convosData = await getAllConversations([assignmentid], 1, true, true, true, true) // only include main user = true, meaning that the conversations that appear here will ONLY be from the person who is logged in... (usually the teacher)
            const convos = convosData.conversations

            if (convos.length > 0) {
              convos[0]["id"] = convos[0]["_id"]
            }
            
            // Get the most recent conversation
            if (convos.length > 0){
              
              setConversation(convos[0])

              // Update video format
              setWithVideo(convos[0].with_video)
              
              // Get more details about it 
              setConversationDetails(await compileCurrentTranscript(convos[0]))
            }

            // LTI - only if there is an lti_assignment
            if (ltiToken && data.lti_assignment && getRole(ltiToken.token.user.roles) === "student"){
              const lti_assignment = data.lti_assignment; // get assignment id! 
              const courseid = ltiToken.token.launch.context.id;
              const submission = await lti_getStudentSubmission(courseid, ltiToken.token.user.id, lti_assignment.id)
              
              setStudentSubmission(submission)
            }

            setIsLoading(false)
        }
        
        init();
    }, [])

    useEffect(()=>{
      if (conversation) {
        
        console.log(ltiToken)
        console.log(conversation.student.uid)
        // console.log(userToken.user.uid)
        // console.log(userToken)
      }
    }, [conversation])
    
    const startConversation = async () => {
       if (chatState === "new"){
        const new_conversation = await createConversation(assignmentid, userToken.uid, ltiToken ? studentSubmission?.student_work : null, withVideo)
        props.history.push('/student/conversation/' + new_conversation.id) 
        
      } else if (chatState === "resume"){
        props.history.push('/student/conversation/' + conversation.id);
      }
      
    }

    return (
      <>
      <IntroSteps
        enabled={stepsEnabled}
        steps={INTRO_STEPS}
        initialStep={0}
        options={{
          doneLabel: "Done",
          exitOnOverlayClick: false,
          exitOnEsc: false,
          disableInteraction: true,
        }}
        onExit={()=>{
          setStepsEnabled(false)
        }}
        onComplete={async ()=>{
          await updateIntroStatus("start_conversation")
        }}
      />
      <AllowPermissionsModal isOpen={permissionModalOpen} setIsOpen={setPermissionModalOpen} withVideo={withVideo} onComplete={()=>{
        startConversation()
      }} onDecline={()=>{
        toast.error("You need to grant permissions!")
      }}/>
    <div className="relative h-full w-full"
      style={{
        fontFamily: 'Nunito'
      }}
    >
    {(ltiToken && getRole(ltiToken.token.user.roles).role === "teacher")||(userToken.details.role === "teacher") ? 
      <div className="absolute top-1 left-1 flex items-center p-4 gap-2 text-gray-600 cursor-pointer" onClick={()=>{
            props.history.goBack();
          }}>
            <AiOutlineLeft />
            Back
          </div>
      : null
    }
    <div className="flex justify-center items-center w-full h-full flex-col gap-4">
      <div className="w-full flex gap-8 items-center justify-center flex-col soft-shadow relative" id="speak-on-it-modal">
          {!isLoading ?
              <>
              {/* New! */}
                {!conversation ? 
                  <>
                  {assignment.recording_format && assignment.recording_format === "only_audio" ? 
                        <div className="text-gray-400 absolute top-8 gap-4 flex items-center justify-center">This conversation is audio only.</div>
                    : assignment.recording_format && assignment.recording_format === "with_video" ? 
                      <div className="text-gray-400 absolute top-8 gap-4 flex items-center justify-center">This conversation is video-enabled.</div> 
                    : 
                       <div className="absolute top-8 gap-4 flex items-center justify-center">
                        <span className="text-gray-500">Video On?</span>
                        <Switch
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          onChange={(value)=>{
                            setWithVideo(value)
                          }}
                        />  
                      </div>
                    }
                    {/* No Submission */}
                    {studentSubmission && studentSubmission.status === "no_submission" ? 
                      <div className="text-center flex flex-col gap-4">
                          <div className="text-md text-3xl font-bold">You have not submitted your assignment yet.</div>
                          <div className="text-md text-gray-500 text-lg">Please submit "{assignment && assignment.lti_assignment.title}" before you <b>Speak On It!</b></div>
                      </div>
                    : 
                    // No LTI or Submission! 
                    <>
                      {assignment.assignment_type === "Student-Based Assignment" ? 
                        <>
                          <div className="text-center flex flex-col gap-4">
                              <div className="text-md text-3xl font-bold">{studentSubmission ? <>Hi! Great work on {assignment.lti_assignment.title}!</> : <>Hi! Great work so far!</>}</div>
                              <div className="text-md text-gray-500 text-lg" id="10minlabel">Are you ready for a <b>{Math.round((assignment.conversation_flow.length * 1.5) - 1.5)}-min chat</b> about your work?</div>
                          </div>
                        </>
                      : assignment.assignment_type === "Reading Responses" ? 
                      <>
                        <div className="text-center flex flex-col gap-4">
                            <div className="text-md text-3xl font-bold">Hi! Are you ready to speak on the reading?</div>
                            <div className="text-md text-gray-500 text-lg">{assignment.external_source_title}</div>
                        </div>
                      </>
                      : assignment.assignment_type === "IB Oral" ?
                      <>
                        <div className="text-center flex flex-col gap-4">
                            <div className="text-md text-3xl font-bold">Hi! When you are ready, please press start and begin your IB oral. There will then be a series of questons about your response?</div>
                        </div>
                      </>
                      : null } 
                      <Button type="primary" onClick={async ()=>{
                        setPermissionModalOpen(true)  
                        setChatState("new")
                      }} style={{ background: '#9C1FFF'}}>Yes!</Button>
                    </>
                    }
                  </>
                    : 
                    // Resume
                    conversation && conversationDetails && conversation.evals.is_complete === false ? 
                    <>
                      
                        {conversation.with_video ? <div className="text-gray-400 absolute top-8 gap-4 flex items-center justify-center">This conversation is video-enabled.</div> : <div className="text-gray-400 absolute top-8 gap-4 flex items-center justify-center">This conversation is audio only.</div>}
                        <div className="text-center flex flex-col gap-4">
                          <div className="text-md text-3xl font-bold">You are in the middle of a conversation!</div>
                          <div className="text-md text-gray-500 text-md">This conversation will likely take another {(assignment.conversation_flow.length * 1.5) - (conversationDetails.num_videos * 1.5) - 1.5} minutes!</div>

                        </div>
                          <Button type="primary" htmlType='submit' style={{ background: '#9C1FFF'}} onClick={async ()=>{
                            if (!permission) {
                              setPermissionModalOpen(true)  
                              setChatState("resume")
                            } else {
                              props.history.push('/student/conversation/' + conversation.id);
                            }
                          }}>Resume</Button>
                    </>
                    // You're done.
                    : conversation && conversationDetails && conversation.evals.is_complete === true ? 
                      <>
                        <div className="text-center flex flex-col gap-4">
                          <div className="text-md text-3xl font-bold">You've already spoken about this assignment!</div>
                        </div>
                      </>
                    : null
                    }
              </>
          : 
          <>
              <ReactLoading
                  type={"spin"}
                  color={"purple"}
                  height={30}
                  width={30}
              />
          </>
          }
          
      </div>
    </div>
    </div>
    </>
  );
}

export default AssignmentConversation;
