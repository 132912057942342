import axios from "axios";
import { getAuth, getIdToken } from "firebase/auth";
import { toast } from "react-toastify";
import { reportBug } from "./bugreports";

const BACKEND_API_URL = process.env.REACT_APP_BACKEND_URL;

export const getAuthToken = async () => {
  const user = await getAuth().currentUser;

  const token = await getIdToken(user);

  return token;
};

const SherpaClient = axios.create({
  baseURL: BACKEND_API_URL,
});

// Add authorization token
SherpaClient.interceptors.request.use(
  async (config) => {
    const token = await getAuthToken();

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    toast.error("Request failed on client side.", {
      autoClose: false,
    });
    return Promise.reject(error);
  }
);

// Error handling
SherpaClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    let errorMessage;
    try {
      // console.log(error);
      toast.error("Error occurred. Please refresh the page!", {
        autoClose: false,
      });
      await reportBug(error.response.data.detail, "axios_intercept");
    } catch (e) {
      toast.error("Request failed. Unknown error.", {
        autoClose: false,
      });
    }

    return Promise.reject(errorMessage);
  }
);

export default SherpaClient;
