import React, { useEffect, useState } from 'react';
import ReactLoading from "react-loading";
import { Table, Statistic } from 'antd';
import { getTeachers } from '../api/adminNew';
import { convertUtcToNow, convertTimeToTimeAgo } from '../utils/general';

const TeacherDashboard = (props) => {
  const COLUMNS = [
    {
      title: 'Signed up',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at) => created_at ? convertTimeToTimeAgo(convertUtcToNow(created_at)) : "",
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: "Number of Assignments",
      dataIndex: 'num_assignments',
      key: 'num_assignments'
    },
    {
      title: "Number of Classes",
      dataIndex: 'num_courses',
      key: 'num_courses'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Source',
      dataIndex: 'source_reference',
      key: 'source_reference'
    }
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [teachers, setTeachers] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getTeachers().then(response => {
      if (response.status === 200) {
        console.log('Teachers:', response.data);
        setTeachers(response.data);
      } else {
        console.error('Error fetching teachers:', response);
      }
      setIsLoading(false);
    }).catch(error => {
      console.error('Error fetching teachers:', error);
      setIsLoading(false);
    });
  }, []);

  const filterTeachers = (teachers, weeksAgo) => {
    if (weeksAgo === 0) return teachers; // All time option

    const now = new Date();
    return teachers.filter(teacher => {
      const createdAt = new Date(teacher.created_at);
      const weeksDifference = (now - createdAt) / (1000 * 60 * 60 * 24 * 7);
      return weeksDifference <= weeksAgo;
    });
  };

  const filteredTeachers = filterTeachers(teachers, props.weeksAgo);

  return (
    <div className="w-full flex flex-col justify-center">
      {isLoading ? (
        <div className="flex justify-center items-center w-full h-screen">
          <ReactLoading type={"spin"} color={"purple"} height={30} width={30} />
        </div>
      ) : (
        <div className="m-8 flex flex-col gap-8">
          <div className="rounded-lg bg-white w-full" style={{ border: '1.5px solid #E2E8F0' }}>
            <div className="p-8 flex justify-between items-center">
              <Statistic title="Number of Teachers" value={teachers.filter(teacher => teacher.role === "teacher").length} />
              <Statistic title="Number of Assignments" value={teachers.reduce((total, teacher) => total + (teacher.num_assignments), 0)} />
              <Statistic title="Number of Classes" value={teachers.reduce((total, teacher) => total + (teacher.num_courses), 0)} />
              <Statistic title="Number of Bug Reports" value={teachers.reduce((total, teacher) => total + (teacher.bug_reports ? teacher.bug_reports.length : 0), 0)} />
            </div>
          </div>
          <div className="rounded-lg bg-white w-full" style={{ border: '1.5px solid #E2E8F0' }}>
            <Table
              pagination={{ pageSize: 100 }}
              columns={COLUMNS}
              dataSource={filteredTeachers}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default TeacherDashboard;
