import React, { useState, useEffect } from 'react';
import ReactLoading from "react-loading";
import { Button, Table, Modal, Form, Input, Statistic, message } from 'antd';
import { limitLength } from '../utils/general';
import { getAdmins, addAdmin, removeAdmin } from '../api/adminNew';
import { toast } from 'react-toastify';

const SuperAdminDashboard = (props) => {
  const COLUMNS = [
    {
      title: 'Admin Name',
      dataIndex: 'name',
      key: 'name',
      render: (name) => {
        return limitLength(name, 50);
      }
    },
    {
      title: 'Institute Name',
      dataIndex: 'institute',
      key: 'institute_name',
      render: (institute_name) => {
        return limitLength(institute_name, 50);
      }
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
      render: (domain) => {
        return limitLength(domain, 50);
      }
    },
    {
      title: 'Admin Email',
      dataIndex: 'email',
      key: 'email',
      render: (email) => {
        return limitLength(email, 50);
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button type="default" danger onClick={() => handleRemove(record.uid)}>
          Remove
        </Button>
      )
    }
  ];

  const [Admins, setAdmins] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const fetchAdmins = async () => {
    setIsLoading(true);
    try {
      const response = await getAdmins();
      console.log('Admins:', response.data)
      setAdmins(response.data);
    } catch (error) {
      console.error('Failed to fetch admins:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAdmins();
  }, []);

  const handleRemove = (id) => {
    Modal.confirm({
      title: 'Are you sure you want to remove this admin?',
      onOk: () => {
        removeAdmin(id).then(response => {
          if (response.status === 200) {
            toast.success('Admin removed successfully');
            fetchAdmins();
          } else {
            toast.error('Failed to remove admin');
          }
        });
      }
    });
  };

  const handleAddAdmin = () => {
    form.validateFields()
      .then(values => {
        const newAdmin = {
          institute: values.institute_name,
          email: values.email,
        };
        addAdmin(newAdmin).then(response => {
          if (response.status === 200) {
            toast.success('Admin added successfully');
            fetchAdmins();
            setIsModalVisible(false);
            form.resetFields();
          } else {
            toast.error('Failed to add admin');
          }
        } );

      })
      .catch(info => {
        console.error('Validate Failed:', info);
      });
  };

  const uniqueInstitutes = [...new Set(Admins.map(admin => admin.institute_name))];

  return (
    <>
    <div className="w-full flex flex-col justify-center">
    {isLoading ?
      <div className="flex justify-center items-center w-full h-screen">
      <ReactLoading
        type={"spin"}
        color={"purple"}
        height={30}
        width={30}
      />
      </div>
      :
      <>
      <div className="m-8 flex flex-col gap-8">
        <div className="flex justify-between items-center">
          <div className="flex gap-8">
            <Statistic title="Number of Admins" value={Admins.length} />
            <Statistic title="Number of Institutions" value={uniqueInstitutes.length} />
          </div>
          <Button type="primary" onClick={() => setIsModalVisible(true)}>
            Add New Admin
          </Button>
        </div>
        <div className="rounded-lg bg-white w-full" style={{border: '1.5px solid #E2E8F0'}}>
          <Table 
            pagination={{ pageSize: 100 }}  
            columns={COLUMNS} 
            dataSource={Admins} 
            rowKey="_id"
          />
        </div>
      </div>
      <Modal
        title="Add New Admin"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={handleAddAdmin}
      >
        <Form form={form} layout="vertical" name="add_admin_form">
          <Form.Item
            name="email"
            label="Admin Email"
            rules={[{ required: true, message: 'Please input the admin email!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="institute_name"
            label="Institute Name"
            rules={[{ required: true, message: 'Please input the institute name!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      </>
    }
    </div>
  </>
  );
};

export default SuperAdminDashboard;
