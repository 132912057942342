import React, { useEffect, useState } from 'react';
import ReactLoading from "react-loading";
import { Button, Tag, Table, Statistic } from 'antd';
import { getAssignments } from '../api/adminNew';
import { getPresignedUrl } from '../api/s3';
import { convertUtcToNow, convertTimeToTimeAgo, limitLength } from '../utils/general';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const AssignmentDashboard = (props) => {
  const { courseId } = useParams();
  const location = useLocation();
  const [course, setCourse] = useState({});
  // const { title, subject, grade } = location.state || {};

  const COLUMNS = [
  {
    title: 'Created At',
    dataIndex: 'created_at',
    key: 'created_at',
    render: (created_at) => {
      created_at = convertUtcToNow(created_at);
      return convertTimeToTimeAgo(created_at)
    }
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    render: (title) => {
      return limitLength(title, 50)
    }
  },
  {
    title: 'Created By',
    dataIndex: 'user',
    key: 'user',
    render: (user) => {
      return <span>{limitLength(user.name, 50)} <br /> {limitLength(user.email, 50)}</span>
    }
  },
  {
    title: 'Grade',
    dataIndex: 'grade',
    key: 'grade',
    render: (grade) => {
      return grade
    }
  },
  {
    title: 'Subject',
    dataIndex: 'subject',
    key: 'subject',
    render: (subject) => {
      return subject
    }
  },
  {
    title: 'Assignment Type',
    dataIndex: 'assignment_type',
    key: 'assignment_type',
    render: (assignment_type) => {
      return assignment_type === "Student-Based Assignment" ? <Tag color="blue">SBA</Tag> : <Tag color="orange">READING</Tag>
    }
  },
  // {
  //   title: 'Concepts',
  //   dataIndex: 'conversation_flow',
  //   key: 'conversation_flow',
  //   render: (conversation_flow) => {
  //     return <span>{conversation_flow.map(question => {
  //       return question.type === "base" && question.concept ? (
  //         <p>{question.concept}</p>
  //       ) : null
  //     })}</span>
  //   }
  // },
  {
    title: 'File',
    dataIndex: 'file_name',
    key: 'file_name',
    render: (file_name) => {
      return file_name ? <Button onClick={async ()=>{
        window.location.href = await getPresignedUrl(file_name)
      }}>PDF</Button> : null
    }
  },
  {
    title: 'Num Conversations',
    dataIndex: 'num_conversations',
    key: 'num_conversations',
  },
  
]
  
  const [assignments, setAssignments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAssignments = async () => {
      setIsLoading(true);
      try {
        const response = await getAssignments(courseId);
        if (response.status === 200) {
          setAssignments(response.data.assignments);
          setCourse(response.data.course);
        } else {
          console.error('Error fetching assignments:', response);
        }
      } catch (error) {
        console.error('Error fetching assignments:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAssignments();
  }, [courseId]);

  const filterAssignments = (assignments, weeksAgo) => {
    if (weeksAgo === 14) return assignments; // All time option
  
    const now = new Date();
    return assignments.filter(assignment => {
      const createdAt = new Date(assignment.created_at);
      const daysDifference = Math.floor((now - createdAt) / (1000 * 60 * 60 * 24));
      const weeksDifference = Math.floor(daysDifference / 7);

      return weeksDifference + 1 === weeksAgo;
    });
  };
  const filteredAssignments = filterAssignments(assignments, props.weeksAgo);

  return (
    <>
      <div className="w-full flex flex-col justify-center">
        {course && (
          <div className="m-8 flex flex-col gap-8">
            <div className="rounded-lg bg-white w-full" style={{ border: '1.5px solid #E2E8F0' , marginBottom: '-35px'}}>
              <div className="p-8 flex justify-between items-center">
                <Statistic title="Course Title" value={course.title} />
                <Statistic title="Subject" value={course.subject} />
                <Statistic title="Grade" value={course.grade} />
              </div>
            </div>
          </div>
        )}
        {assignments && !isLoading ? (
          <div className="m-8 flex flex-col gap-8">
            <div className="rounded-lg bg-white w-full" style={{ border: '1.5px solid #E2E8F0' }}>
              <div className="p-8 flex justify-between items-center">
                <Statistic title="Number of Assignments" value={filteredAssignments.length} />
                <Statistic title="% Student-Based Assignments" value={Math.round(filteredAssignments.filter(item => item.assignment_type === "Student-Based Assignment").length / filteredAssignments.length * 100)} />
              </div>
            </div>
            <div className="rounded-lg bg-white w-full" style={{ border: '1.5px solid #E2E8F0' }}>
              <Table 
                pagination={{ pageSize: 100 }}  
                columns={COLUMNS} 
                dataSource={filteredAssignments} 
                onRow={(record) => ({
                  onClick: () => {
                    props.history.push(`/teacher/assignment/${record._id}`);
                  },
                })}
              />
            </div>
          </div>
        ) : (
          <div className="flex justify-center items-center w-full h-screen">
            <ReactLoading type={"spin"} color={"purple"} height={30} width={30} />
          </div>
        )}
      </div>
    </>
  );
};

export default AssignmentDashboard;