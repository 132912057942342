import React, { useContext, useEffect, useState } from "react";
import { Modal, Form, Input, Button, message, Select } from "antd";
import { SherpaContext } from "../utils/sherpaContext";
import { updateUser } from "../api/user"; // API calls for user data
import { addTeacherToCourse } from "../api/course";
import { toast } from "react-toastify";
const { Option } = Select;

const AddTeacher = ({ isOpen, setIsOpen, courseId }) => {
  const { userToken } = useContext(SherpaContext);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const payload = { ...values }; // Include courseId in the payload
    addTeacherToCourse(courseId, payload)
      .then((data) => {
        toast.success("Invitation sent successfully");
        setIsOpen(false);
        form.resetFields();
      })
      .catch((error) => {
        toast.error("Failed to add teacher to course");
      });
  };

  return (
    <Modal
      title="Add Teacher"
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={null}
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input the email of the teacher",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Permissions"
          name="permissions"
          rules={[
            { required: true, message: "Please select a permission level" },
          ]}
        >
          <Select mode="multiple">
            <Option value="admin_access">
              Full administrative access to the course
            </Option>
            <Option value="read_access">
              Permission to read course content (But not assignments or
              submissions)
            </Option>
            <Option value="view_submissions">
              Permission to view student submissions
            </Option>
            <Option value="add_assignments">
              Permission to add new assignments
            </Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddTeacher;
