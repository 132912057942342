import React, { useContext, useEffect, useState } from 'react'
import Chat from './Chat'
import Setup from './Setup'
import SetupIBOral from './SetupIBOral'
import { SherpaContext } from '../utils/sherpaContext'
import { useParams } from 'react-router-dom'
import { getConversation } from '../api/conversation'
import ReactLoading from 'react-loading'
import { AiOutlineLeft } from "react-icons/ai";
import { getRole } from '../utils/lti'
import { Button } from 'antd'
import BugReportModal from '../components/BugReportModal'


const StudentMain = (props) => {

  const sherpaContext = useContext(SherpaContext)
  const [bugModal, setBugModal] = useState(false)
  const [conversation, setConversation] = useState(null)
  const [assignment, setAssignment] = useState(null)
  const { ltiToken, userToken, updateGettingStartedStatus } = sherpaContext
  const { conversationid } = useParams();

  // Get Conversation
  useEffect(() => {
    const setupConversation = async () => {
      const data = await getConversation(conversationid, {
        "videos": false,
        "evals": false,
        "student": false
      })

      if (data.assignment.id === userToken.details?.getting_started?.create_reading_assignment) {
        updateGettingStartedStatus("main_conversation", conversationid)
      }

      setAssignment(data.assignment)
      setConversation(data.conversation);
    }

    if (!conversation) {
      setupConversation();
    }

  }, []);


  return (
    <>
      <BugReportModal isOpen={bugModal} source="Chat" setIsOpen={setBugModal} />
      <div className="absolute top-1 right-1 text-right flex items-center p-4 gap-2 text-gray-600 cursor-pointer" style={{
        animation: 'fadeIn 2s',
        zIndex: 9
      }}>
        <Button onClick={() => {
          setBugModal(true)
        }}>
          Report Bug
        </Button>
      </div>
      <div className="w-full h-full relative" style={{
        fontFamily: 'Nunito'
      }}>

        {(ltiToken && getRole(ltiToken.token.user.roles).role === "teacher") || (userToken.details.role === "teacher") ?
          <>
            <div className="absolute top-1 left-1 flex items-center p-4 gap-2 text-gray-600 cursor-pointer" onClick={() => {
              props.history.goBack();
            }}>
              <AiOutlineLeft />
              Back
            </div>
          </>
          : null
        }
        <div className="flex justify-center items-center w-full h-full flex-col gap-4">
          <div className="relative w-full flex-col soft-shadow flex items-center justify-center overflow-scroll" id="speak-on-it-modal" style={{
            height: conversation && conversation.with_video ? '397.4px' : '500px'
          }}>
            {
              !conversation || !assignment ?
                <ReactLoading
                  type={"spin"}
                  color={"purple"}
                  height={30}
                  width={30}
                />
                : assignment.assignment_type === "Reading Responses" ?
                  <Chat conversation={conversation} setConversation={setConversation} assignment={assignment} history={props.history} />
                  : assignment.assignment_type === "Student-Based Assignment" ?
                    <>
                      {!conversation.student_work ?
                        <Setup conversation={conversation} setConversation={setConversation} assignment={assignment} />
                        : <Chat conversation={conversation} setConversation={setConversation} assignment={assignment} history={props.history} />
                      }
                    </>
                    : assignment.assignment_type === "IB Oral" ?
                      <>
                        {!conversation.student_work ?
                          <SetupIBOral conversation={conversation} setConversation={setConversation} assignment={assignment} />
                          : <Chat conversation={conversation} setConversation={setConversation} assignment={assignment} history={props.history} />
                        }
                      </>
                      : null
            }
          </div>
        </div>
      </div>
    </>)
}

export default StudentMain;


