import React, { useContext, useState, useEffect } from "react";
import { Table, Input, Row, Col, Typography, Tag, Statistic } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import ReactLoading from "react-loading";
import { useParams } from "react-router-dom";
import { getParticipants } from "../api/adminNew";
import { SherpaContext } from "../utils/sherpaContext";

const ParticipantsDashboard = (props) => {
  const { courseId } = useParams();
  const { userToken } = useContext(SherpaContext);

  const [participants, setParticipants] = useState({ teachers: [], associate_teachers: [], students: [] });
  const [course, setCourse] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    fetchParticipants();
  }, [courseId]);

  const fetchParticipants = async () => {
    try {
      setIsLoading(true);
      const participantData = await getParticipants(courseId);
      if (participantData["associate_teachers"] === undefined) {
        participantData["associate_teachers"] = [];
      }
      setParticipants({ teachers: participantData["teachers"], associate_teachers: participantData["associate_teachers"], students: participantData["students"] });
      setCourse(participantData.course);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredParticipants = [
    ...participants.teachers.map(p => ({ ...p, role: 'Teacher' })),
    ...participants.associate_teachers.map(p => ({ ...p, role: 'Associate Teacher' })),
    ...participants.students.map(p => ({ ...p, role: 'Student' }))
  ].filter((participant) =>
    participant.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const COLUMNS = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (role) => {
        let color = '';
        switch (role) {
          case 'Teacher':
            color = 'blue';
            break;
          case 'Associate Teacher':
            color = 'green';
            break;
          case 'Student':
            color = 'purple';
            break;
          default:
            color = 'default';
        }
        return <Tag color={color}>{role}</Tag>;
      },
    },
  ];

  return (
    <>
      {isLoading ? (
        <div className='flex justify-center items-center w-full h-screen'>
          <ReactLoading type={"spin"} color={"purple"} height={30} width={30} />
        </div>
      ) : (
        <div className="w-full flex flex-col justify-center">
          {course && (
          <div className="m-8 flex flex-col gap-8">
            <div className="rounded-lg bg-white w-full" style={{ border: '1.5px solid #E2E8F0' , marginBottom: '-35px'}}>
              <div className="p-8 flex justify-between items-center">
                <Statistic title="Course Title" value={course.title} />
                <Statistic title="Subject" value={course.subject} />
                <Statistic title="Grade" value={course.grade} />
              </div>
            </div>
          </div>
        )}
          <div className="m-8 flex flex-col gap-8">
            <Input
              placeholder="Search participants..."
              prefix={<SearchOutlined />}
              onChange={handleSearchChange}
              value={searchTerm}
              style={{
                marginBottom: 20,
                marginLeft: 25,
                marginRight: 25,
                width: "60%",
              }}
            />
            <div className="rounded-lg bg-white w-full" style={{ border: '1.5px solid #E2E8F0' }}>
              <Table
                pagination={{ pageSize: 100 }}
                columns={COLUMNS}
                dataSource={filteredParticipants}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ParticipantsDashboard;
