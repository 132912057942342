import SherpaClient from "./main";

// Get all courses
export const getCourses = async (addAssignment = false) => {
  const response = await SherpaClient.get(
    `courses?add_assignment=${addAssignment}`
  );
  return response.data;
};

// Create a new course
export const createCourse = async (course) => {
  const response = await SherpaClient.post("courses/create_course", course);
  return response.data;
};

// Join a course
export const joinCourse = async (course_code) => {
  const response = await SherpaClient.post(`/courses/join/${course_code}`);
  return response.data;
};

// Get course by course_id
export const getCourseById = async (course_id) => {
  const response = await SherpaClient.get(`/courses/${course_id}`);
  return response.data;
};

// Edit course by course_id
export const editCourse = async (course_id, course) => {
  const response = await SherpaClient.put(`/courses/${course_id}`, course);
  return response.data;
};

// Delete course by course_id
export const deleteCourse = async (course_id) => {
  const response = await SherpaClient.delete(`/courses/${course_id}`);
  return response;
};

// reset joining code
export const resetJoiningCode = async (course_id) => {
  const response = await SherpaClient.get(
    `/courses/${course_id}/reset_joining_code`
  );
  return response;
};

// Get all assignments in a course
export const getCourseAssignments = async (course_id) => {
  const response = await SherpaClient.get(`/courses/${course_id}/assignments`);
  return response.data;
};

// Add assignment to another course
export const addAssignmentToCourse = async (
  course_id,
  assignment_id,
  requestBody
) => {
  const response = await SherpaClient.put(
    `/courses/${course_id}/${assignment_id}/copy`,
    requestBody
  );
  return response.data;
};

// Get all participants in a course
export const getCourseParticipants = async (course_id) => {
  const response = await SherpaClient.get(`/courses/${course_id}/participants`);
  return response.data;
};

// Create a new assignment for a course
export const createCourseAssignment = async (requestBody) => {
  const response = await SherpaClient.post(
    `/courses/create_assignment`,
    requestBody
  );
  return response.data;
};

// Get course submissions
export const getCourseSubmissions = async (course_id) => {
  const response = await SherpaClient.get(
    `/courses/${course_id}/conversations`
  );
  return response.data;
};

// Get assignment evaluations
export const getAssignmentEvaluations = async (course_id, assignment_id) => {
  const response = await SherpaClient.get(
    `/courses/${course_id}/${assignment_id}/submissions`
  );
  return response.data;
};

// Get all announcements for a course
export const getCourseAnnouncements = async (course_id) => {
  const response = await SherpaClient.get(
    `/courses/${course_id}/announcements`
  );
  return response.data;
};

// Create a new announcement for a course
export const createCourseAnnouncement = async (course_id, requestBody) => {
  const response = await SherpaClient.post(
    `/courses/${course_id}/create_announcement`,
    requestBody
  );
  return response.data;
};

export const addTeacherToCourse = async (course_id, requestBody) => {
  const response = await SherpaClient.post(
    `/courses/${course_id}/add_teacher`,
    requestBody
  );
  return response;
};

export const getNotifications = async () => {
  const response = await SherpaClient.get("/notifications");
  return response.data;
};

export const markNotification = async (requestBody) => {
  const response = await SherpaClient.post(
    `/notifications/update/`,
    requestBody
  );
  return response;
};
