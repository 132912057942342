import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Card, Select, Tooltip } from 'antd';
import ExperimentDB from './experiment_db_2.json'
import { getExperimentCount, submitExperiment } from '../api/experiment'
import InfoModal from '../components/InfoModal';

const Experiment = (props) => {
    const [ExperimentForm] = Form.useForm();
    const [experimentCount, setExperimentCount] = useState(null);
    const [question, setQuestion] = useState(null);
    const [infoModalOpen, setInfoModalOpen] = useState(false)

    useEffect(() => {
        getExperimentCount().then(count => {
            setExperimentCount(count.count);
            setQuestion(ExperimentDB[count.count])
        });
    }, []);

    const onFinish = async (values) => {
        await submitExperiment({
            ...values, 
            "id": question['_id'],
            "assignment_id": question['assignment._id']
        })
        getExperimentCount().then(count => {
            setExperimentCount(count.count);
            setQuestion(ExperimentDB[count.count])
        });
        ExperimentForm.resetFields();
    }

    const handleConfidenceClick = (confidence) => {
        ExperimentForm.setFieldsValue({ confidence });
        ExperimentForm.submit();
    }

    return question && (
        <>
            <InfoModal 
            isOpen={infoModalOpen}
            setIsOpen={setInfoModalOpen}
            title="Reading"
            main={
                <div>
                    {(question['assignment.original_text']).split("\n").map(para => (
                        <p>{para}</p>
                    ))}
                </div>
            }
            />
             <div className="flex w-full h-screen justify-center items-center">
            
             <Card className="w-[800px] my-64">
                <div className="flex justify-between items-center w-full">
                    <span className="text-xs">{question["assignment.title"]} | Grade {question['assignment.grade']} | {question['assignment.subject']}</span>
                    <span><Button onClick={()=>{
                        setInfoModalOpen(true)
                    }}>View Reading</Button> <Tooltip title="If reading looks off, it is likely a PDF which you can find here"><Button 
                    type="link" 
                    onClick={() => window.open('https://drive.google.com/file/d/1bx3cTfVPrvacfrgBm0TDWa6X3_TyO6-t/view?usp=sharing', '_blank')}
                >
                    Download PDFs
                </Button></Tooltip> </span>
                   
                </div>
                 <div className="text-3xl font-bold text-center my-16">{experimentCount+1}/236</div>
                 
                 <p><b>Question: </b> {question["question"]}</p>
                 <p><b>Student Answer: </b> {question["improved_response"]}</p>

                 <br/>
                 <Form
                    form={ExperimentForm}
                    layout="vertical"
                    className="w-full"
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={<span style={{ fontSize: '1.2em' }}>What is your evaluation?</span>}
                        name="categorization"
                        rules={[
                            {
                                required: true,
                                message: "Please select something!",
                            },
                        ]}
                    >
                        <Select>
                            <Select.Option value="verified">Pass</Select.Option>
                            <Select.Option value="off-topic">Off-Topic</Select.Option>
                            <Select.Option value="factual-wrong">Factually Incorrect</Select.Option>
                            <Select.Option value="missing-elements">Insufficient Detail / Depth</Select.Option>
                        </Select>
                    </Form.Item>
                        <Form.Item
                            label={<span style={{ fontSize: '1.2em' }}>Briefly, explain why (if question is Verified, and clearly well-answered, you can SKIP this).</span>}
                            name="reason"
                            rules={[
                                {
                                    required: true,
                                    message: "Please provide a reason!",
                                    validator: (_, value) => {
                                        if (ExperimentForm.getFieldValue('categorization') !== 'verified' && !value) {
                                            return Promise.reject(new Error('Please provide a reason!'));
                                        }
                                        return Promise.resolve();
                                    },
                                },
                            ]}
                            dependencies={['categorization']}
                        >
                            <Input.TextArea rows={4} placeholder='Missing crucial point about how ozone layers do not work.'/>
                        </Form.Item>
                    
                    <Form.Item name="confidence" hidden>
                        <Input />
                    </Form.Item>
                    
                    <div className="w-full justify-center items-center flex gap-4">
                        <Button type="primary" style={{ background: '#dcb2ff' }} onClick={() => handleConfidenceClick('not confident')}>I'm not that confident</Button>
                        <Button type="primary" style={{ background: '#c175ff' }} onClick={() => handleConfidenceClick('somewhat confident')}>I'm somewhat confident</Button>
                        <Button type="primary" style={{ background: '#9C1FFF' }} onClick={() => handleConfidenceClick('confident')}>I'm confident</Button>
                    </div>
                </Form>
            </Card>
        </div>
        </>
    )

};

export default Experiment;
