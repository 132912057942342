import React, { useContext, useState, useEffect } from 'react';
import { lti_getAssignments, lti_submitDeepLink } from '../api/lti';
import { createAssignment, createAssignmentV2 } from '../api/assignment'
import { createCourseAssignment, getCourses } from '../api/course'
import { useParams } from 'react-router-dom'
import { Form, Input, Button, Select, Space, Modal } from 'antd'
import ScrollFade from "@benestudioco/react-scrollfade";
import { Steps, Collapse, Upload, Radio, InputNumber } from 'antd';
import { toast } from 'react-toastify';
import { RaceBy } from '@uiball/loaders'
import { AiFillFilePdf } from 'react-icons/ai';
import ReactLoading from 'react-loading'
import { IoDocumentTextOutline, IoBook } from "react-icons/io5"
import { Card } from 'antd'
import { uploadToS3 } from '../api/s3';
import { Steps as IntroSteps } from 'intro.js-react';
import { SherpaContext } from '../utils/sherpaContext';
import { updateUser } from '../api/user';
import { addToWaitlist } from '../api/waitlist';
import TuneConvoModal from '../components/TuneConvoModal'

import { GlobalWorkerOptions, getDocument } from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

GlobalWorkerOptions.workerSrc = pdfjsWorker;

// const WORD_COUNT = 100000
const WORD_COUNT = 10000

const INTRO_STEPS = [
  //  {
  //   element: "#report-bug-button",
  //   "title": "We are still a work in progress!",
  //   intro: <>
  //     <Alert type="warning" message={
  //     <>Sherpa is currently in <b>Closed Beta</b>. <br/> <br/>You may experience bugs & issues when using the app. Please be patient with us. <br/><br/>We would appreciate if you could report any bugs with the button on the left of the screen!</>}
  //     />
  //   </>
  // },
  {
    element: "#main_chat",
    title: "Create New Assignment",
    intro: <>Let's start by designing our first conversation. <br /> <br /> There are three main types of conversation: <br /><br />You can gauge a student's understanding of their own work, whether it be an essay, research paper, or lab report. <br /><br />Alternatively, you can gauge a student's understanding of a reading, article or external source.<br /><br /> Lastly, you can have them take an IB Oral exam</>
  }
]

const STEPS = [
  {
    title: 'Create Assignment',
  },
  {
    title: 'Setup Assignment',
  },
  {
    title: 'Design Conversation',
  },
  {
    title: 'Try it!',
  },
]
const TeacherCreate = (props) => {
  const { userToken, setUserToken, ltiToken, updateIntroStatus } = useContext(SherpaContext)
  const { courseId } = useParams();
  // const [stepsEnabled, setStepsEnabled] = useState(!userToken?.details?.intro_status?.create  && !ltiToken)
  const [ltiAssignments, setLtiAssignments] = useState(null)
  const [stepsEnabled, setStepsEnabled] = useState(false)
  const [step, setStep] = useState(0)
  const [assignmentType, setAssignmentType] = useState(null);
  const [externalFormat, setExternalFormat] = useState(null);
  const [assignmentCreationForm] = Form.useForm();
  const [designConversationForm] = Form.useForm();
  const [tuneType, setTuneType] = useState(null)
  const [isCreating, setIsCreating] = useState(false)
  const [featureRequestModalOpen, setFeatureRequestModalOpen] = useState(false)
  const [limited, setLimited] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [timeLimit, setTimeLimit] = useState(false)
  const [format, setFormat] = useState("pdf")
  const [fileData, setFileData] = useState(null)
  const [totalPages, setTotalPages] = useState(null)
  const [startPage, setStartPage] = useState(null)
  const [endPage, setEndPage] = useState(null)
  const [courses, setCourses] = useState([])

  const onSubmitStep1 = async (values) => {
    if (values["file"]) {
      if (fileData) {
        const wordCount = (fileData?.slice(startPage - 1, endPage).join("\n").split(" ")).length - 1
        if (wordCount < 50) {
          toast.error("The uploaded reading does not contain enough words.")
        } else if (wordCount > WORD_COUNT) {
          Modal.confirm({
            title: `The reading exceeds the ${WORD_COUNT} word limit. Only the first ${WORD_COUNT} words will be used.`,
            onOk: async () => {
              setStep(2)
            },
          });
        } else {
          setStep(2)
        }
      }
    } else {
      setStep(2)

    }
  }

  const onSubmitStep3 = async (values) => {
    setIsCreating(true)

    try {
      // Textract
      const assignmentCreationFormValues = assignmentCreationForm.getFieldsValue(true)
      const designConversationFormValues = designConversationForm.getFieldsValue(true)

      const formValues = {
        ...assignmentCreationFormValues,
        // "language": values.language,
        "assignment_type": assignmentType,
        "title": assignmentType === "Student-Based Assignment" ? assignmentCreationFormValues.title : assignmentType === "Reading Responses" ? assignmentCreationFormValues.external_source_title : assignmentType === "IB Oral" ? assignmentCreationFormValues.IB_title : "",
        "external_format": externalFormat,
        "conversation_flow": [
          { "type": "start" },
          { "type": "base", "question_type": "base_1", "level": 1, "concept": designConversationFormValues.concept_1 ?? null },
          { "type": "followup" },
          { "type": "base", "question_type": "base_2", "level": 2, "concept": designConversationFormValues.concept_2 ?? null },
          { "type": "followup" },
          { "type": "base", "question_type": "base_3", "level": 3, "concept": designConversationFormValues.concept_3 ?? null },
          { "type": "closure" },
        ],
      }

      if (assignmentType === "Reading Responses") {
        if (fileData) {
          formValues["text"] = fileData.slice(formValues['reading_start_page'] - 1, formValues['reading_end_page']).join("\n").split(" ").slice(0, WORD_COUNT).join(" ");
        }
      }

      if (ltiToken) {
        await onLTISubmitDeepLink(formValues)
      } else {
        let response;
        if (assignmentType === "Reading Responses") {
          console.log("form values", formValues)
          response = await createCourseAssignment(formValues)
          console.log("response", response)
        } else {
          response = await createCourseAssignment(formValues)
        }

        if (response?.status === "success") {
          if(formValues["course_ids"].length > 1){
            toast.success("Assignment has been created in multiple courses.");
            props.history.push('/teacher/Classteacherdashboard');
          }
          else{
            toast.success("Assignment has been created.");
            props.history.push('/teacher/assignment/' + response["assignment_id"]);
          }
        }

        setIsCreating(false)
      }

    } catch (e) {
      console.log(e)
      toast.error("Failed to create assignment.")
      setIsCreating(false)
    }

  }

  const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onLTISubmitDeepLink = async (formValues) => {
    if (formValues["lti_assignment"]) {
      formValues["lti_assignment"] = JSON.parse(formValues.lti_assignment)
    }

    const response = await lti_submitDeepLink(formValues, ltiToken.ltik)
    var divElement = document.createElement("div");
    divElement.innerHTML = response.form;
    document.body.appendChild(divElement);

    document.getElementById("ltiaas_dl").submit()
  }

  useEffect(() => {
    const getLtiAssignments = async () => {
      const assignments = await lti_getAssignments(ltiToken.token.launch.context.id)
      setLtiAssignments(assignments)
    }

    if (ltiToken) {
      getLtiAssignments()
    }
  }, [ltiToken])

  useEffect(() => {
    const getCoursesData = async () => {
      const courses = await getCourses(true)
      setCourses(courses)
    }

    getCoursesData()
  }, [])    

  return (
    <>
      <IntroSteps
        enabled={stepsEnabled}
        steps={INTRO_STEPS}
        initialStep={0}
        options={{
          doneLabel: "Done",
          exitOnOverlayClick: false,
          exitOnEsc: false
        }}
        onExit={() => {
          setStepsEnabled(false)
        }}
        onComplete={async () => {
          await updateIntroStatus("create")
        }}
      />
      {!isLoading ? (
        <div className="w-full h-full relative">
          {limited === "share" ?
            <Card className="w-[500px] p-8 soft-shadow text-center justify-between flex flex-col" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
              <div>
                <div className="text-2xl" style={{
                  fontWeight: 600
                }}>Share Sherpa with a Teacher who Cares</div>
                <div className="lg my-4 mb-8">
                  You can create one conversation every day. To create another conversation, share Sherpa with 3 teachers who would love to experience this!
                </div>
              </div>
              <Form
                className="my-4"
                onFinish={async (values) => {
                  const last_shared = new Date().toISOString();
                  // Update server side 
                  await updateUser({
                    "last_shared": last_shared
                  })

                  // Update locally
                  setUserToken({
                    ...userToken,
                    details: {
                      ...userToken.details,
                      "last_shared": last_shared
                    }
                  })

                  // New endpoint that sends to users! 
                  await addToWaitlist(values.teacher_1)
                  await addToWaitlist(values.teacher_2)
                  await addToWaitlist(values.teacher_3)
                }}
              >
                <Form.Item name="teacher_1" label="Teacher 1" rules={[{ required: true, message: 'An email is required!' }, { type: 'email', message: 'The input is not valid E-mail!' }]}>
                  <Input placeholder='jane@hotchkiss.edu' />
                </Form.Item>
                <Form.Item name="teacher_2" label="Teacher 2" rules={[{ required: true, message: 'An email is required!' }, { type: 'email', message: 'The input is not valid E-mail!' }]}>
                  <Input placeholder='john@windsor.edu' />
                </Form.Item>
                <Form.Item name="teacher_3" label="Teacher 3" rules={[{ required: true, message: 'An email is required!' }, { type: 'email', message: 'The input is not valid E-mail!' }]}>
                  <Input placeholder='liz@westminster.edu' />
                </Form.Item>

                <Button htmlType="submit" type="primary" style={{ background: '#9C1FFF' }}>
                  Share!
                </Button>
              </Form>
            </Card>
            : limited === "closed" ?
              <Card className="w-[500px] h-[450px] p-8 soft-shadow text-center items-center justify-center flex flex-col" style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 999 }}>
                <div>
                  <div className="text-2xl" style={{
                    fontWeight: 600
                  }}>You have exhausted your conversations for today.</div>
                  <div className="lg my-4 mb-8">
                    Thank you for making the most of our tool. Come back tomorrow to create another conversation!
                  </div>
                </div>
              </Card>
              : null}

          <div className={`w-full h-full ${limited ? "blur" : ""}`} style={{ pointerEvents: limited ? "none" : "auto" }}>
            <TuneConvoModal isOpen={featureRequestModalOpen} setIsOpen={setFeatureRequestModalOpen} />
            {!isCreating ?
              <>
                <div className="w-full flex justify-center items-center" style={{ borderBottom: '1.5px solid #E2E8F0', fontFamily: 'Inter !important' }} >
                  <div className="w-3/4 p-4">
                    <Steps
                      size="small"
                      current={step}
                      items={STEPS}
                    />
                  </div>
                </div>
                {step === 0 ?
                  <div className="w-full flex flex-col justify-center items-center pb-24 gap-16">
                    <div className="pt-16 pb-4 w-full flex justify-center items-center flex-col gap-4">
                      <div className="text-2xl font-bold">
                        Create Conversation
                      </div>
                      <div className="text-lg">
                        If you truly understand something, you should be able to <b>speak on it</b>!
                      </div>
                    </div>

                    {/* <div className="rounded-lg bg-[#EEECFF] w-[800px]">
                <div className="px-8 py-6 flex items-start justify-between ">
                    <div className="text-purple-800 flex flex-col gap-2">
                        <span className="text-lg" style={{fontWeight: '600'}}>Scale Your Conversational Ability</span>
                        <span className="text-sm">Sherpa has the ability to engage in a 1-on-1 conversation with your students, in a variety of different ways.</span>
                    </div>
                </div>
            </div> */}
                    <div className="w-[800px]" id="reading_response_selection">
                      <div className="text-lg mb-6" style={{
                        fontWeight: 600
                      }}>
                        Chat About a Reading, Article, or Text
                      </div>
                      <div className="w-full grid grid-cols-1 gap-4">
                        <div className="rounded-lg bg-white p-4 flex flex-col gap-2 text-sm hover:bg-[#f7f7f7] cursor-pointer" style={{ border: '1.5px solid #E2E8F0' }} onClick={() => {
                          setAssignmentType("Reading Responses");
                          setExternalFormat("reading")
                          setStep(1)
                        }}>
                          <div className="flex items-center gap-2"><IoBook /><b className="text-md">Reading Responses</b> </div>
                          <span className="leading-relaxed">Submit any text-based digital media you assigned to your students, and we will simulate a conversation with them about what they read.</span>
                        </div>
                      </div>

                    </div>
                    <div id="main_chat" className="w-[800px] flex flex-col gap-8">
                      <div className="w-[800px]" id="student_work_selections">
                        <div className="text-lg mb-6" style={{
                          fontWeight: 600
                        }}>
                          Chat About Your Student's Work
                        </div>
                        <div className="w-full grid grid-cols-1 gap-4">
                          <div className="rounded-lg bg-white p-4 flex flex-col gap-2 text-sm hover:bg-[#f7f7f7] cursor-pointer" style={{ border: '1.5px solid #E2E8F0' }} onClick={() => {
                            setAssignmentType("Student-Based Assignment");
                            setStep(1)
                          }}>
                            <div className="flex items-center gap-2"><IoDocumentTextOutline /><b className="text-md">Student-Based Assignment</b> </div>
                            <span className="leading-relaxed">Have your students write an essay, lab-report, infographic on any topic of your choice, and we will simulate a conversation with them about their writing to gauge their true understanding.</span>
                          </div>
                          <div className="rounded-lg bg-white p-4 flex flex-col gap-2 text-sm hover:bg-[#f7f7f7] cursor-pointer" style={{ border: '1.5px solid #E2E8F0' }} onClick={() => {
                            setAssignmentType("IB Oral");
                            setStep(1)
                          }}>
                            <div className="flex items-center gap-2"><IoDocumentTextOutline /><b className="text-md">IB Oral</b> </div>
                            <span className="leading-relaxed">Have your students prepare for their IB Oral examination, and we will simulate a conversation with them about their preparation to gauge their true understanding.</span>
                          </div>
                          {/* <Tooltip title="Coming Soon">
                    <div className="rounded-lg bg-white p-4 flex flex-col gap-2 text-sm cursor-not-allowed opacity-30" style={{border: '1.5px solid #E2E8F0'}} >
                      <div className="flex items-center gap-2"><IoCodeSharp /><b className="text-md">Code</b> </div>
                      <span className="leading-relaxed">Conduct technical walkthroughs on your student’s programming assignments.</span>
                    </div>
                  </Tooltip>
                  
                  <Tooltip title="Coming Soon">
                    <div className="rounded-lg bg-white p-4 flex flex-col gap-2 text-sm cursor-not-allowed opacity-30" style={{border: '1.5px solid #E2E8F0'}} >
                      <div className="flex items-center gap-2"><IoTrendingUpOutline /><b className="text-md">Data Visualizations</b> </div>
                      <span className="leading-relaxed">Have students discuss and critically analyse their data models and visualisations.</span>
                    </div>
                  </Tooltip> */}
                        </div>
                      </div>


                    </div>
                  </div>

                  : step === 1 ?
                    <div className="w-full flex flex-col justify-center items-center pb-24">
                      <div className="py-16 w-full flex justify-center items-center flex-col gap-4">
                        <div className="text-2xl font-bold">
                          Setup Conversation
                        </div>
                        <div className="text-lg">
                          Tailor the conversation to {
                            assignmentType === "Student-Based Assignment" ? "your student's essay" :
                              assignmentType === "IB Oral" ? "your preferences" :
                                "the provided " + externalFormat
                          }
                        </div>
                      </div>

                      <div className="rounded-lg bg-white w-[800px]" style={{ border: '1.5px solid #E2E8F0' }} >
                        <Form
                          form={assignmentCreationForm}
                          layout="vertical"
                          className="w-full overflow-auto p-8"
                          onFinish={onSubmitStep1}
                          initialValues={{
                            time_limit: false,
                            min_words: 10,
                          }}
                        >

                          <ScrollFade />
                          {assignmentType === "Student-Based Assignment" ?
                            <>
                              {/* <Form.Item
                 label="Which existing assignment do you want your students to speak on?"
                 name="lti_assignment"
                 rules={[
                     { required: true, message: "This can't be empty." },
                   ]}
                 >
                 <Select>
                     {ltiAssignments.map((assignment)=>{
                         return (
                             <Select.Option value={JSON.stringify(assignment)}>{assignment.title}</Select.Option>
                         )
                     })}
                 </Select>
              </Form.Item> */}
                              <Form.Item
                                label="What is the name of this assignment?"
                                name="title"
                                rules={[
                                  {
                                    required: true,
                                    message: "Your title can't be empty!",
                                  },
                                ]}
                              >
                                <Input placeholder="Assignment 1" />
                              </Form.Item>
                              <Form.Item
                                label="What was the task prompt for this assignment?"
                                name="task_prompt"
                                rules={[
                                  {
                                    required: true,
                                    message: "Your prompt can't be empty!",
                                  },
                                ]}
                              >
                                <Input placeholder="Write a 3000-word essay on something that they care about..." />
                              </Form.Item>
                            </>
                            : assignmentType === "IB Oral" ?
                              <>
                                {/* New form for "IB Oral" */}
                                <Form.Item
                                  label="What is the name of this IB Oral assignment?"
                                  name="IB_title"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Your title can't be empty!",
                                    },
                                  ]}
                                >
                                  <Input placeholder="IB Oral Assignment 1" />
                                </Form.Item>
                                {/* Add more form items as needed */}
                              </>
                              :
                              <>
                                <Form.Item
                                  label={"What is the title of the " + externalFormat + "?"}
                                  name="external_source_title"
                                  rules={[
                                    { required: true, message: "This can't be empty." },
                                  ]}
                                >
                                  <Input placeholder={"e.g. The Great Gatsby"} />
                                </Form.Item>
                                <Radio.Group
                                  options={[
                                    { 'label': 'PDF', value: 'pdf' },
                                    { 'label': 'Text', value: 'text' }
                                  ]}
                                  onChange={(e) => {
                                    setFormat(e.target.value)
                                  }}
                                  value={format}
                                  className="mb-4 w-full"
                                  optionType="button"
                                  buttonStyle="solid"
                                />

                                {format === "text" ?
                                  <Form.Item
                                    label={"Paste the text of the reading, article, or book you want your students to chat about."}
                                    name="text"
                                    rules={[
                                      { required: true, message: "This can't be empty." },
                                    ]}
                                  >
                                    <Input.TextArea rows={4} placeholder={"The novel was inspired by a youthful romance Fitzgerald had with socialite Ginevra King, and the riotous parties he attended on Long Island's North Shore in 1922. Following a move to the French Riviera, Fitzgerald completed a rough draft of the novel in 1924. He submitted it to editor Maxwell Perkins, who persuaded Fitzgerald to revise the work over the following winter. After making revisions, Fitzgerald was satisfied with the text, but remained ambivalent about the book's title and considered several alternatives. Painter Francis Cugat's dust jacket art greatly impressed Fitzgerald, and he incorporated its imagery into the novel. After its publication by Scribner's in April 1925, The Great Gatsby received generally favorable reviews, though some literary critics believed it did not equal Fitzgerald's previous efforts. Compared to his earlier novels, This Side of Paradise (1920) and The Beautiful and Damned (1922), Gatsby was a commercial disappointment. It sold fewer than 20,000 copies by October, and Fitzgerald's hopes of a monetary windfall from the novel were unrealized. When the author died in 1940, he believed himself to be a failure and his work forgotten."} />
                                  </Form.Item>
                                  : format === "pdf" ?
                                    <>
                                      <Form.Item
                                        label={"Upload the " + externalFormat + " you want your students to speak on!"}
                                        name="file"
                                        valuePropName="fileList"
                                        getValueFromEvent={normFile}
                                        rules={[
                                          {
                                            required: true,
                                            message: "You have to upload a file!",
                                          },
                                        ]}
                                      >
                                        <Upload.Dragger
                                          accept='application/pdf'
                                          onChange={(values) => {
                                            if (values.fileList.length === 0) {
                                              setTotalPages(0)
                                              setStartPage(null)
                                              setEndPage(null)
                                              assignmentCreationForm.setFieldValue('reading_end_page', null)
                                              assignmentCreationForm.setFieldValue('reading_start_page', null)
                                            }
                                          }}
                                          maxCount={1} customRequest={async (data) => {
                                            await uploadToS3(data.file.name, data.file)
                                            assignmentCreationForm.setFieldValue('file_name', data.file.name)
                                            const fileReader = new FileReader();
                                            fileReader.readAsArrayBuffer(data.file);
                                            fileReader.onload = async () => {
                                              const arrayBuffer = fileReader.result;
                                              const buffer = new Uint8Array(arrayBuffer);

                                              const loadingTask = getDocument(buffer);
                                              try {
                                                const doc = await loadingTask.promise;
                                                const numPages = doc.numPages;

                                                setTotalPages(numPages)
                                                assignmentCreationForm.setFieldValue('reading_end_page', numPages)
                                                assignmentCreationForm.setFieldValue('reading_start_page', 1)
                                                setStartPage(1)
                                                setEndPage(numPages)


                                                const loadPage = async function (pageNum) {
                                                  const page = await doc.getPage(pageNum);
                                                  const content = await page.getTextContent();
                                                  const strings = content.items.map(function (item) {
                                                    return item.str;
                                                  });
                                                  page.cleanup();
                                                  return strings.join(" ")
                                                };

                                                const textData = []
                                                for (let i = 1; i <= numPages; i++) {
                                                  textData.push(await loadPage(i));
                                                }

                                                setFileData(textData)
                                                toast.success("Processed Reading!")
                                                data.onSuccess(data.file, data.file.name)

                                              } catch (err) {
                                                toast.error("Error: " + err)
                                              }
                                            }

                                            // await uploadToS3(data.file.name, data.file)
                                            // const job_id = await textractFile(data.file.name)
                                            // console.log("job_id: ", job_id)
                                            // setJobID(job_id)

                                          }}
                                          beforeUpload={(file) => {
                                            const isCorrectFormat = file.type === 'application/pdf';
                                            if (!isCorrectFormat) {
                                              toast.error('You can only upload JPG/PNG file!');
                                            }
                                            return isCorrectFormat;
                                          }}
                                        >
                                          <p className="ant-upload-drag-icon">
                                            <AiFillFilePdf size={50} color="#9C1FFF" />
                                          </p>
                                          <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                          <p className="ant-upload-hint">
                                            Only PDF files are supported
                                          </p>
                                        </Upload.Dragger>
                                      </Form.Item>
                                      <Form.Item
                                        label={`What pages of the reading do you want your students to be tested on? ${totalPages ? `(${((fileData?.slice(startPage - 1, endPage).join("\n").split(" ")).length - 1)} words)` : ``}`}
                                      >
                                        <Space.Compact>
                                          <Form.Item
                                            name={'reading_start_page'}
                                            noStyle
                                            rules={[
                                              { required: true, message: "You need to have a valid start page." },
                                              ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                  if (!value || getFieldValue('reading_end_page') >= value) {
                                                    return Promise.resolve();
                                                  }
                                                  return Promise.reject(new Error('End page must be greater than start page'));
                                                },
                                              }),
                                            ]}
                                          >
                                            <InputNumber disabled={!totalPages} min={1} addonBefore="Start Page" style={{ width: '50%' }} onChange={value => setStartPage(value)} />
                                          </Form.Item>
                                          <Form.Item
                                            name={'reading_end_page'}
                                            noStyle
                                            disabled={!totalPages}
                                            rules={[
                                              { required: true, message: "You need to have a valid end page." },
                                              ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                  if (!value || getFieldValue('reading_start_page') <= value) {
                                                    return Promise.resolve();
                                                  }
                                                  return Promise.reject(new Error('End page must be greater than start page'));
                                                },
                                              }),
                                            ]}
                                          >
                                            <InputNumber disabled={!totalPages} max={totalPages} min={1} addonAfter="End Page" style={{ width: '50%' }} onChange={value => setEndPage(value)} />
                                          </Form.Item>
                                        </Space.Compact>
                                      </Form.Item>
                                    </>
                                    : null}
                              </>
                          }

                          {/* <Form.Item
                            label="What subject is this for?"
                            name="subject"
                            rules={[
                              { required: true, message: "This can't be empty." },
                            ]}
                          >
                            {assignmentType === "IB Oral" ? (
                              <Select>
                                <Select.Option value="IB English A: Language and Literature">IB English A: Language and Literature</Select.Option>
                              </Select>
                            ) : (
                              <Select>
                                <Select.Option value="English">English</Select.Option>
                                <Select.Option value="History">History</Select.Option>
                                <Select.Option value="Computer Science">Computer Science</Select.Option>
                                <Select.Option value="Math">Math</Select.Option>
                                <Select.Option value="Science">Science</Select.Option>
                                <Select.Option value="Geography">Geography</Select.Option>
                                <Select.Option value="Language">Language</Select.Option>
                                <Select.Option value="Social Science">Social Science</Select.Option>
                                <Select.Option value="Other">Other</Select.Option>
                              </Select>
                            )}
                          </Form.Item> */}
                          {assignmentType === "Reading Responses" ? (
                            <Form.Item
                              label="What language would you like this assignment to be in?"
                              name="language"
                              rules={[
                                { required: true, message: "This can't be empty." },
                              ]}
                            >
                              <Select>
                                <Select.Option value="English">English</Select.Option>
                                <Select.Option value="Spanish">Spanish</Select.Option>
                                {/*Shaurya*/}
                                {/* <Select.Option value="German">German</Select.Option> */}
                                <Select.Option value="French">French</Select.Option>
                                {/* <Select.Option value="Pinyin">Pinyin</Select.Option>
                                <Select.Option value="Russian">Russian</Select.Option>
                                <Select.Option value="Arabic">Arabic</Select.Option>
                                <Select.Option value="Portuguese">Portuguese</Select.Option>
                                <Select.Option value="Bengali">Bengali</Select.Option>
                                <Select.Option value="Hindi">Hindi</Select.Option>
                                <Select.Option value="Japanese">Japanese</Select.Option>
                                <Select.Option value="Korean">Korean</Select.Option>
                                <Select.Option value="Italian">Italian</Select.Option> */}
                              </Select>
                            </Form.Item>
                          ) : (
                          <Form.Item
                              label="What language would you like this assignment to be in?"
                              name="language"
                              rules={[
                                { required: true, message: "This can't be empty." },
                              ]}
                            >
                              <Select>
                                <Select.Option value="English">English</Select.Option>
                                {/* <Select.Option value="Spanish">Spanish</Select.Option> */}
                                {/*Shaurya*/}
                                {/* <Select.Option value="German">German</Select.Option> */}
                                {/* <Select.Option value="French">French</Select.Option> */}
                                {/* <Select.Option value="Pinyin">Pinyin</Select.Option>
                                <Select.Option value="Russian">Russian</Select.Option>
                                <Select.Option value="Arabic">Arabic</Select.Option>
                                <Select.Option value="Portuguese">Portuguese</Select.Option>
                                <Select.Option value="Bengali">Bengali</Select.Option>
                                <Select.Option value="Hindi">Hindi</Select.Option>
                                <Select.Option value="Japanese">Japanese</Select.Option>
                                <Select.Option value="Korean">Korean</Select.Option>
                                <Select.Option value="Italian">Italian</Select.Option> */}
                              </Select>
                            </Form.Item>
                          )}

                          {/* <Form.Item
                            label="What grade level is this assignment for?"
                            name="grade"
                            rules={[
                              { required: true, message: "This can't be empty." },
                            ]}
                          >
                            {assignmentType === "IB Oral" ? (
                              <Select>
                                <Select.Option value={11}>11th Grade</Select.Option>
                                <Select.Option value={12}>12th Grade</Select.Option>
                              </Select>
                            ) : (
                              <Select>
                                <Select.Option value={1}>1st grade</Select.Option>
                                <Select.Option value={2}>2nd grade</Select.Option>
                                <Select.Option value={3}>3rd grade</Select.Option>
                                <Select.Option value={4}>4th grade</Select.Option>
                                <Select.Option value={5}>5th grade</Select.Option>
                                <Select.Option value={6}>6th grade</Select.Option>
                                <Select.Option value={7}>7th Grade</Select.Option>
                                <Select.Option value={8}>8th Grade</Select.Option>
                                <Select.Option value={9}>9th Grade</Select.Option>
                                <Select.Option value={10}>10th Grade</Select.Option>
                                <Select.Option value={11}>11th Grade</Select.Option>
                                <Select.Option value={12}>12th Grade</Select.Option>
                                <Select.Option value={13}>College</Select.Option>
                              </Select>
                            )}
                          </Form.Item> */}

                          <Form.Item
                            label="How do you want your students to speak on it?"
                            name="recording_format"
                            rules={[
                              { required: true, message: "This can't be empty." },
                            ]}
                          >
                            <Select>
                              <Select.Option value={"with_video"}>Students <b>must</b> turn on their camera, so you can record their video.</Select.Option>
                              <Select.Option value={"students_can_choose"}>Students have the <b>option</b> to turn on their cameras, so they can record their video.</Select.Option>
                              <Select.Option value={"only_audio"}>Students <b>do not</b> have the option of turning on their cameras.</Select.Option>
                            </Select>
                          </Form.Item>

                          <Form.Item
                            label="Do you want your students to immediately receive AI feedback on their conversation?"
                            name="student_ai_feedback"
                            rules={[
                              { required: true, message: "This can't be empty." },
                            ]}
                          >
                            <Select>
                              <Select.Option value={true}>Yes</Select.Option>
                              <Select.Option value={false}>No, I want to be able to approve the AI feedback before it is shared with my students.</Select.Option>
                            </Select>
                          </Form.Item>
                          <Form.Item
                          label = "Which course do you want to add this assignment to?"
                          name = "course_ids"
                          rules = {[
                            { required: true, message: "This can't be empty." },
                          ]}
                          initialValue={courseId? [courseId]:[]}
                          > 
                          <Select mode="multiple" placeholder="Select a course" defaultValue={courseId? [courseId]:[]}>
                            {courses.map((course) => {
                              return (
                                <Select.Option value={course._id}>{course.title}</Select.Option>
                              )
                            })}
                          </Select>                        
                          </Form.Item>

                          <Collapse bordered={false} className="mb-8" >
                            <Collapse.Panel header="Advanced Settings" key="1">
                              <Form.Item
                                label="Do you want students to have a limited 'waiting time' before they start talking?"
                                name="time_limit"
                                rules={[
                                  { required: true, message: "This can't be empty." },
                                ]}
                              >
                                <Select onChange={value => setTimeLimit(value)}>
                                  <Select.Option value={true}>Yes</Select.Option>
                                  <Select.Option value={false}>No</Select.Option>
                                </Select>
                              </Form.Item>
                              {timeLimit && (
                                <Form.Item
                                  label="Students have a waiting time of ..."
                                  name="time_limit_seconds"
                                  rules={[
                                    { required: true, message: "This can't be empty." },
                                  ]}
                                >
                                  <Select>
                                    <Select.Option value={5}>5 seconds</Select.Option>
                                    <Select.Option value={10}>10 seconds</Select.Option>
                                    <Select.Option value={20}>20 seconds</Select.Option>
                                    <Select.Option value={60}>60 seconds</Select.Option>
                                    <Select.Option value={120}>2 minutes</Select.Option>
                                  </Select>
                                </Form.Item>
                              )}
                              <Form.Item
                                label="Each student response should have a minimum word count of..."
                                name="min_words"
                                rules={[
                                  { required: true, message: "This can't be empty." },
                                ]}
                              >
                                <Select>
                                  <Select.Option value={5}>5 words</Select.Option>
                                  <Select.Option value={10}>10 words</Select.Option>
                                  <Select.Option value={20}>20 words</Select.Option>
                                  <Select.Option value={30}>30 words</Select.Option>
                                  <Select.Option value={40}>40 words</Select.Option>
                                </Select>
                              </Form.Item>
                            </Collapse.Panel>
                          </Collapse>



                          <div className="w-full flex justify-between">
                            <Button
                              onClick={() => {
                                setStep(0)
                              }}
                            >
                              Back
                            </Button>
                            <Button
                              type="primary"
                              htmlType="submit"
                              style={{ background: '#9C1FFF' }}
                            >
                              Next
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </div>
                    : step === 2 ?
                      <>
                        <div className="w-full flex flex-col justify-center items-center pb-24 gap-6">
                          <div className="pb-8 pt-16 w-full flex justify-center items-center flex-col gap-4">
                            <div className="text-2xl font-bold">
                              Design Your Conversation
                            </div>
                            <div className="text-lg">
                              What type of questions would you want Sherpa to ask to gauge students' true understanding?
                            </div>
                          </div>
                          {/* <div className="rounded-lg bg-[#EEECFF] w-[800px]">
                <div className="px-8 py-6 flex items-start justify-between ">
                    <div className="text-purple-800 flex flex-col gap-2">
                        <span className="text-lg" style={{fontWeight: '600'}}>Scale Your Conversational Ability</span>
                        <span className="text-sm">Sherpa has the ability to engage in a 1-on-1 conversation with your students, in a variety of different ways.</span>
                    </div>
                </div>
            </div> */}

                          <div className="rounded-lg bg-[#EEECFF] w-[800px]">
                            <div className="px-8 py-6 flex items-start justify-between ">
                              <div className="text-purple-800 flex flex-col gap-2">
                                <span className="text-lg" style={{ fontWeight: '600' }}>How Does Sherpa Ask Questions?</span>
                                <span className="text-sm">Sherpa asks questions that are specifically designed to gauge <span style={{
                                  fontStyle: 'italic'
                                }}>true student understanding</span> by using the <a href="https://blog.edmentum.com/webb%E2%80%99s-depth-knowledge-framework-basics">Depth of Knowledge</a> framework. There are 4 levels in this framework, where each level becomes increasingly more challenging.</span><br />
                                {/* <span className="text-lg" style={{fontWeight: '600'}}>What Can You Do?</span>
                        <span className="text-sm">We also want to give you control over how these conversations are delivered. Whether it be tuning <b>specific questions</b>, reminding students to think about specific <b>learning objectives</b>, or testing for specific <b>concepts and ideas</b>, we are currently experimenting with a range of different ways to give more autonomy to teachers.</span> 

                        <span className="text-sm">This feature is coming soon, but we would love to hear from you in the meantime.</span> */}

                                <div>
                                  <div className="w-full justify-start flex">
                                    <Button
                                      type="primary"
                                      className="flex"
                                      style={{ background: '#9C1FFF' }}
                                      onClick={() => {
                                        setFeatureRequestModalOpen(true)
                                      }}
                                    >
                                      How do you feel about this?
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="rounded-lg bg-white w-[800px]" style={{ border: '1.5px solid #E2E8F0' }} >
                            <Form
                              form={designConversationForm}
                              onFieldsChange={(values) => {
                                if (values[0].name[0] === "tune_ai_type") {
                                  setTuneType(values[0].value)
                                }
                              }}
                              layout="vertical"
                              className="w-full overflow-auto p-8"
                              onFinish={onSubmitStep3}
                            >


                              <Form.Item
                                label="How do you want to tune Sherpa?"
                                name="tune_ai_type"
                                rules={[
                                  { required: true, message: "This can't be empty." },
                                ]}
                              >
                                <Select>
                                  <Select.Option value={"Custom Concepts"}>I want to check for a student’s understanding about specific ideas and concepts.</Select.Option>
                                  <Select.Option value={"AI Approach"}>I want to let AI decide the flow of questions, entirely. </Select.Option>
                                  {/* <Select.Option value={"Learning Objectives"} disabled={true}>I want to tune the AI to remind students about specific learning objectives. </Select.Option>
                      <Select.Option value={"Custom Questions"}  disabled={true}>I want to tune the AI to ask my own, specific custom question. </Select.Option> */}
                                </Select>
                              </Form.Item>

                              {tuneType === "Custom Concepts" ?
                                <>
                                  <div className="mt-12 mb-8">
                                    <div className="text-xl font-semibold">
                                      You can check your student's understanding of up to 3 custom concepts
                                    </div>
                                    <p>
                                      We will generate questions that will effectively test a student's understanding of these concepts
                                      {assignmentType === "Reading Responses" ?
                                        <>.</>
                                        : assignmentType === "Student-Based Assignment" ?
                                          <>
                                            , while being <b>specifically tailored</b> to their own, unique essay.
                                          </>
                                          : null}
                                    </p>
                                  </div>
                                  <Form.Item
                                    label="What is the first concept or idea you want to ask about?"
                                    name="concept_1"
                                  >
                                    <Input placeholder="e.g. the elusive nature of 'hope', as represented by the Green Light." />
                                  </Form.Item>
                                  <Form.Item
                                    label="What is the second concept or idea you want to ask about?"
                                    name="concept_2"
                                  >
                                    <Input placeholder="e.g. the role of the independent variable, in contrast to the dependent variable, in a science report." />
                                  </Form.Item>
                                  <Form.Item
                                    label="What is the third concept or idea you want to ask about?"
                                    name="concept_3"
                                  >
                                    <Input placeholder="e.g. the destructive nature of unchecked ambition and the consequences it can bring to individuals and society." />
                                  </Form.Item>
                                </>
                                : tuneType === "Specific Questions" ?
                                  <>
                                    <Form.Item
                                      label="What is your first question?"
                                      name="question_1"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Your question can't be empty!",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="Why?" />
                                    </Form.Item>
                                    <Form.Item
                                      label="What is your second question?"
                                      name="question_2"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Your question can't be empty!",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="How?" />
                                    </Form.Item>
                                    <Form.Item
                                      label="What is your third question?"
                                      name="question_3"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Your question can't be empty!",
                                        },
                                      ]}
                                    >
                                      <Input placeholder="When...?" />
                                    </Form.Item>
                                  </>
                                  : null}
                              <div className="w-full flex justify-between">
                                <Button
                                  onClick={() => {
                                    setStep(1)
                                  }}
                                >
                                  Back
                                </Button>
                                <Button
                                  type="primary"
                                  htmlType="submit"
                                  style={{ background: '#9C1FFF' }}
                                >
                                  Create!
                                </Button>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </>
                      : null}
              </>
              :
              <div className="flex justify-center items-center w-full h-full flex-col gap-8">
                <div className="text-xl font-semibold">Creating Assignment...</div>
                <RaceBy
                  size={120}
                  lineWeight={5}
                  speed={1.4}
                  color="#a68bfa"
                />
              </div>
            }
          </div>
        </div>
      ) :
        <div className="flex justify-center items-center w-full h-full">
          <ReactLoading
            type={"spin"}
            color={"purple"}
            height={30}
            width={30}
          />
        </div>
      }
    </>
  )
}

export default TeacherCreate;